import {
  ActionIcon,
  Button,
  Group,
  Select,
  SelectItem,
  TextInput,
  Text,
  Modal,
  Checkbox,
  NumberInput,
  Grid,
  Space,
  Title,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Check, Pencil, Plus, Trash, DeviceFloppy } from 'tabler-icons-react';
import CurrencyApi from '../../../apis/CurrencyApi';
import SupplierApi from '../../../apis/SupplierApi';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import OurTable from '../../../components/OurTable';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IPurchaseInvoiceRequest } from '../../../interfaces/requests/IPurchaseInvoice';
import { IPurchaseOrder } from '../../../interfaces/requests/IPurchaseOrderRequest';
import InnerLayout from '../../InnerLayout';
import ItemForm from './itemForm';
import PurchaseOrderForm from './purchaseOrderForm';
import ListToolbar from '../../../components/ListToolbar';
import AddPurchaseOrderForm from './addPurchaseOrderForm';
import moment from 'moment';
import ItemApi from '../../../apis/ItemApi';
import EnumerationApi from '../../../apis/EnumerationApi';

const links = [
  {
    title: 'Purchase Invoice',
    link: '/purchase-Invoice',
  },
  {
    title: 'v1',
    link: 'dashboard',
  },
  {
    title: 'v2',
    link: 'dashboard',
  },
];

const CreateOrEditPurchaseInvoiceMasterLayout = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [modalPurchaseOrderOpened, setModalPurchaseOrderOpened] = useState<boolean>(false);
  const [modalAddPurchaseOrderOpened, setModalAddPurchaseOrderOpened] = useState<boolean>(false);
  const [isStatusTax, setIsStatusTax] = useState<boolean>(false);
  const [modalItemOpened, setModalItemOpened] = useState<boolean>(false);
  const [rateCurrency, setRateCurrency] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const [supplier, setSupplier] = useState<SelectItem[]>([]);
  const [currency, setCurrency] = useState<SelectItem[]>([]);
  const [payment, setPayment] = useState<SelectItem[]>([]);
  const [id, setId] = useState<number | undefined>(0);
  const [purchaseOrderLinesData, setPurchaseOrderLinesData] = useState<any[]>([]);

  const params = useParams();

  const [data, setData] = useState<IPurchaseInvoiceRequest>({
    supplier_id: '',
    doc_no: '',
    date: new Date(),
    currency_id: '',
    payment_id: '',
    term: '',
    purchase_order_lines: [],
    item_lines: [],
    total_rp: 0,
    total_after_tax: 0,
    hm_current_total: 0,
  });

  const modals = useModals();

  const toggleModal = (type: string) => {
    if (type.toUpperCase() === 'PURCHASEORDER') {
      setModalPurchaseOrderOpened(!modalPurchaseOrderOpened);
    }

    if (type.toUpperCase() === 'ITEM') {
      setModalItemOpened(!modalItemOpened);
    }

    if (type.toUpperCase() === 'ADDPURCHASEORDER') {
      setModalAddPurchaseOrderOpened(!modalAddPurchaseOrderOpened);
    }
  };

  const toCreate = () => {};

  const onDeletePrompt = (data: any) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(data),
    });
  };

  const remove = (data: any) => {
    console.log('delete');
  };

  const toEdit = (data: any, type: string) => {
    if (type.toUpperCase() === 'PURCHASEORDER') {
      setId(data.id);
      toggleModal('PurchaseOrder');
    }

    if (type.toUpperCase() === 'ITEM') {
      setId(data.id);
      toggleModal('Item');
    }
  };

  const getCurrencyList = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrency(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSupplierList = () => {
    setIsLoading(true);
    SupplierApi.getDataSource()
      .then((res) => {
        setSupplier(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateCheckbox = () => {
    setIsStatusTax(!isStatusTax);
  };

  const overallSave = () => {
    //Manipulate TotalRp,TotalAfterTax,HMCurrentTotal
    console.log(data);
  };

  const updateInputValue = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    switch (type) {
      case 'doc_no':
        setData((prevState) => ({
          ...prevState,
          doc_no: event.target.value,
        }));
        break;
      case 'term':
        setData((prevState) => ({
          ...prevState,
          term: event.target.value,
        }));
        break;
    }
  };

  const updateSelectValue = (value: string, type: string) => {
    switch (type) {
      case 'supplier':
        setData((prevState) => ({
          ...prevState,
          supplier_id: value,
        }));
        break;
      case 'currency':
        setData((prevState) => ({
          ...prevState,
          currency_id: value,
        }));
        break;
      case 'payment':
        setData((prevState) => ({
          ...prevState,
          payment_id: value,
        }));
        break;
    }
  };

  const addPurchaseOrder = () => {
    console.log('HIT Purchase Order API');
    // const data: IPurchaseOrder = {
    //   close_reason: "",
    //   code: "asd",
    //   currency_converted_total: 1212,
    //   currency_id: 1,
    //   date: '2022-01-01',
    //   id: 1,
    //   status: 1,
    //   status_in_string: 'OPEN',
    //   supplier_id: 1,
    //   total: 32000
    // }

    // setData(prevState => ({
    //   ...prevState,
    //   purchase_order_lines: [
    //     data
    //   ]
    // }))
  };

  useEffect(() => {
    getSupplierList();
    getPayment();
    getCurrencyList();
  }, []);

  useEffect(() => {
    if (data.purchase_order_lines.length > 0) {
      let totalRp = data.purchase_order_lines.map((x) => x.total).reduce((prev, current) => prev + current, 0);
      setData((prevState) => ({
        ...prevState,
        total_rp: totalRp,
        total_after_tax: totalRp,
      }));
    }
  }, [data.purchase_order_lines]);

  useEffect(() => {
    if (isStatusTax && percentage) {
      let totalAfterTax = data.total_after_tax;
      totalAfterTax += percentage;
      setData((prevState) => ({
        ...prevState,
        total_after_tax: totalAfterTax,
      }));
    } else {
      let totalAfterTax = data.total_after_tax;
      totalAfterTax -= percentage;
      setData((prevState) => ({
        ...prevState,
        total_after_tax: totalAfterTax,
      }));
    }
  }, [isStatusTax]);

  useEffect(() => {
    const paramsId = params.id;
    console.log(paramsId);
    if (paramsId) {
      //Hit API
      // getById(Number(paramsId));
    }
  }, []);

  //For Development
  useEffect(() => {
    if (rateCurrency === 0) {
      setRateCurrency(11);
    }
  }, [rateCurrency]);

  const columnsPO = [
    {
      title: 'Date',
      accessor: 'date',
    },
    {
      title: 'PO No',
      accessor: 'po_no',
    },
    {
      title: 'Total',
      accessor: 'total',
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (data: any) => {
        return (
          <Group spacing={'xs'}>
            <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(data)}>
              <Trash size={16} />
            </ActionIcon>
            <ActionIcon variant='light' color='blue[3]' onClick={() => toEdit(data, 'PurchaseOrder')}>
              <Pencil size={16} />
            </ActionIcon>
          </Group>
        );
      },
    },
  ];

  const columnsItems = [
    {
      title: 'ID Item',
      accessor: 'id_item',
    },
    {
      title: 'Item Description',
      accessor: 'description',
    },
    {
      title: 'Quantity',
      accessor: 'quantity',
    },
    {
      title: 'Unit',
      accessor: 'unit',
    },
    {
      title: 'Price',
      accessor: 'price',
    },
    {
      title: 'Amount',
      accessor: 'amount',
    },
    {
      title: 'S Quantity',
      accessor: 's_qty',
    },
    {
      title: 'S Unit',
      accessor: 's_unit',
    },
    {
      title: 'S Price',
      accessor: 's_price',
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: any) => {
        return (
          <Group spacing={'xs'}>
            <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
              <Trash size={16} />
            </ActionIcon>
            <ActionIcon variant='light' color='blue[3]' onClick={() => toEdit(item, 'Item')}>
              <Pencil size={16} />
            </ActionIcon>
          </Group>
        );
      },
    },
  ];

  const getItemById = (id: any) => {
    ItemApi.getById(id)
      .then((res) => {
        let data = res.data;
        console.log(data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onRowClick = (value: any) => {
    getItemById(value.id);
  };

  // const handlePercentage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPercentage(Number(event.target.value));
  //   if (isStatusTax && percentage) {
  //     let totalAfterTax = data.total_after_tax;
  //     totalAfterTax += percentage;
  //     setData(prevState => ({
  //       ...prevState,
  //       total_after_tax: totalAfterTax
  //     }))
  //   } else {
  //     let totalAfterTax = data.total_after_tax - percentage;
  //     setData(prevState => ({
  //       ...prevState,
  //       total_after_tax: totalAfterTax
  //     }))
  //   }
  // }

  const dummyItemsData = [
    {
      id_item: 'B-61',
      description: 'Hello World',
      quantity: '23',
      unit: 'PCS',
      price: '12312',
      amount: '1231',
      s_qty: '123',
      s_unit: '21',
      s_price: '123',
    },
    {
      id_item: 'B-62',
      description: 'Hello World',
      quantity: '23',
      unit: 'PCS',
      price: '12312',
      amount: '1231',
      s_qty: '123',
      s_unit: '21',
      s_price: '123',
    },
    {
      id_item: 'B-63',
      description: 'Hello World',
      quantity: '23',
      unit: 'PCS',
      price: '12312',
      amount: '1231',
      s_qty: '123',
      s_unit: '21',
      s_price: '123',
    },
  ];

  const getPayment = () => {
    EnumerationApi.getDataSource('purchase_order_invoice_payment')
      .then((res) => {
        let result = res.data.map((x) => {
          return {
            ...x,
            label: x.display_name,
          };
        });

        setPayment(result);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePercentage = (event: any) => {
    setPercentage(Number(event.target.value));
    let totalAfterTax = percentage + data.total_after_tax;
    setData((prevState) => ({
      ...prevState,
      total_after_tax: totalAfterTax,
    }));
  };

  const handleAddPurchaseOrderForm = (data: any, purchaseOrderData: any) => {
    let newData = {
      id: data.id,
      date: moment(data.date).format('DD/MM/YYYY'),
      po_no: data.code,
      total: data.total,
    };

    let isExist = [...purchaseOrderLinesData].filter((x) => x.id == data.id);
    if (isExist.length >= 1) {
      showNotification({ message: 'Already exist in table', title: 'Error!', color: 'red' });
    } else {
      let newPurchaseOrderData = [...purchaseOrderLinesData, newData];
      console.log(newPurchaseOrderData, 'ASD');
      setPurchaseOrderLinesData(newPurchaseOrderData);
    }
  };

  if (isSubmitting) {
    return <div>Submitting...</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <InnerLayout title='Purchase Invoice'>
      <OurBreadcrumbs crumbs={links} />
      <ListToolbar>
        <Group>
          <Button onClick={overallSave} size='sm' leftIcon={<DeviceFloppy size={16} />}>
            Submit
          </Button>
        </Group>
      </ListToolbar>
      <Grid>
        <Grid.Col span={4}>
          <TextInput required label='Doc No.' value={data.doc_no} onChange={(e) => updateInputValue(e, 'doc_no')} />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            style={{ minWidth: '150px' }}
            data={currency}
            required
            label='Currency'
            value={data.currency_id}
            onChange={(value) => value && updateSelectValue(value, 'currency')}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput required label='Term (Days)' value={data.term} onChange={(e) => updateInputValue(e, 'term')} />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={4}>
          <DatePicker label='Date' required value={data.date} />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            style={{ minWidth: '150px' }}
            data={payment}
            required
            label='Payment'
            value={data.payment_id}
            onChange={(value) => value && updateSelectValue(value, 'payment')}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            style={{ minWidth: '150px' }}
            data={supplier}
            required
            label='Supplier'
            value={data.supplier_id}
            onChange={(value) => value && updateSelectValue(value, 'supplier')}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={4}>
          <TextInput required label='Purchase Order No.' />
        </Grid.Col>
      </Grid>
      <ListToolbar>
        <Group>
          <Button
            disabled={!data.supplier_id}
            size='sm'
            leftIcon={<Plus size={16} />}
            onClick={() => toggleModal('AddPurchaseOrder')}
          >
            Add Purchase Order
          </Button>
        </Group>
      </ListToolbar>
      <Title order={4}>Purchase Order</Title>
      <Space h={6} />
      <OurTable clickEvent={onRowClick} columns={columnsPO} data={purchaseOrderLinesData} isLoading={isLoading} />
      <Space h={6} />
      <Title order={4}>Items</Title>
      <Space h={6} />
      <OurTable columns={columnsItems} data={dummyItemsData} isLoading={isLoading} />
      <Modal
        opened={modalPurchaseOrderOpened}
        onClose={() => toggleModal('PurchaseOrder')}
        title={'Update Purchase Order'}
      >
        <PurchaseOrderForm afterSubmit={() => toggleModal('PurchaseOrder')} id={id} />
      </Modal>
      <Modal opened={modalItemOpened} onClose={() => toggleModal('Item')} title={'Update Item'}>
        <ItemForm afterSubmit={() => toggleModal('Item')} id={id} />
      </Modal>
      <Modal
        opened={modalAddPurchaseOrderOpened}
        onClose={() => toggleModal('AddPurchaseOrder')}
        title={'Add Purchase Order'}
      >
        <AddPurchaseOrderForm afterSubmit={handleAddPurchaseOrderForm} id={Number(data.supplier_id)} />
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
          <TextInput label='Total Rp' disabled value={data.total_rp} />
          <TextInput required label='Total After Tax Rp' value={data.total_after_tax} disabled />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
          <div style={{ alignItems: 'flex-end', display: 'flex', height: '45px' }}>
            <Checkbox label='Status Tax' onChange={updateCheckbox} />
          </div>
          <TextInput type='number' label='%' disabled={!isStatusTax && true} onChange={handleChangePercentage} />
          <TextInput required label='HM Current Total $' disabled value={data.hm_current_total} />
        </div>
      </div>
    </InnerLayout>
  );
};

export default CreateOrEditPurchaseInvoiceMasterLayout;
