import { ActionIcon, Button, Group, NativeSelect, Select, SelectItem, Space, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Plus } from 'tabler-icons-react';
import PurchaseOrderApi from '../../../apis/PurchaseOrderApi';
import OurTable from '../../../components/OurTable';
import ErrorHandler from '../../../helpers/_errorHandler';

const AddPurchaseOrderForm = (props: { afterSubmit?: Function; id?: number }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [purchaseOrderList, setPurchaseOrderList] = useState<SelectItem[]>([]);
  const [purchaseOrderId, setPurchaseOrderId] = useState<any>();
  const [data, setData] = useState<any[]>([]);
  const [currentDataById, setCurrentDataById] = useState<{}>({});
  const [currentId, setCurrentId] = useState<number>();

  const handleSelectOnChange = (value: any) => {
    if (value) {
      setPurchaseOrderId(value);
      getPurchaseOrderListById(value);
    }
  };

  const addPurchaseOrder = (purchaseOrder: any) => {
    if (props.afterSubmit) {
      props.afterSubmit(purchaseOrder, currentDataById);
    }
  };

  const getPurchaseOrderListById = (id: any) => {
    setIsLoading(true);
    PurchaseOrderApi.getById(id)
      .then((res) => {
        let data = res.data;
        setCurrentDataById(data);
        setCurrentId(data.id);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPurchaseOrderBySupplierId = () => {
    setIsLoading(true);
    PurchaseOrderApi.getPOBySupplierId(props.id)
      .then((res) => {
        setPurchaseOrderList(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPurchaseOrderLines = () => {
    setIsLoading(true);
    PurchaseOrderApi.getPOLines(currentId)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      title: 'PO No.',
      accessor: 'code',
    },
    {
      title: 'Date',
      accessor: 'date',
      customRender: (purchaseOrder: any) => {
        return moment(purchaseOrder.date).format('DD MMM YYYY');
      },
    },
    {
      title: 'Total Price',
      accessor: 'total',
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (purchaseOrder: any) => {
        return (
          <Group spacing={'xs'}>
            <Button size='xs' onClick={() => addPurchaseOrder(purchaseOrder)} leftIcon={<Plus size={16} />}>
              Add
            </Button>
          </Group>
        );
      },
    },
  ];

  useEffect(() => {
    if (props.id) {
      getPurchaseOrderBySupplierId();
    }
  }, []);

  useEffect(() => {
    if (currentId) {
      getPurchaseOrderLines();
    }
  }, [currentId]);

  return (
    <>
      <OurTable columns={columns} data={purchaseOrderList} />
    </>
  );
};

export default AddPurchaseOrderForm;
