import { Box, Button, Group, SelectItem } from '@mantine/core';
import { useEffect, useState } from 'react';
import DynamicField from '../../../components/DynamicField';
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import { useForm, yupResolver } from '@mantine/form';
import { object, string, number } from 'yup';
import { IsARequiredField as requiredField } from '../../../helpers/_isARequiredField';
import UnitApi from '../../../apis/UnitApi';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';

const ItemForm = (props: {
  afterSubmit: Function;
  id?: number;
  dataToBeEdited: {
    project_name: string;
    quantity: number;
    unit_id: number;
    price: number;
    discount: number;
  };
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [units, setUnits] = useState<SelectItem[]>([]);

  const [fields, setFields] = useState<IFormGeneratorFieldProps[]>([
    {
      name: 'project_name',
      label: 'Project Detail Desc',
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'quantity',
      label: 'Qty',
      type: IFormGeneratorFieldType.Number,
    },
    {
      name: 'unit_id',
      label: 'Unit',
      type: IFormGeneratorFieldType.Dropdown,
      placeholder: 'Please select a unit',
    },
    {
      name: 'price',
      label: '@Price',
      type: IFormGeneratorFieldType.Number,
    },
    {
      name: 'amount',
      label: 'Amount',
      disabled: true,
      type: IFormGeneratorFieldType.Number,
    },
    {
      name: 'discount',
      label: 'Discount',
      type: IFormGeneratorFieldType.Number,
    },
    {
      name: 'total',
      label: 'Total Amount',
      disabled: true,
      type: IFormGeneratorFieldType.Number,
    },
  ]);

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    let submittedUnit = units.find((x) => Number(x.value) === Number(values.unit_id));
    values.unit = submittedUnit;
    values.id = props.id;
    props.afterSubmit(values);
  };

  const priceOrQuantityOnChange = (value: any, fieldName: string) => {
    let values = form.values;

    let discount = values.discount ? values.discount : 0;
    let quantity = values.quantity ? values.quantity : 0;
    let price = values.price ? values.price : 0;

    if (fieldName === 'discount') {
      discount = value;
    }

    if (fieldName === 'quantity') {
      quantity = value;
    }

    if (fieldName === 'price') {
      price = value;
    }

    let total = price * quantity;
    let totalAmount = quantity * price - discount;

    form.setFieldValue('amount', total);
    form.setFieldValue('total', totalAmount);
    form.setFieldValue(fieldName, value);
  };

  useEffect(() => {
    getUnit();

    if (props.dataToBeEdited) {
      const { project_name, quantity, unit_id, price, discount } = props.dataToBeEdited;

      let integerPrice = Number(price);
      let integerDiscount = Number(discount);

      form.setValues({
        project_name,
        quantity,
        unit_id,
        price: integerPrice,
        discount: integerDiscount,
        amount: quantity * integerPrice,
        total: quantity * integerPrice - integerDiscount,
      });
    }
  }, []);

  const getUnit = () => {
    setIsLoading(true);
    UnitApi.getDataSource()
      .then((res) => {
        setUnits(res.data);

        let newFields = [...fields];

        let untiFieldIndex = newFields.findIndex((x) => x.name === 'unit_id');
        if (untiFieldIndex > -1) {
          newFields[untiFieldIndex].datasource = res.data;
        }

        setFields(newFields);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validationSchema = object().shape({
    project_name: string().required(requiredField('Project Detail Desc')).nullable(),
    quantity: number().required(requiredField('Quantity')).nullable(),
    unit_id: string().required(requiredField('Unit')).nullable(),
    price: number().required(requiredField('Price')).nullable(),
    discount: number().required(requiredField('Discount')).nullable(),
  });

  const form = useForm<any>({
    initialValues: {
      project_name: '',
      quantity: null,
      unit_id: null,
      price: null,
      discount: null,
    },
    validate: yupResolver(validationSchema),
  });

  return (
    <Box>
      <form onSubmit={form.onSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <DynamicField
            field={field}
            form={form}
            key={index}
            onChange={
              field.name === 'quantity' || field.name === 'price' || field.name === 'discount'
                ? (e: any) => priceOrQuantityOnChange(e, field.name)
                : null
            }
          />
        ))}

        <Group position='right' mt='md'>
          <Button type='submit' loading={isSubmitting}>
            Submit
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default ItemForm;
