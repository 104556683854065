import { Route, Routes } from 'react-router-dom';
import AccountPayableList from './list';
import AccountPayableCreateForm from './create';
import AccountPayableEditForm from './edit';
import AccountPayableHistory from './history';

const AccountPayableMasterLayout = () => {
    return (
        <>
            <Routes>
                <Route index element={<AccountPayableList />}></Route>
                <Route path='/create' element={<AccountPayableCreateForm />}></Route>
                <Route path='/update/:accountReceivableId' element={<AccountPayableEditForm />}></Route>
                <Route path='/history' element={<AccountPayableHistory />}></Route>
            </Routes>
        </>
    );
};

export default AccountPayableMasterLayout;
