import { AxiosResponse } from 'axios';
import qs from 'qs';
import _fetch from '../helpers/_fetch';
import {
  IBank,
  IBankDataTableRequest,
  IBankDataTableResponse,
  IPostBankRequest,
} from '../interfaces/requests/IBankRequest';

const url = '/bank';

const BankApi = {
  getDataTable: (
    body: IBankDataTableRequest = {
      page: 1,
      limit: 10,
    },
    filter: any
  ): Promise<AxiosResponse<IBankDataTableResponse>> => {
    let extraUrl = qs.stringify(filter);
    return _fetch<IBankDataTableResponse>(
      `${url}/dt?page=${body.page}&limit=${body.limit}&${extraUrl ? extraUrl : ''}`,
      {
        method: 'POST',
      }
    );
  },
  create: (body: IBank): Promise<AxiosResponse<IPostBankRequest>> => {
    return _fetch<IPostBankRequest>(`${url}`, {
      method: 'POST',
      body: body,
    });
  },
  remove: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`, {
      method: 'delete',
    });
  },
  getById: (id?: number): Promise<AxiosResponse<IBank>> => {
    return _fetch<IBank>(`${url}/${id}`);
  },
};

export default BankApi;
