import { Route, Routes } from 'react-router-dom'
import AccountPayableReportList from './list'

const AccountPayableReportMasterLayout = () => {
 return (
  <>
   <Routes>
    <Route index element={<AccountPayableReportList />}></Route>
   </Routes>
  </>
 )
}

export default AccountPayableReportMasterLayout
