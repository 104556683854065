import InnerLayout from '../../InnerLayout';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import ListToolbar from '../../../components/ListToolbar';
import { ActionIcon, Button, Group, Modal, Textarea, Tooltip } from '@mantine/core';
import { Check, Pencil, Plus, Truck, X } from 'tabler-icons-react';
import OurTable from '../../../components/OurTable';
import { useEffect, useState } from "react";
import ErrorHandler from "../../../helpers/_errorHandler";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

const links = [
	{
		title: 'Dashboard',
		link: 'dashboard',
	},
	{
		title: 'v1',
		link: 'dashboard',
	},
	{
		title: 'v2',
		link: 'dashboard',
	},
];

const PurchaseInvoiceList = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [totalPage, setTotalPage] = useState(0);

	const navigate = useNavigate();
	const handleCreateRoute = () => {
		navigate("/purchase-invoice/create");
	}

	const getList = () => {
		// setIsLoading(true);
		// PurchaseInvoiceApi.getDataTable({
		// 	limit: 10,
		// 	page: 1,
		// })
		// 	.then((res) => {
		// 	})
		// 	.catch((err) => {
		// 		let error = ErrorHandler(err);
		// 		showNotification({ message: error.message, title: 'Error!', color: 'red' });
		// 	})
		// 	.finally(() => {
		// 		setIsLoading(false);
		// 	});
	}


	const handleChangePage = (value: any) => {
		setPage(value);
	}
	useEffect(() => {
		if (!data.length) {
			getList();
		}
	}, [])

	if (isLoading) {
		return <div>Loading...</div>
	}

	const columns = [
		{
			title: 'Code',
			accessor: 'code',
		},
		{
			title: 'Status',
			accessor: 'status_in_string',
		},
		{
			title: 'Date',
			accessor: 'date',
		},
		{
			title: 'Document No',
			accessor: 'document_no',
		},
		{
			title: 'Payment',
			accessor: 'payment',
		},
		{
			title: 'Term',
			accessor: 'term',
		},
		{
			title: 'Action',
			accessor: '',
			customRender: (item: any) => {
				return (
					<Group spacing={'xs'}>
						<Tooltip
							label="Complete PO"
							withArrow
						>
							{/* <ActionIcon disabled={item.status_in_string.toUpperCase() !== "OPEN" ? true : false} variant='light' color='green' onClick={() => handleCompletePO(item)}>
								<Truck size={16} />
							</ActionIcon> */}
						</Tooltip>
					</Group >
				);
			},
		},
	]

	return (
		<InnerLayout title='Purchase Invoice'>
			<OurBreadcrumbs crumbs={links} />
			<ListToolbar>
				<Group>
					<Button size='sm' leftIcon={<Plus size={16} />} onClick={handleCreateRoute}>
						Create Purchase Invoice
					</Button>
				</Group>
			</ListToolbar>
			<OurTable isLoading={isLoading} page={page} handleChangePage={handleChangePage} totalPage={totalPage} columns={columns} data={data} />
		</InnerLayout >
	)
}

export default PurchaseInvoiceList