import { ActionIcon, Button, Group, Modal, Switch, Text } from '@mantine/core';
import { Check, Pencil, Trash } from 'tabler-icons-react';
import { Plus } from 'tabler-icons-react';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import InnerLayout from '../../InnerLayout';
import OurTable, { IOurColumnFilterType } from '../../../components/OurTable';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import CustomerApi from '../../../apis/CustomerApi';
import { ICustomer } from '../../../interfaces/requests/ICustomerRequest';
import CustomerForm from './form';
import { useModals } from '@mantine/modals';
import OurFilter, { IFilterFieldType } from '../../../components/OurFilter';

const links = [
  {
    title: 'App',
    link: 'dashboard',
  },
  {
    title: 'Customer',
    link: 'customer',
  },
];

const CustomerList = () => {
  const [data, setData] = useState<ICustomer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>(0);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [datatableParams, setDatatableParams] = useState<any>({
    page: 1,
  });

  const modals = useModals();

  const getList = () => {
    setIsLoading(true);
    const { page, ...rest } = datatableParams;
    CustomerApi.getDataTable(
      {
        limit,
        page,
      },
      rest
    )
      .then((res) => {
        setTotalPage(res.data.last_page);
        setData(res.data.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  const handleChangePage = (value: any) => {
    setDatatableParams(value);
  };

  const afterSubmit = () => {
    toggleModal();
    getList();
  };

  useEffect(() => {
    if (!data.length) {
      getList();
    }
  }, []);

  useEffect(() => {
    getList();
  }, [datatableParams]);

  const toCreate = () => {
    setId(undefined);
    toggleModal();
  };

  const toEdit = (item: ICustomer) => {
    setId(item.id);
    toggleModal();
  };

  const remove = (item: ICustomer) => {
    setIsLoading(true);
    CustomerApi.remove(item.id)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Customer Removed!',
          icon: <Trash />,
        });
        getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDeletePrompt = (data: any) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(data),
    });
  };

  const onUpdateActive = (checked: boolean, id: number) => {
    setIsLoading(true);
    CustomerApi.toggleActive(id)
      .then(() => {
        showNotification({
          message: 'Process Successfully!',
          title: 'Customer Updated!',
          icon: <Check />,
        });
        getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      title: 'Active',
      accessor: '',
      customRender: (item: any) => {
        return (
          <Switch
            color='green'
            checked={item.active}
            onChange={(event) => onUpdateActive(event.target.checked, item.id)}
            size='xs'
          />
        );
      },
    },
    {
      title: 'Code',
      accessor: 'code',
      withFilter: true,
      filter: {
        name: 'code',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Name',
      accessor: 'name',
      withFilter: true,
      filter: {
        name: 'name',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Contact Person',
      accessor: 'contact_person',
      withFilter: true,
      filter: {
        name: 'contact_person',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Phone',
      accessor: 'phone_1',
      withFilter: true,
      filter: {
        name: 'phone_1',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Address',
      accessor: 'address',
      withFilter: true,
      filter: {
        name: 'address',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Email',
      accessor: 'email',
      withFilter: true,
      filter: {
        name: 'email',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Fax',
      accessor: 'fax',
      withFilter: true,
      filter: {
        name: 'fax',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: ICustomer) => {
        return (
          <Group spacing={'xs'}>
            <ActionIcon variant='light' color='blue[4]' onClick={() => toEdit(item)}>
              <Pencil size={16} />
            </ActionIcon>
            <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
              <Trash size={16} />
            </ActionIcon>
          </Group>
        );
      },
    },
  ];

  const filterColumns = [
    {
      title: 'Code',
      type: IFilterFieldType.Text,
    },
    {
      title: 'Name',
      type: IFilterFieldType.Text,
    },
    {
      title: 'Contact Person',
      type: IFilterFieldType.Text,
    },
    {
      title: 'Phone',
      type: IFilterFieldType.Text,
    },
    {
      title: 'Address',
      type: IFilterFieldType.Text,
    },
    {
      title: 'Email',
      type: IFilterFieldType.Text,
    },
    {
      title: 'Fax',
      type: IFilterFieldType.Text,
    },
  ];

  return (
    <InnerLayout title='Customer'>
      <OurBreadcrumbs crumbs={links} />
      <ListToolbar>
        <Group>
          <Button size='sm' leftIcon={<Plus size={16} />} onClick={toCreate}>
            Create Customer
          </Button>
        </Group>
      </ListToolbar>
      <OurTable
        isLoading={isLoading}
        page={datatableParams.page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={columns}
        data={data}
      />
      <Modal opened={createModalOpened} onClose={toggleModal} title={id ? 'Update Customer' : 'Create Customer'}>
        <CustomerForm afterSubmit={afterSubmit} id={id} />
      </Modal>
    </InnerLayout>
  );
};

export default CustomerList;
