import { Route, Routes } from 'react-router-dom';
import StockInList from './list';
import StockInCreateForm from './create';
import StockInEditForm from './edit';

const StockInMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<StockInList />}></Route>
        <Route path='/create' element={<StockInCreateForm />}></Route>
        <Route path='/update/:id' element={<StockInEditForm />}></Route>
      </Routes>
    </>
  );
};

export default StockInMasterLayout;
