import { Button, Text, Grid, NativeSelect, SelectItem, Space, Checkbox, Modal, Input } from '@mantine/core'
import { Trash, Search, FileSpreadsheet, Printer } from 'tabler-icons-react'
import { useEffect, useState } from 'react'
import ErrorHandler from '../../../helpers/_errorHandler'
import { showNotification } from '@mantine/notifications'
import SupplierApi from '../../../apis/SupplierApi'
import { ISupplier } from '../../../interfaces/requests/ISupplierRequest'
import { useModals } from '@mantine/modals'
import { useNavigate } from 'react-router-dom'
import DynamicField from '../../../components/DynamicField'
import { IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps'
import { useForm } from '@mantine/form'
import CurrencyApi from '../../../apis/CurrencyApi'
import SearchCustomerModal from './searchCustomerModal'
import EnumerationApi from '../../../apis/EnumerationApi'
import moment from 'moment'
import { baseURL } from '../../../helpers/_fetch'

const ARBalance = () => {
 const [data, setData] = useState<ISupplier[]>([])
 const [isLoading, setIsLoading] = useState<boolean>(false)
 const [createModalOpened, setCreateModalOpened] = useState<boolean>(false)
 const [id, setId] = useState<number | undefined>(0)
 const [currencyList, setCurrencyList] = useState<SelectItem[]>([
  {
   value: '',
   label: 'Please choose one option',
  },
 ])
 const [page, setPage] = useState(1)
 const [limit, setLimit] = useState(10)
 const [totalPage, setTotalPage] = useState(0)
 const [reportTypes, setReportTypes] = useState<
  {
   value: number
   display_name: string
  }[]
 >([])
 const [reportTypeSelected, setReportTypeSelected] = useState('')
 const [customerSelected, setCustomerSelected] = useState({
  id: null,
  name: '',
 })

 const [currencySelected, setCurrencySelected] = useState('')

 const [showSearchCustomerModal, setShowSearchCustomerModal] = useState<boolean>(false)

 const navigate = useNavigate()

 const modals = useModals()

 const getList = () => {
  setIsLoading(true)
  SupplierApi.getDataTable(
   {
    limit,
    page,
   },
   {}
  )
   .then((res) => {
    setTotalPage(res.data.last_page)
    setData(res.data.data)
   })
   .catch((err) => {
    let error = ErrorHandler(err)
    showNotification({ message: error.message, title: 'Error!', color: 'red' })
   })
   .finally(() => {
    setIsLoading(false)
   })
 }

 const toggleModal = () => {
  setCreateModalOpened(!createModalOpened)
 }

 useEffect(() => {
  if (!data.length) {
   getList()
   getCurrencyList()

   getSalesInvoiceReportTypeEnum()
  }
 }, [])

 const getSalesInvoiceReportTypeEnum = () => {
  EnumerationApi.getDataSource('sales_invoice_report_type').then((res) => {
   let result = [...res.data].map((x) => {
    return {
     ...x,
     value: x.value.toString(),
    }
   })

   setReportTypes(result)
   setReportTypeSelected(result[0].value)
  })
 }

 const getCurrencyList = () => {
  setIsLoading(true)
  CurrencyApi.getDataSource()
   .then((res) => {
    let result = [
     {
      value: '',
      label: 'Please choose one option',
     },
     ...res.data,
    ]

    setCurrencySelected(result[0].value)
    setCurrencyList(result)
   })
   .catch((err) => {
    let error = ErrorHandler(err)
    showNotification({ message: error.message, title: 'Error!', color: 'red' })
   })
   .finally(() => {
    setIsLoading(false)
   })
 }

 const toCreate = () => {
  navigate('create')
 }

 const toEdit = (item: ISupplier) => {
  setId(item.id)
  toggleModal()
 }

 const remove = (item: ISupplier) => {
  setIsLoading(true)
  SupplierApi.remove(item.id)
   .then((res) => {
    showNotification({
     message: 'Proccess Successfully!',
     title: 'Supplier Removed!',
     icon: <Trash />,
    })
    getList()
   })
   .catch((err) => {
    let error = ErrorHandler(err)
    showNotification({ message: error.message, title: 'Error!', color: 'red' })
   })
   .finally(() => {
    setIsLoading(false)
   })
 }

 const onDeletePrompt = (data: any) => {
  modals.openConfirmModal({
   title: 'Please confirm your action',
   children: (
    <Text size='sm'>
     This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
    </Text>
   ),
   labels: { confirm: 'Confirm', cancel: 'Cancel' },
   onConfirm: () => remove(data),
  })
 }

 const form = useForm({
  initialValues: {
   customer_id: null,
   project_title: '',
   fromDate: new Date(),
   toDate: new Date(),
  },
 })

 const toggleSearchCustomerModal = () => {
  setShowSearchCustomerModal(!showSearchCustomerModal)
 }

 const onCustomerSelected = (customer: any) => {
  setCustomerSelected(customer)
  toggleSearchCustomerModal()
 }

 const generateUrlForExport = () => {
  let body = {
   customer_id: Number(customerSelected.id),
   currency_id: Number(currencySelected),
   start_date: moment(form.values.fromDate).format('YYYY-MM-DD'),
   end_date: moment(form.values.toDate).format('YYYY-MM-DD'),
  }

  let newUrl = `${baseURL}/sales-invoice/report?`

  if (body.customer_id) {
   newUrl += `customer_id=${body.customer_id}`
  }

  if (body.currency_id) {
   newUrl += `&currency_id=${body.currency_id}`
  }

  if (body.start_date) {
   newUrl += `&start_date=${body.start_date}`
  }

  if (body.end_date) {
   newUrl += `&end_date=${body.end_date}`
  }

  return newUrl
 }

 if (isLoading) {
  return <div>Loading..</div>
 }

 return (
  <>
   <div
    style={{
     minHeight: 'calc(100vh - 200px)',
     display: 'flex',
     flexDirection: 'column',
     justifyContent: 'space-between',
    }}>
    <div>
     <Space h={24} />
     <Grid>
      <Grid.Col span={4}>
       <DynamicField
        field={{
         name: 'period',
         type: IFormGeneratorFieldType.Date,
         label: 'Period',
        }}
        form={form}
       />
       <NativeSelect
        mt='sm'
        label='Currency'
        data={currencyList}
        value={currencySelected}
        onChange={(data) => setCurrencySelected(data.target.value)}
       />
       <div style={{ marginTop: '12px' }}>
        <span
         style={{
          color: '#212529',
          fontWeight: 500,
          fontSize: '14px',
          marginBottom: '4px',
         }}>
         Customer [F1]
        </span>
        <Input style={{ marginTop: '4px' }} value={customerSelected.name} name='customer' />
       </div>
       <NativeSelect
        mt='sm'
        label='Sort ID Customer'
        data={[]}
        // value={currencySelected}
        // onChange={(data) => setCurrencySelected(data.target.value)}
       />
       <div style={{ marginTop: '12px' }}>
        <Checkbox label='Show All Data' />
       </div>
      </Grid.Col>
     </Grid>
    </div>
    <div>
     <Grid style={{ marginBottom: '64px' }}>
      <Grid.Col style={{ display: 'flex' }}>
       <Button
        onClick={() => {
         let url = generateUrlForExport()
         window.open(url, '_blank')
        }}
        size='sm'
        mt='40px'
        mr='6px'
        leftIcon={<FileSpreadsheet size={16} />}>
        Export To Excel
       </Button>
       <Button variant='outline' size='sm' mt='40px' leftIcon={<Printer size={16} />}>
        Print
       </Button>
      </Grid.Col>
     </Grid>
    </div>
   </div>
   <Modal size='xl' title='Search Customer' opened={showSearchCustomerModal} onClose={toggleSearchCustomerModal}>
    <SearchCustomerModal onCustomerSelected={onCustomerSelected} />
   </Modal>
  </>
 )
}

export default ARBalance
