import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

// export const baseURL = 'https://mkb.arkaiv.id/api';
export const baseURL = `${process.env.REACT_APP_BACKEND_URL}/api`;

interface IApiRequestConfig {
  method?: string;
  body?: any;
  contentType?: string;
}

const getAuth = (): string => {
  let accessToken = localStorage.getItem('access_token');

  return `Bearer ${accessToken}`;
};

const _fetch = async <T,>(
  url: string,
  options: IApiRequestConfig = {
    method: 'GET',
    body: {},
    contentType: 'application/json',
  }
): Promise<AxiosResponse<T>> => {
  let request: AxiosRequestConfig = {
    url,
    baseURL,
    method: options.method,
    headers: {
      Authorization: getAuth(),
      'Content-Type': options.contentType || 'application/json',
      Accept: 'application/json',
    },
  };

  if (options.method === 'POST' || options.method === 'PUT') {
    request.data = options.body;
  }

  try {
    let response: AxiosResponse<T> = await axios.request<T>(request);

    if (response.status >= 200 && response.status < 400) {
      return response;
    }

    throw { message: 'Unexpected Error while calling API' };
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }
    throw error;
  }
};

export default _fetch;
