import { Button, Text, Grid, NativeSelect, SelectItem, Space, Radio, Modal, Input } from '@mantine/core'
import { Trash, Search, FileSpreadsheet, Printer } from 'tabler-icons-react'
import OurBreadcrumbs from '../../../components/OurBreadcrumbs'
import InnerLayout from '../../InnerLayout'
import APBalance from './APBalance'
import APPayment from './APPayment'
import MonthlyAP from './MonthlyAP'
import { Tabs } from '@mantine/core'

const links = [
 {
  title: 'Dashboard',
  link: 'dashboard',
 },
 {
  title: 'Account Payable Report',
 },
]

const AccountPayableReportList = () => {
 return (
  <InnerLayout title='Account Payable Report'>
   <div>
    <OurBreadcrumbs crumbs={links} />
    <Space h={48} />
    <Tabs defaultValue='apbalance'>
     <Tabs.List grow>
      <Tabs.Tab value='apbalance'>AP Balance</Tabs.Tab>
      <Tabs.Tab value='appayment'>AP Payment</Tabs.Tab>
      <Tabs.Tab value='monthlyap'>Monthly AP</Tabs.Tab>
     </Tabs.List>
     <Tabs.Panel value='apbalance'>
      <APBalance />
     </Tabs.Panel>
     <Tabs.Panel value='appayment'>
      <APPayment />
     </Tabs.Panel>
     <Tabs.Panel value='monthlyap'>
      <MonthlyAP />
     </Tabs.Panel>
    </Tabs>
   </div>
  </InnerLayout>
 )
}

export default AccountPayableReportList
