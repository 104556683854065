import { Route, Routes } from 'react-router-dom';
import CustomerList from './list';

const CustomerMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<CustomerList />} />
      </Routes>
    </>
  );
};

export default CustomerMasterLayout;
