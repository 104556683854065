import {
  ActionIcon,
  Button,
  Group,
  Text,
  Grid,
  NativeSelect,
  SelectItem,
  Space,
  Input,
  Checkbox,
  Tooltip,
  Popover,
  CheckboxIcon,
  CheckIcon,
} from '@mantine/core';
import { Pencil, Trash, Printer, ReportMoney, Filter } from 'tabler-icons-react';
import { Plus } from 'tabler-icons-react';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import InnerLayout from '../../InnerLayout';
import OurTable from '../../../components/OurTable';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import SupplierApi from '../../../apis/SupplierApi';
import { useModals } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import DynamicField from '../../../components/DynamicField';
import { IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import { useForm } from '@mantine/form';
import CurrencyApi from '../../../apis/CurrencyApi';
import SalesInvoiceApi from '../../../apis/SalesInvoiceApi';
import { ISalesInvoice } from '../../../interfaces/requests/ISalesInvoiceRequest';
import moment from 'moment';
import TwoDecimalPlaces from '../../../helpers/_twoDecimalPlaces';

const links = [
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
  {
    title: 'Sales Invoice',
  },
];

const SalesInvoiceList = () => {
  const [data, setData] = useState<ISalesInvoice[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>(0);
  const [currencyList, setCurrencyList] = useState<SelectItem[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [openFilterPopover, setOpenFilterPopover] = useState<boolean>(false);

  const [searchByDataSource] = useState<SelectItem[]>([
    {
      value: 'invoiceNo',
      label: 'Invoice No',
    },
    {
      value: 'projectNo',
      label: 'Project No',
    },
    {
      value: 'projectTitle',
      label: 'Project Title',
    },
    {
      value: 'idCustomer',
      label: 'ID Customer',
    },
    {
      value: 'customerName',
      label: 'Customer Name',
    },
    {
      value: 'poNo',
      label: 'PO No',
    },
    {
      value: 'doNo',
      label: 'DO No',
    },
    {
      value: 'detailItem',
      label: 'Detail Item',
    },
  ]);
  const [searchBy, selectedSearchBy] = useState<SelectItem>({
    value: 'docNo',
    label: 'Doc No',
  });

  const navigate = useNavigate();

  const modals = useModals();

  const getList = () => {
    setIsLoading(true);
    SalesInvoiceApi.getDataTable({
      limit,
      page,
    })
      .then((res) => {
        setTotalPage(res.data.last_page);
        setData(res.data.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  useEffect(() => {
    if (!data.length) {
      getList();
      getCurrencyList();
    }
  }, []);

  const getCurrencyList = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrencyList(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toCreate = () => {
    navigate('create');
  };

  const toEdit = (salesInvoiceId: number) => {
    navigate(`/sales-invoice/update/${salesInvoiceId}`);
  };

  const remove = (item: ISalesInvoice) => {
    setIsLoading(true);
    SalesInvoiceApi.remove(item.id)
      .then(() => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Sales Invoice Removed!',
          icon: <Trash />,
        });
        getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDeletePrompt = (data: any) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(data),
    });
  };

  const columns = [
    {
      title: 'Status',
      accessor: 'status_in_string',
    },
    {
      title: 'Date',
      accessor: 'date',
      customRender: (item: any) => {
        return <span style={{ whiteSpace: 'nowrap' }}> {moment(item.data).format('YYYY-MM-DD')}</span>;
      },
    },
    {
      title: 'Invoice No',
      accessor: 'invoice_no',
    },
    {
      title: 'Project No',
      accessor: 'project_no',
      customRender: (item: any) => {
        return item.sales_order.project_no;
      },
    },
    {
      title: 'Project Title',
      accessor: 'project_title',
      customRender: (item: any) => {
        return item.sales_order.project_title;
      },
    },
    {
      title: 'ID Cust',
      accessor: 'customer_id',
    },
    {
      title: 'Customer Name',
      accessor: 'customerName',
      customRender: (item: any) => {
        return item.customer ? item.customer.name : '-';
      },
    },
    {
      title: 'PO No',
      accessor: 'po_no',
    },
    {
      title: 'DO No',
      accessor: 'do_no',
    },
    {
      title: 'Payment',
      accessor: 'payment',
      customRender: (item: any) => {
        return item.payment_in_string;
      },
    },
    {
      title: 'Term',
      accessor: 'terms',
    },
    {
      title: 'Curr',
      accessor: 'currency',
      customRender: (item: any) => {
        return item.currency.name;
      },
    },
    {
      title: 'Rate',
      accessor: 'rate',
      customRender: (item: any) => {
        return item.currency.rate;
      },
    },
    {
      title: 'Total',
      accessor: 'total',
      customRender: (item: any) => {
        return TwoDecimalPlaces(item.total);
      },
    },
    {
      title: 'Disc',
      accessor: 'discount',
      customRender: (item: any) => {
        return Math.round(item.discount);
      },
    },
    {
      title: 'Tax',
      accessor: 'tax',
      customRender: (item: any) => {
        let useTax = Number(item.tax) > 0;
        return useTax ? <CheckIcon /> : '-';
      },
    },
    {
      title: 'Tax Percentage',
      accessor: 'tax',
      customRender: (item: any) => {
        return `${TwoDecimalPlaces(item.tax * 100)}%`;
      },
    },
    {
      title: 'Tax Amount',
      accessor: 'tax_amount',
      customRender: (item: any) => {
        return TwoDecimalPlaces(item.tax_amount);
      },
    },
    {
      title: 'Grand Total',
      accessor: 'grandTotal',
    },
    {
      title: 'HM Curr Total',
      accessor: 'hmCurrencyTotal',
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: ISalesInvoice) => {
        return (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Tooltip label='Edit'>
              <ActionIcon variant='light' color='blue[4]' onClick={() => toEdit(item.id!)}>
                <Pencil size={16} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label='Print'>
              <ActionIcon variant='light' color='blue[4]' onClick={() => print(item.id!)}>
                <Printer size={16} />
              </ActionIcon>
            </Tooltip>
            {/* <Tooltip label='Print Project Cost'>
              <ActionIcon variant='light' color='blue[4]'>
                <ReportMoney size={16} />
              </ActionIcon>
            </Tooltip> */}
            <Tooltip label='Delete'>
              <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
                <Trash size={16} />
              </ActionIcon>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const print = (salesInvoiceId: number) => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/api/sales-invoice/single-report/${salesInvoiceId}`, '_blank');
  };

  const toggleFilterPopover = () => {
    setOpenFilterPopover(!openFilterPopover);
  };

  const form = useForm({
    initialValues: {
      customer_id: null,
      project_title: '',
      fromDate: new Date(),
      toDate: new Date(),
    },
  });

  if (isLoading) {
    return <div>Loading..</div>;
  }

  return (
    <InnerLayout title='Sales Invoice'>
      <OurBreadcrumbs crumbs={links} />

      <Space h={24} />

      <ListToolbar>
        <Group style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Popover
            width={700}
            position='right'
            withArrow
            shadow='md'
            opened={openFilterPopover}
            onClose={toggleFilterPopover}
          >
            <Popover.Target>
              <Button variant='outline' size='sm' onClick={toggleFilterPopover} leftIcon={<Filter size={16} />}>
                Filter
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <>
                <Grid>
                  <Grid.Col span={4}>
                    <DynamicField
                      field={{
                        name: 'period',
                        type: IFormGeneratorFieldType.Dropdown,
                        label: 'Period',
                        datasource: [
                          {
                            value: '202206',
                            label: '202206',
                          },
                        ],
                      }}
                      form={form}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <DynamicField
                      field={{
                        name: 'fromDate',
                        type: IFormGeneratorFieldType.Date,
                        label: 'From Date',
                      }}
                      form={form}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <DynamicField
                      field={{
                        name: 'toDate',
                        type: IFormGeneratorFieldType.Date,
                        label: 'To Date',
                      }}
                      form={form}
                    />
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Payment'
                      data={[
                        {
                          label: 'ALL',
                          value: 'all',
                        },
                        {
                          label: 'Cash',
                          value: 'cash',
                        },
                        {
                          label: 'AR',
                          value: 'ar',
                        },
                      ]}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Status'
                      data={[
                        {
                          value: 'all',
                          label: 'ALL',
                        },
                        {
                          value: 'open',
                          label: 'OPEN',
                        },
                        {
                          value: 'cancel',
                          label: 'CANCEL',
                        },
                      ]}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Curr'
                      data={currencyList}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Search By'
                      data={searchByDataSource}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <DynamicField
                      field={{
                        name: 'keyword',
                        type: IFormGeneratorFieldType.Text,
                        label: 'Keyword',
                      }}
                      form={form}
                    />
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col mt='xs'>
                    <Button size='sm' style={{ float: 'right' }}>
                      Reset
                    </Button>
                  </Grid.Col>
                </Grid>
              </>
            </Popover.Dropdown>
          </Popover>

          <Button size='sm' leftIcon={<Plus size={16} />} onClick={toCreate}>
            Create Sales Invoice
          </Button>
        </Group>
      </ListToolbar>
      <OurTable
        isLoading={isLoading}
        page={page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={columns}
        data={data}
      />
    </InnerLayout>
  );
};

export default SalesInvoiceList;
