import { SelectItem } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

export interface IFormGeneratorProps {
  fields: IFormGeneratorFieldProps[];
  onSubmit: Function;
  initialValues: any;
  loading: boolean;
}

export interface IFormGeneratorFieldProps {
  name: string;
  type: IFormGeneratorFieldType;
  required?: boolean;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  datasource?: SelectItem[];
}

export enum IFormGeneratorFieldType {
  Text,
  Number,
  Dropdown,
  Checkbox,
  Password,
  Date,
  TextArea,
}

export interface IDynamicFieldProps {
  field: IFormGeneratorFieldProps;
  form: UseFormReturnType<any>;
  onChange?: any;
}
