import { Route, Routes } from 'react-router-dom';
import BankList from './list';

const BankMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<BankList />} />
      </Routes>
    </>
  );
};

export default BankMasterLayout;
