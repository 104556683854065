import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { Check } from 'tabler-icons-react';
import ItemApi from '../../../apis/ItemApi';
import UnitApi from '../../../apis/UnitApi';
import FormGenerator from '../../../components/FormGenerator';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import { IItem } from '../../../interfaces/requests/IItemRequest';

const ItemForm = (props: { afterSubmit: Function; id?: number }) => {
    const [fields, setFields] = useState<IFormGeneratorFieldProps[]>([
        {
            name: 'code',
            label: 'Code',
            required: true,
            type: IFormGeneratorFieldType.Text,
        },
        {
            name: 'name',
            label: 'Name',
            required: true,
            type: IFormGeneratorFieldType.Text,
        },
        {
            name: 'description',
            label: 'Description',
            required: true,
            type: IFormGeneratorFieldType.Text,
        },
        {
            name: 'size',
            label: 'Size',
            required: true,
            type: IFormGeneratorFieldType.Text,
        },
        {
            name: 'weight',
            label: 'Weight',
            required: true,
            type: IFormGeneratorFieldType.Text,
        },
        {
            name: 'length',
            label: 'Length',
            required: true,
            type: IFormGeneratorFieldType.Text,
        },
        {
            name: 'dimensions',
            label: 'Dimensions',
            required: true,
            type: IFormGeneratorFieldType.Text,
        },
        {
            name: 'unit_id',
            label: 'Unit',
            required: true,
            type: IFormGeneratorFieldType.Dropdown,
            datasource: [],
            placeholder: 'Please select a unit',
        },
        {
            name: 'active',
            label: 'Active',
            type: IFormGeneratorFieldType.Checkbox,
            required: true,
        },
    ]);

    const [initialValues, setInitialValues] = useState<IItem>({
        id: undefined,
        name: '',
        code: '',
        active: false,
        description: '',
        weight: '',
        dimensions: '',
        length: '',
        size: '',
        unit_id: '',
    });

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit = (values: any) => {
        setIsSubmitting(true);
        ItemApi.create(values)
            .then((res) => {
                showNotification({
                    message: 'Proccess Successfully!',
                    title: 'Unit Created!',
                    icon: <Check />,
                });

                if (props.afterSubmit) {
                    props.afterSubmit();
                }
            })
            .catch((err) => {
                let error = ErrorHandler(err);
                showNotification({ message: error.message, title: 'Error!', color: 'red' });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const getById = (id: number) => {
        ItemApi.getById(id)
            .then((res) => {
                setInitialValues(res.data);
            })
            .catch((err) => {
                let error = ErrorHandler(err);
                showNotification({ message: error.message, title: 'Error!', color: 'red' });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const getUnitList = () => {
        UnitApi.getDataSource()
            .then((res) => {
                let newFields = [...fields];

                let unitField = newFields.findIndex((field) => field.name === 'unit_id');

                if (unitField > -1) {
                    newFields[unitField].datasource = res.data;
                    setFields(newFields);
                }
            })
            .catch((err) => {
                let error = ErrorHandler(err);
                showNotification({ message: error.message, title: 'Error!', color: 'red' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (props.id !== undefined && props.id !== null && props.id) {
            getById(props.id);
        }
    }, [props.id]);

    useEffect(() => {
        getUnitList();
    }, []);

    return (
        <Box>
            <FormGenerator fields={fields} initialValues={initialValues} onSubmit={onSubmit} loading={isSubmitting} />
        </Box>
    );
}

export default ItemForm