
import { Route, Routes } from 'react-router-dom'
import PurchaseInvoiceList from './list'
import CreateOrEditPurchaseInvoiceMasterLayout from './createOrEdit'

const PurchaseInvoiceMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<PurchaseInvoiceList />}></Route>
        <Route path='/create' element={<CreateOrEditPurchaseInvoiceMasterLayout />}></Route>
        <Route path='/update/:id' element={<CreateOrEditPurchaseInvoiceMasterLayout />}></Route>
      </Routes>
    </>
  )
}

export default PurchaseInvoiceMasterLayout