import { ActionIcon, Button, Group, Text, Grid, NativeSelect, SelectItem, Space, Input, Popover } from '@mantine/core';
import { Pencil, Trash, Plus, Filter } from 'tabler-icons-react';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import InnerLayout from '../../InnerLayout';
import OurTable from '../../../components/OurTable';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import SupplierApi from '../../../apis/SupplierApi';
import { ISupplier } from '../../../interfaces/requests/ISupplierRequest';
import { useModals } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import DynamicField from '../../../components/DynamicField';
import { IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import { useForm } from '@mantine/form';
import CurrencyApi from '../../../apis/CurrencyApi';

const links = [
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
  {
    title: 'Stock In',
  },
];

const SalesReturnList = () => {
  const [data, setData] = useState<ISupplier[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>(0);
  const [currencyList, setCurrencyList] = useState<SelectItem[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [openFilterPopover, setOpenFilterPopover] = useState<boolean>(false);

  const [searchByDataSource] = useState<SelectItem[]>([
    {
      value: 'docNo',
      label: 'Doc No',
    },
  ]);
  const [searchBy, selectedSearchBy] = useState<SelectItem>({
    value: 'docNo',
    label: 'Doc No',
  });

  const navigate = useNavigate();

  const modals = useModals();

  const getList = () => {
    setIsLoading(true);
    SupplierApi.getDataTable(
      {
        limit,
        page,
      },
      {}
    )
      .then((res) => {
        setTotalPage(res.data.last_page);
        setData(res.data.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  useEffect(() => {
    if (!data.length) {
      getList();
      getCurrencyList();
    }
  }, []);

  const getCurrencyList = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrencyList(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toCreate = () => {
    navigate('create');
  };

  const toEdit = (item: ISupplier) => {
    setId(item.id);
    toggleModal();
  };

  const remove = (item: ISupplier) => {
    setIsLoading(true);
    SupplierApi.remove(item.id)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Supplier Removed!',
          icon: <Trash />,
        });
        getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDeletePrompt = (data: any) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(data),
    });
  };

  const columns = [
    {
      title: 'Status',
      accessor: 'status',
    },
    {
      title: 'Date',
      accessor: 'date',
    },
    {
      title: 'DO No',
      accessor: 'doNo',
    },
    {
      title: 'ID Supp',
      accessor: 'idSupp',
    },
    {
      title: 'Supplier Name',
      accessor: 'supplierName',
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: ISupplier) => {
        return (
          <Group spacing={'xs'}>
            <ActionIcon variant='light' color='blue[4]' onClick={() => toEdit(item)}>
              <Pencil size={16} />
            </ActionIcon>
            <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
              <Trash size={16} />
            </ActionIcon>
          </Group>
        );
      },
    },
  ];

  const toggleFilterPopover = () => {
    setOpenFilterPopover(!openFilterPopover);
  };

  const form = useForm({
    initialValues: {
      customer_id: null,
      project_title: '',
      fromDate: new Date(),
      toDate: new Date(),
    },
  });

  if (isLoading) {
    return <div>Loading..</div>;
  }

  return (
    <InnerLayout title='Stock In'>
      <OurBreadcrumbs crumbs={links} />

      <Space h={24} />

      <ListToolbar>
        <Group style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Popover
            width={700}
            position='right'
            withArrow
            shadow='md'
            opened={openFilterPopover}
            onClose={toggleFilterPopover}
          >
            <Popover.Target>
              <Button variant='outline' size='sm' onClick={toggleFilterPopover} leftIcon={<Filter size={16} />}>
                Filter
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <div>
                <Grid>
                  <Grid.Col span={4}>
                    <DynamicField
                      field={{
                        name: 'period',
                        type: IFormGeneratorFieldType.Dropdown,
                        label: 'Period',
                      }}
                      form={form}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <DynamicField
                      field={{
                        name: 'fromDate',
                        type: IFormGeneratorFieldType.Date,
                        label: 'From Date',
                      }}
                      form={form}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <DynamicField
                      field={{
                        name: 'toDate',
                        type: IFormGeneratorFieldType.Date,
                        label: 'To Date',
                      }}
                      form={form}
                    />
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Search By'
                      data={searchByDataSource}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <DynamicField
                      field={{
                        name: 'keyword',
                        type: IFormGeneratorFieldType.Text,
                        label: 'Keyword',
                      }}
                      form={form}
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Status'
                      data={[
                        {
                          value: 'all',
                          label: 'ALL',
                        },
                        {
                          value: 'open',
                          label: 'OPEN',
                        },
                        {
                          value: 'close',
                          label: 'CLOSE',
                        },
                        {
                          value: 'cancel',
                          label: 'CANCEL',
                        },
                      ]}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col mt='xs'>
                    <Button size='sm' style={{ float: 'right' }}>
                      Reset
                    </Button>
                  </Grid.Col>
                </Grid>
              </div>
            </Popover.Dropdown>
          </Popover>

          <Button size='sm' leftIcon={<Plus size={16} />} onClick={toCreate}>
            Create Stock In
          </Button>
        </Group>
      </ListToolbar>
      <OurTable
        isLoading={isLoading}
        page={page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={columns}
        data={data}
      />
      <Space h={12} />
      <Grid justify='end'>
        <Grid.Col span={3}>
          <span style={{ float: 'right' }}>Total S$</span>
        </Grid.Col>
        <Grid.Col span={3}>
          <Input type='number' />
        </Grid.Col>
      </Grid>
    </InnerLayout>
  );
};

export default SalesReturnList;
