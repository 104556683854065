import { Route, Routes } from 'react-router-dom'
import UserList from './list'

const UserMasterLayout = () => {
    return (
        <>
            <Routes>
                <Route index element={<UserList />} />
            </Routes>
        </>
    )
}

export default UserMasterLayout