import { Button, Text, Grid, NativeSelect, SelectItem, Space, Radio, Modal, Input } from '@mantine/core'
import { Trash, Search, FileSpreadsheet, Printer } from 'tabler-icons-react'
import OurBreadcrumbs from '../../../components/OurBreadcrumbs'
import InnerLayout from '../../InnerLayout'
import ARBalance from './ARBalance'
import ARAging from './ARAging'
import ARPayment from './ARPayment'
import MonthlyAR from './MonthlyAR'
import { Tabs } from '@mantine/core'

const links = [
 {
  title: 'Dashboard',
  link: 'dashboard',
 },
 {
  title: 'Account Receiveable Report',
 },
]

const AccountReceiveAbleReportList = () => {
 return (
  <InnerLayout title='Account Receiveable Report'>
   <div>
    <OurBreadcrumbs crumbs={links} />
    <Space h={48} />
    <Tabs defaultValue='arbalance'>
     <Tabs.List grow>
      <Tabs.Tab value='arbalance'>AR Balance</Tabs.Tab>
      <Tabs.Tab value='araging'>AR Aging</Tabs.Tab>
      <Tabs.Tab value='arpayment'>AR Payment</Tabs.Tab>
      <Tabs.Tab value='monthlyar'>Monthly AR</Tabs.Tab>
     </Tabs.List>
     <Tabs.Panel value='arbalance'>
      <ARBalance />
     </Tabs.Panel>
     <Tabs.Panel value='araging'>
      <ARAging />
     </Tabs.Panel>
     <Tabs.Panel value='arpayment'>
      <ARPayment />
     </Tabs.Panel>
     <Tabs.Panel value='monthlyar'>
      <MonthlyAR />
     </Tabs.Panel>
    </Tabs>
   </div>
  </InnerLayout>
 )
}

export default AccountReceiveAbleReportList
