import { Box, NativeSelect, Grid, Input, Button, Space, Group, ActionIcon, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { Search, Check } from 'tabler-icons-react';
import OurTable from '../../../components/OurTable';
import DynamicField from '../../../components/DynamicField';
import { IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import moment from 'moment';
import QuotationApi from '../../../apis/QuotationApi';
import { showNotification } from '@mantine/notifications';
import { IItem } from '../../../interfaces/requests/IItemRequest';
import ErrorHandler from '../../../helpers/_errorHandler';
import { ISalesQuotation } from '../../../interfaces/requests/ISalesQuotation';

const ItemForm = (props: any) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);

  const [searchBy, setSearchBy] = useState('quotation_no');
  const [searchByValue, setSearchByValue] = useState('');
  const [searchQuotationList, SetSearchQuotationList] = useState<ISalesQuotation[]>([]);

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    const { fromDate, toDate } = values;

    let body = {
      start_date: fromDate ? moment(fromDate).format('YYYY-MM-DD') : null,
      end_date: toDate ? moment(toDate).format('YYYY-MM-DD') : null,
      [searchBy]: searchByValue,
    };

    QuotationApi.search(body)
      .then((res) => {
        SetSearchQuotationList(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const columns = [
    {
      title: 'Date',
      accessor: 'date',
      customRender: (item: any) => {
        return moment(item.date).format('DD MMM YYYY');
      },
    },
    {
      title: 'Quotation No',
      accessor: 'quotation_no',
    },
    {
      title: 'Rev#',
      accessor: 'revision',
    },
    {
      title: 'ID Cust',
      accessor: 'customer_id',
    },
    {
      title: 'Customer Name',
      accessor: 'customerName',
      customRender: (item: any) => {
        return item.customer ? item.customer.name : '-';
      },
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: IItem) => {
        return (
          <Group spacing={'xs'}>
            <ActionIcon variant='light' color='blue[4]' onClick={() => props.onQuotationSelected(item)}>
              <Check size={16} />
            </ActionIcon>
          </Group>
        );
      },
    },
  ];

  const form = useForm({
    initialValues: {
      fromDate: new Date(),
      toDate: new Date(),
    },
  });

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Group>
            <Button size='sm' leftIcon={<Search size={14} />} type='submit'>
              Search
            </Button>
          </Group>
        </div>

        <Grid>
          <Grid.Col span={6}>
            <DynamicField
              field={{
                name: 'fromDate',
                type: IFormGeneratorFieldType.Date,
                label: 'From Date',
              }}
              form={form}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DynamicField
              field={{
                name: 'toDate',
                type: IFormGeneratorFieldType.Date,
                label: 'To Date',
              }}
              form={form}
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={6}>
            <NativeSelect
              mt='sm'
              label='Search By'
              value={searchBy}
              data={[
                {
                  value: 'quotation_no',
                  label: 'Quotation No',
                },
                {
                  value: 'customer_id',
                  label: 'ID Customer',
                },
                {
                  value: 'customer_name',
                  label: 'Customer Name',
                },
              ]}
              onChange={(data) => {
                setSearchBy(data.target.value);
              }}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              mt='sm'
              type='text'
              label='Search Value'
              value={searchByValue}
              onChange={(event: any) => {
                setSearchByValue(event.target.value);
              }}
            />
          </Grid.Col>
        </Grid>
      </form>
      <Space h={24} />

      <OurTable
        isLoading={isLoading}
        page={page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={columns}
        data={searchQuotationList}
      />
    </Box>
  );
};

export default ItemForm;
