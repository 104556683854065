import { Route, Routes } from 'react-router-dom'
import RoleList from './list'

const RoleMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<RoleList />} />
      </Routes>
    </>
  )
}

export default RoleMasterLayout