import { Route, Routes } from 'react-router-dom'
import PurchaseOrderList from './list'
import CreateOrEditPurchaseOrderMasterLayout from './createOrEdit'

const PurchaseOrderMasterLayout = () => {
    return (
        <>
            <Routes>
                <Route index element={<PurchaseOrderList />}></Route>
                <Route path='/create' element={<CreateOrEditPurchaseOrderMasterLayout />}></Route>
                <Route path='/update/:id' element={<CreateOrEditPurchaseOrderMasterLayout />}></Route>
            </Routes>
        </>
    )
}

export default PurchaseOrderMasterLayout