import { AxiosResponse } from 'axios';
import _fetch from '../helpers/_fetch';
import { ILoginRequest, ILoginResponse, IUser } from '../interfaces/requests/IAuthRequest';

const url = '/auth';

const AuthApi = {
  login: (body: ILoginRequest): Promise<AxiosResponse<ILoginResponse>> => {
    return _fetch<ILoginResponse>(`${url}/login`, {
      method: 'POST',
      body,
    });
  },
  profile: (): Promise<AxiosResponse<IUser>> => {
    return _fetch<IUser>(`${url}/profile`);
  },
};

export default AuthApi;
