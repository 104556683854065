import {
  ActionIcon,
  Box,
  Button,
  Grid,
  Group,
  Modal,
  SelectItem,
  Space,
  Text,
  Tooltip,
  Checkbox,
  Input,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { object, string, number, date } from 'yup';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { DeviceFloppy, Plus, Check, Trash, Pencil } from 'tabler-icons-react';
import CurrencyApi from '../../../apis/CurrencyApi';
import CustomerApi from '../../../apis/CustomerApi';
import MarketingApi from '../../../apis/MarketingApi';
import DynamicField from '../../../components/DynamicField';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import OurTable from '../../../components/OurTable';
import ErrorHandler from '../../../helpers/_errorHandler';
import TwoDecimalPlaces from '../../../helpers/_twoDecimalPlaces';
import { IsARequiredField as requiredField } from '../../../helpers/_isARequiredField';
import { IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import InnerLayout from '../../InnerLayout';
import ItemForm from './itemForm';
import moment from 'moment';
import QuotationApi from '../../../apis/QuotationApi';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IItem } from '../../../interfaces/requests/IItemRequest';
import { useModals } from '@mantine/modals';
import { v4 as uuidV4 } from 'uuid';
import SupplierApi from '../../../apis/SupplierApi';

const QuotationForm = () => {
  const location = useLocation();

  const links = [
    {
      title: 'Dashboard',
      link: 'dashboard',
    },
    {
      title: 'Account Payable',
      link: '/account-payable',
    },
    {
      title: location.pathname.includes('/account-payable/create') ? 'Create' : 'Update',
    },
  ];

  const columns = [
    {
      title: 'Inv/Doc No',
      accessor: 'document_no',
    },
    {
      title: 'Inv Date',
      accessor: 'invoice_date',
    },
    {
      title: 'Rate',
      accessor: 'rate',
    },
    {
      title: 'Total Amount',
      accessor: 'total_amount',
    },
    {
      title: 'HM Total Amount',
      accessor: 'hm_total_amount',
    },
    {
      title: 'Total Remain',
      accessor: 'total_remain',
    },
    {
      title: 'HM Total Remain',
      accessor: 'hm_total_remain',
    },
    {
      title: 'Payment',
      accessor: 'payment',
    },
    {
      title: 'HM Payment',
      accessor: 'hm_payment',
    },
    {
      title: 'Discount',
      accessor: 'discount',
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: IItem) => {
        return (
          <Group spacing={'xs'}>
            <Tooltip label='Edit' withArrow>
              <ActionIcon variant='light' color='blue[4]' onClick={() => onEditSalesQuotationLineClicked(item)}>
                <Pencil size={16} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label='Delete' withArrow>
              <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
                <Trash size={16} />
              </ActionIcon>
            </Tooltip>
          </Group>
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<SelectItem[]>([]);
  const [currencies, setCurrencies] = useState<SelectItem[]>([]);
  const [marketings, setMarketings] = useState<SelectItem[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);

  const modals = useModals();

  const [salesQuotationLines, setSalesQuotationLines] = useState<
    {
      id: string;
      project_desc: string;
      quantity: number;
      price: number;
      discount: number;
      unit_id: number;
      unit: object;
    }[]
  >([]);

  const [showItemForm, setShowItemForm] = useState<boolean>(false);
  const [salesQuotationLineToBeEdited, setSalesQuotationLineToBeEdited] = useState<any>({});

  const navigate = useNavigate();

  const validationSchema = object().shape({
    customer_id: string().required(requiredField('Customer')).nullable(),
    down_payment: number().required(requiredField('DP')).nullable(),
    quotation_no: string().required(requiredField('Quotation No')).nullable(),
    currency_id: string().required(requiredField('Currency')).nullable(),
    date: date().required(requiredField('Date')).nullable(),
    validity: number().required(requiredField('Validity')).nullable(),
    customer_po_no: number().required(requiredField('Cust PO No')).nullable(),
    inquiry_no: number().required(requiredField('Inquiry No')).nullable(),
    payment: number().required(requiredField('Payment')).nullable(),
    marketing_id: string().required(requiredField('Marketing')).nullable(),
    delivery: number().required(requiredField('Delivery')).nullable(),
  });

  const form = useForm({
    initialValues: {
      customer_id: null,
      down_payment: 0,
      quotation_no: null,
      currency_id: null,
      marketing_id: null,
      customer_po_no: null,
      inquiry_no: null,
      date: new Date(),
      validity: null,
      payment: null,
      delivery: null,
    },
    validate: yupResolver(validationSchema),
  });

  const getSupplier = () => {
    setIsLoading(true);
    SupplierApi.getDataSource()
      .then((res) => {
        setSuppliers(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMarketing = () => {
    setIsLoading(true);
    MarketingApi.getDataSource()
      .then((res) => {
        setMarketings(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCurrency = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrencies(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleItemForm = () => {
    setShowItemForm(!showItemForm);
    if (showItemForm) {
      setSalesQuotationLineToBeEdited({});
    }
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  const params = useParams();

  useEffect(() => {
    getMarketing();
    getCurrency();
    getSupplier();

    if (params.salesQuotationId) {
      getSalesQuotationById();
    }
  }, []);

  const getSalesQuotationById = () => {
    setIsLoading(true);

    QuotationApi.getById(Number(params.salesQuotationId))
      .then((res) => {
        const {
          customer_id,
          down_payment,
          quotation_no,
          currency_id,
          date,
          validity,
          customer_po_no,
          inquiry_no,
          payment,
          marketing_id,
          delivery,
          sales_quotation_lines,
        } = res.data;

        let newSalesQuotationLines = sales_quotation_lines.map((x: any) => {
          return {
            id: x.id.toString(),
            project_desc: x.project_desc,
            quantity: x.quantity,
            price: TwoDecimalPlaces(x.price),
            discount: TwoDecimalPlaces(x.discount),
            unit_id: x.unit_id,
            unit: {
              label: x.unit?.name,
              value: x.unit?.id,
            },
          };
        });

        setSalesQuotationLines(newSalesQuotationLines);

        form.setValues({
          customer_id,
          down_payment: Number(down_payment),
          quotation_no,
          currency_id,
          date: new Date(date),
          validity,
          customer_po_no,
          inquiry_no,
          payment,
          marketing_id,
          delivery,
        });
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formOnSubmit = (values: any) => {
    setIsLoading(true);

    const {
      date,
      quotation_no,
      customer_id,
      marketing_id,
      customer_po_no,
      inquiry_no,
      validity,
      payment,
      delivery,
      currency_id,
      down_payment,
    } = values;

    let body = {
      id: params.salesQuotationId ? params.salesQuotationId : null,
      date: moment(date).format('YYYY-MM-DD'),
      quotation_no,
      customer_id,
      marketing_id,
      customer_po_no,
      inquiry_no,
      validity,
      payment,
      delivery,
      currency_id,
      down_payment,
      discount: 0,
      lines: salesQuotationLines.map((x) => {
        return {
          id: x.id,
          project_desc: x.project_desc,
          quantity: x.quantity,
          price: x.price,
          discount: x.discount,
          unit_id: x.unit_id,
        };
      }),
    };

    let apiToCall: any;

    if (params.salesQuotationId) {
      apiToCall = QuotationApi.update(body);
    } else {
      apiToCall = QuotationApi.create(body);
    }

    apiToCall
      .then(() => {
        showNotification({
          message: 'Process Successfully!',
          title: `Account Payable ${params.salesQuotationId ? 'Updated' : 'Created'}!`,
          icon: <Check />,
          color: 'green',
        });

        navigate('/quotation');
      })
      .catch((err: any) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitAccountPayableItem = (values: any) => {
    const { id, project_desc, quantity, price, discount, unit_id, unit } = values;

    let newSalesQuotationLines = [];

    if (id) {
      newSalesQuotationLines = [...salesQuotationLines];

      let index = newSalesQuotationLines.findIndex((x) => x.id === id);

      newSalesQuotationLines[index] = {
        id,
        project_desc,
        quantity,
        price,
        discount,
        unit_id,
        unit,
      };
    } else {
      newSalesQuotationLines = [
        ...salesQuotationLines,
        {
          id: uuidV4(),
          project_desc,
          quantity,
          price,
          discount,
          unit_id,
          unit,
        },
      ];
    }

    setSalesQuotationLines(newSalesQuotationLines);
    setSalesQuotationLineToBeEdited({});
    setShowItemForm(false);
  };

  const onDeletePrompt = (data: IItem) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        remove(data);
      },
    });
  };

  const remove = (item: IItem) => {
    let newSalesQuotationLines = [...salesQuotationLines].filter((x) => x.id.toString() != item.id?.toString());
    setSalesQuotationLines(newSalesQuotationLines);
  };

  const onEditSalesQuotationLineClicked = (item: any) => {
    setSalesQuotationLineToBeEdited(item);
    toggleItemForm();
  };

  return (
    <>
      <InnerLayout title='Account Payable'>
        <OurBreadcrumbs crumbs={links} />
        <ListToolbar>
          <Group>
            <Button
              disabled={isLoading}
              onClick={() => form.onSubmit(formOnSubmit)}
              size='sm'
              leftIcon={<DeviceFloppy size={16} />}
            >
              Submit
            </Button>
          </Group>
        </ListToolbar>

        <Grid>
          <Grid.Col span={3}>
            <Grid>
              <Grid.Col>
                <DynamicField
                  field={{
                    name: 'supplier_id',
                    type: IFormGeneratorFieldType.Dropdown,
                    datasource: suppliers,
                    label: 'Supplier',
                    disabled: isLoading,
                  }}
                  form={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <DynamicField
                  field={{
                    name: 'quotation_no',
                    type: IFormGeneratorFieldType.Text,
                    label: 'Paid No',
                    disabled: isLoading,
                  }}
                  form={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <DynamicField
                  field={{
                    name: 'date',
                    type: IFormGeneratorFieldType.Date,
                    label: 'Paid Date',
                    disabled: isLoading,
                  }}
                  form={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <DynamicField
                  field={{
                    name: 'quotation_no',
                    type: IFormGeneratorFieldType.Text,
                    label: 'Document No',
                    disabled: isLoading,
                  }}
                  form={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <DynamicField
                  field={{
                    name: 'customer_po_no',
                    type: IFormGeneratorFieldType.Text,
                    label: 'Description',
                    disabled: isLoading,
                  }}
                  form={form}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={9}>
            <Grid>
              <Grid.Col span={3}>
                <DynamicField
                  field={{
                    name: 'supplier_id',
                    type: IFormGeneratorFieldType.Dropdown,
                    datasource: [
                      {
                        label: 'Payment',
                        value: 'payment',
                      },
                      {
                        label: 'Return',
                        value: 'return',
                      },
                    ],
                    label: 'Type',
                    disabled: isLoading,
                  }}
                  form={form}
                />
              </Grid.Col>

              <Grid.Col span={3} style={{ paddingTop: '24px' }}>
                <span
                  style={{
                    fontWeight: 500,
                    color: '#212529',
                  }}
                >
                  Currency: IDR
                </span>
              </Grid.Col>

              <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ paddingTop: '17px' }}>** One Payment Method per Transaction</div>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={3}>
                <div
                  style={{
                    height: '34px',
                    paddingTop: '40px',
                  }}
                >
                  <Checkbox
                    style={{
                      fontWeight: 500,
                      color: '#212529',
                    }}
                    label='Cash'
                  />
                </div>
              </Grid.Col>

              <Grid.Col span={3}>
                <div
                  style={{
                    marginTop: '34px',
                  }}
                >
                  <DynamicField
                    field={{
                      name: 'down_payment',
                      type: IFormGeneratorFieldType.Number,
                      label: '',
                      disabled: isLoading,
                    }}
                    form={form}
                  />
                </div>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={3}>
                <div
                  style={{
                    marginTop: '2px',
                    height: '34px',
                    paddingTop: '40px',
                  }}
                >
                  <Checkbox
                    style={{
                      fontWeight: 500,
                      color: '#212529',
                    }}
                    label='Transfer Bank'
                  />
                </div>
              </Grid.Col>

              <Grid.Col span={3}>
                <div
                  style={{
                    marginTop: '34px',
                  }}
                >
                  <DynamicField
                    field={{
                      name: 'down_payment',
                      type: IFormGeneratorFieldType.Number,
                      label: '',
                      disabled: isLoading,
                    }}
                    form={form}
                  />
                </div>
              </Grid.Col>

              <Grid.Col span={3}>
                <div
                  style={{
                    marginTop: '34px',
                  }}
                >
                  <DynamicField
                    field={{
                      name: 'customer_id',
                      type: IFormGeneratorFieldType.Dropdown,
                      datasource: suppliers,
                      label: '',
                      disabled: isLoading,
                    }}
                    form={form}
                  />
                </div>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={3}>
                <div
                  style={{
                    marginTop: '2px',
                    height: '34px',
                    paddingTop: '40px',
                    marginBottom: '2px',
                  }}
                >
                  <Checkbox
                    style={{
                      fontWeight: 500,
                      color: '#212529',
                    }}
                    label='Giro / Check'
                  />
                </div>
              </Grid.Col>

              <Grid.Col span={3}>
                <div
                  style={{
                    marginTop: '34px',
                  }}
                >
                  <DynamicField
                    field={{
                      name: 'down_payment',
                      type: IFormGeneratorFieldType.Number,
                      label: '',
                      disabled: isLoading,
                    }}
                    form={form}
                  />
                </div>
              </Grid.Col>

              <Grid.Col span={3}>
                <div
                  style={{
                    marginTop: '34px',
                  }}
                >
                  <DynamicField
                    field={{
                      name: 'customer_id',
                      type: IFormGeneratorFieldType.Dropdown,
                      datasource: suppliers,
                      label: '',
                      disabled: isLoading,
                    }}
                    form={form}
                  />
                </div>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={3} offset={6}>
                <div
                  style={{
                    marginTop: '34px',
                  }}
                >
                  <DynamicField
                    field={{
                      name: 'down_payment',
                      type: IFormGeneratorFieldType.Number,
                      label: '',
                      disabled: isLoading,
                    }}
                    form={form}
                  />
                </div>
              </Grid.Col>

              <Grid.Col span={3} style={{ paddingTop: '30px' }}>
                <DynamicField
                  field={{
                    name: 'date',
                    type: IFormGeneratorFieldType.Date,
                    disabled: isLoading,
                    label: '',
                  }}
                  form={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={3}>
                <DynamicField
                  field={{
                    name: 'customer_po_no',
                    type: IFormGeneratorFieldType.Number,
                    label: 'Total Amount',
                    disabled: isLoading,
                  }}
                  form={form}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <DynamicField
                  field={{
                    name: 'customer_po_no',
                    type: IFormGeneratorFieldType.Number,
                    label: 'Remain',
                    disabled: isLoading,
                  }}
                  form={form}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>

        <Space h={24} />
        <ListToolbar>
          <Group>
            <Button disabled={isLoading} size='sm' leftIcon={<Plus size={16} />} onClick={toggleItemForm}>
              Add Items
            </Button>
          </Group>
        </ListToolbar>
        <Box>
          <OurTable
            isLoading={isLoading}
            page={page}
            handleChangePage={handleChangePage}
            totalPage={totalPage}
            columns={columns}
            data={salesQuotationLines}
          />
        </Box>

        <Grid>
          <Grid.Col span={3}>
            <Grid>
              <Grid.Col span={6}>Tot Remain</Grid.Col>
              <Grid.Col span={6}>
                <Input type='number' />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={3}>
            <Grid>
              <Grid.Col span={6}>Tot Payment</Grid.Col>
              <Grid.Col span={6}>
                <Input type='number' />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={3}>
            <Grid>
              <Grid.Col span={6}>Tot Discount</Grid.Col>
              <Grid.Col span={6}>
                <Input type='number' />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={3}>
            <Grid>
              <Grid.Col span={6}>Tot End Balance</Grid.Col>
              <Grid.Col span={6}>
                <Input type='number' />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={3}>
            <Grid>
              <Grid.Col span={6}>
                HM Curr : <br />
                Tot Remain
              </Grid.Col>
              <Grid.Col span={6}>
                <Input type='number' />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={3}>
            <Grid>
              <Grid.Col span={6}>Tot Payment</Grid.Col>
              <Grid.Col span={6}>
                <Input type='number' />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={3}>
            <Grid>
              <Grid.Col span={6}>Tot Discount</Grid.Col>
              <Grid.Col span={6}>
                <Input type='number' />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={3}>
            <Grid>
              <Grid.Col span={6}>Tot End Balance</Grid.Col>
              <Grid.Col span={6}>
                <Input type='number' />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>

        <Modal
          title={`${salesQuotationLineToBeEdited.id ? 'Update' : 'Add'} Account Payable Item`}
          opened={showItemForm}
          onClose={() => toggleItemForm()}
        >
          <ItemForm
            afterSubmit={(values: any) => {
              submitAccountPayableItem(values);
            }}
            id={salesQuotationLineToBeEdited.id}
            dataToBeEdited={salesQuotationLineToBeEdited}
          />
        </Modal>
      </InnerLayout>
    </>
  );
};

export default QuotationForm;
