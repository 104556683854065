import { AxiosResponse } from 'axios';
import qs from 'qs';
import _fetch from '../helpers/_fetch';
import {
  IPurchaseOrder,
  IPurchaseOrderDataTableRequest,
  IPurchaseOrderDataTableResponse,
  IPurchaseOrderCompletePO,
  IPurchaseOrderCancelPO,
} from '../interfaces/requests/IPurchaseOrderRequest';

const url = '/purchase-order';

const PurchaseOrderApi = {
  getDataTable: (
    body: IPurchaseOrderDataTableRequest = {
      page: 1,
      limit: 10,
    },
    filter: any
  ): Promise<AxiosResponse<IPurchaseOrderDataTableResponse>> => {
    let extraUrl = qs.stringify(filter);
    return _fetch<IPurchaseOrderDataTableResponse>(
      `${url}/dt?page=${body.page}&limit=${body.limit}&${extraUrl ? extraUrl : ''}`,
      {
        method: 'POST',
      }
    );
  },
  completePO: (body: IPurchaseOrderCompletePO): Promise<AxiosResponse<IPurchaseOrder>> => {
    return _fetch<IPurchaseOrder>(`${url}/${body.id}/complete`, {
      method: 'POST',
    });
  },
  cancelPO: (body: IPurchaseOrderCancelPO): Promise<AxiosResponse<IPurchaseOrder>> => {
    return _fetch<IPurchaseOrder>(`${url}/${body.id}/cancel`, {
      body: body,
      method: 'POST',
    });
  },
  create: (body: any): Promise<AxiosResponse<IPurchaseOrder>> => {
    return _fetch<IPurchaseOrder>(`${url}`, {
      method: 'POST',
      body: body,
    });
  },
  update: (body: any): Promise<AxiosResponse<IPurchaseOrder>> => {
    return _fetch<IPurchaseOrder>(`${url}`, {
      method: 'PUT',
      body: body,
    });
  },
  getById: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`);
  },
  getPOBySupplierId: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/supplier/${id}/datasource`);
  },
  getPOLines: (body: any): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${body}/lines`);
  },
};

export default PurchaseOrderApi;
