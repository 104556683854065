import { Title, Checkbox, Group, Button, TextInput } from "@mantine/core"
import { useForm } from "@mantine/form"
import { showNotification } from "@mantine/notifications"
import React, { useEffect, useState } from "react"
import { Check } from "tabler-icons-react"
import ModuleApi from "../../../apis/ModuleApi"
import RoleApi from "../../../apis/RoleApi"
import ErrorHandler from "../../../helpers/_errorHandler"
import { IRole } from "../../../interfaces/requests/IRoleRequest"

const RoleForm = (props: { afterSubmit: Function; id?: number }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [dataById, setDataById] = useState<IRole>();
  const form = useForm({
    initialValues: {
      role_name: '',
      role_code: ''
    }
  });

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    setIsLoading(true);
    if (props.id) {
      let body = data.map((x) => {
        return {
          ...x,
          role_id: props.id,
        }
      });

      ModuleApi.updateModuleAndRole(body)
        .then(() => {
          showNotification({
            message: 'Proccess Successfully!',
            title: 'Role and Module Updated!',
            icon: <Check />,
          });

          if (props.afterSubmit) {
            props.afterSubmit();
          }
        })
        .catch(err => {
          let error = ErrorHandler(err);
          showNotification({ message: error.message, title: 'Error!', color: 'red' });
        })
        .finally(() => {
          setIsSubmitting(false);
          setIsLoading(false);
        })
    } else {
      setIsSubmitting(true);
      setIsLoading(true);
      let body = data.map((x) => {
        return {
          ...x,
          ...values
        }
      });

      RoleApi.createModuleAndRole(body)
        .then(() => {
          showNotification({
            message: 'Proccess Successfully!',
            title: 'Role and Module Updated!',
            icon: <Check />,
          });

          if (props.afterSubmit) {
            props.afterSubmit();
          }
        })
        .catch(err => {
          let error = ErrorHandler(err);
          showNotification({ message: error.message, title: 'Error!', color: 'red' });
        })
        .finally(() => {
          form.setValues({ role_name: '', role_code: '' });
          setIsSubmitting(false);
          setIsLoading(false);
        })
    }
  }

  const getById = (id: number) => {
    RoleApi.getById(id)
      .then((res) => {
        setDataById(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    ModuleApi.getAll()
      .then(res => {
        let tempModule = res.data.map((value) => {
          return {
            id: value.id,
            name: value.code,
            isChecked: false,
          }
        });
        setData(tempModule);
      })
      .catch(err => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [])

  useEffect(() => {
    if (dataById && dataById.modules) {
      setIsLoading(true);
      let code = [...dataById.modules].map(x => x.code);
      let newData = [...data].map(x => {
        if (code.includes(x.name)) {
          return {
            ...x,
            isChecked: true,
          }
        }

        return x;
      })
      setData(newData);
      setIsLoading(false);
    }
  }, [dataById])

  useEffect(() => {
    if (props.id) {
      getById(props.id);
    }
  }, [props.id])

  if (isLoading) {
    return <div>Loading...</div>
  }

  const updateventChecked = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    let value = event.target.checked;
    let newData = [...data].map((x) => {
      if (x.id === id) {
        return {
          ...x,
          isChecked: value
        }
      }
      return x;
    });
    setData(newData);
  }

  const updateCode = (event: any) => {
    let code = event.target.value?.trim().replaceAll(" ", "_");
    form.setValues({ role_name: event.target.value, role_code: code });
  }

  return (
    <>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        {!props.id &&
          <div style={{ paddingBottom: 20 }}>
            <TextInput
              mt='sm'
              required
              label='Name'
              onChange={updateCode}
            />
            <TextInput
              mt='sm'
              label='Code'
              disabled
              {...form.getInputProps('role_code')}
            />
          </div>
        }

        <Title order={4}>Modules</Title>
        {data.map((field) => {
          return (
            <Checkbox
              checked={field.isChecked}
              key={field.id}
              mt="md"
              label={field.name}
              onChange={(event) => updateventChecked(event, field.id)}
            />
          )
        })}

        <Group position="right" mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </>
  )
}

export default RoleForm
