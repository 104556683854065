import { AxiosResponse } from 'axios';
import qs from 'qs';
import _fetch from '../helpers/_fetch';
import { IUser } from '../interfaces/requests/IUserRequest';
import { IPostUserRequest, IUserDataTableRequest, IUserDataTableResponse } from '../interfaces/requests/IUserRequest';

const url = '/user';

const UserApi = {
  getDataTable: (
    body: IUserDataTableRequest = {
      page: 1,
      limit: 10,
    },
    filter: any
  ): Promise<AxiosResponse<IUserDataTableResponse>> => {
    let extraUrl = qs.stringify(filter);
    return _fetch<IUserDataTableResponse>(
      `${url}/dt?page=${body.page}&limit=${body.limit}&${extraUrl ? extraUrl : ''}`,
      {
        method: 'POST',
      }
    );
  },
  create: (body: IUser): Promise<AxiosResponse<IUser>> => {
    return _fetch<IPostUserRequest>(`${url}`, {
      method: 'POST',
      body: body,
    });
  },
  getById: (id?: number): Promise<AxiosResponse<IPostUserRequest>> => {
    return _fetch<IPostUserRequest>(`${url}/${id}`);
  },
  remove: (id?: number): Promise<AxiosResponse<boolean>> => {
    return _fetch<boolean>(`${url}/${id}`, {
      method: 'DELETE',
    });
  },
};

export default UserApi;
