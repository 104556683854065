import { Box, Button, Group, SelectItem } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import UnitApi from '../../../apis/UnitApi';
import DynamicField from '../../../components/DynamicField';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';

const ItemForm = (props: {
    afterSubmit: Function;
    id?: number;
    dataToBeEdited: {
        project_desc: string;
        quantity: number;
        unit_id: number;
        price: number;
        discount: number;
    };
}) => {
    const [fields, setFields] = useState<IFormGeneratorFieldProps[]>([
        {
            name: 'project_desc',
            label: 'Invoice No',
            required: true,
            type: IFormGeneratorFieldType.Text,
        },
        {
            name: 'invoice_date',
            label: 'Cust PO',
            required: true,
            type: IFormGeneratorFieldType.Date,
        },
        {
            name: 'rate',
            label: 'Inv Date',
            required: true,
            type: IFormGeneratorFieldType.Number,
        },
        {
            name: 'total_amount',
            label: 'Rate',
            required: true,
            type: IFormGeneratorFieldType.Number,
        },
        {
            name: 'hm_total_amount',
            label: 'Total Amount',
            required: true,
            disabled: true,
            type: IFormGeneratorFieldType.Number,
        },

        {
            name: 'total_remain',
            label: 'HM Total Amount',
            required: false,
            type: IFormGeneratorFieldType.Number,
        },
        {
            name: 'hm_total_remain',
            label: 'Total Remain',
            required: false,
            disabled: true,
            type: IFormGeneratorFieldType.Number,
        },
        {
            name: 'payment',
            label: 'HM Total Remain',
            required: false,
            type: IFormGeneratorFieldType.Number,
        },
        {
            name: 'hm_payment',
            label: 'Payment',
            required: false,
            type: IFormGeneratorFieldType.Number,
        },
        {
            name: 'discount',
            label: 'HM Payment',
            required: false,
            type: IFormGeneratorFieldType.Number,
        },
    ]);

    const form = useForm<any>({
        initialValues: {
            project_desc: '',
            quantity: 0,
            price: 0,
            discount: 0,
            total: 0,
        },
    });

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [units, setUnits] = useState<SelectItem[]>([]);

    const onSubmit = (values: any) => {
        setIsSubmitting(true);
        let submittedUnit = units.find((x) => Number(x.value) === Number(values.unit_id));
        values.unit = submittedUnit;
        values.id = props.id;
        props.afterSubmit(values);
    };

    const getUnit = () => {
        setIsLoading(true);
        UnitApi.getDataSource()
            .then((res) => {
                setUnits(res.data);

                let newFields = [...fields];

                let untiFieldIndex = newFields.findIndex((x) => x.name === 'unit_id');
                if (untiFieldIndex > -1) {
                    newFields[untiFieldIndex].datasource = res.data;
                }

                setFields(newFields);
            })
            .catch((err) => {
                let error = ErrorHandler(err);
                showNotification({ message: error.message, title: 'Error!', color: 'red' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const priceOrQuantityOnChange = (value: any, fieldName: string) => {
        form.setFieldValue(fieldName, value);
        let values = form.values;

        let discount = values.discount ? values.discount : 0;

        let total = values.price * values.quantity;
        let totalAmount = values.quantity * values.price - discount;
        form.setFieldValue('amount', total);
        form.setFieldValue('total', totalAmount);
    };

    useEffect(() => {
        getUnit();

        if (props.dataToBeEdited) {
            const { project_desc, quantity, unit_id, price, discount } = props.dataToBeEdited;

            form.setValues({
                project_desc,
                quantity,
                unit_id,
                price,
                discount,
                amount: quantity * price,
                total: quantity * price - discount,
            });
        }
    }, []);

    return (
        <Box>
            <form onSubmit={form.onSubmit(onSubmit)}>
                {fields.map((field, index) => (
                    <DynamicField
                        field={field}
                        form={form}
                        key={index}
                        onChange={
                            field.name === 'quantity' || field.name === 'price' || field.name === 'discount'
                                ? (e: any) => priceOrQuantityOnChange(e, field.name)
                                : null
                        }
                    />
                ))}
                <Group position='right' mt='md'>
                    <Button type='submit' loading={isSubmitting}>
                        Submit
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

export default ItemForm;
