import { SelectItem } from '@mantine/core';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import _fetch from '../helpers/_fetch';
import { IRole, IRoleDataTableRequest, IRoleDataTableResponse } from '../interfaces/requests/IRoleRequest';

const url = '/role';

const RoleApi = {
  getDataTable: (
    body: IRoleDataTableRequest = {
      page: 1,
      limit: 10,
    },
    filter: any
  ): Promise<AxiosResponse<IRoleDataTableResponse>> => {
    let extraUrl = qs.stringify(filter);
    return _fetch<IRoleDataTableResponse>(
      `${url}/dt?page=${body.page}&limit=${body.limit}&${extraUrl ? extraUrl : ''}`,
      {
        method: 'POST',
      }
    );
  },
  getById: (id?: number): Promise<AxiosResponse<IRole>> => {
    return _fetch<IRole>(`${url}/${id}`);
  },
  createModuleAndRole: (body: any): Promise<AxiosResponse<boolean>> => {
    return _fetch<boolean>(`${url}/create-module-role`, {
      method: 'POST',
      body: body,
    });
  },
  getRoleDropdown: (): Promise<AxiosResponse<SelectItem[]>> => {
    return _fetch<SelectItem[]>(`${url}/datasource`, {
      method: 'POST',
    });
  },
};

export default RoleApi;
