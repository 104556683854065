import { Box, Group, Button, SelectItem } from '@mantine/core';
import { useEffect, useState } from 'react';
import FormGenerator from '../../../components/FormGenerator';
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import UnitApi from '../../../apis/UnitApi';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import DynamicField from '../../../components/DynamicField';
import { useForm, yupResolver } from '@mantine/form';
import { object, string, number } from 'yup';
import { IsARequiredField as requiredField } from '../../../helpers/_isARequiredField';

const ItemForm = (props: {
  afterSubmit: Function;
  id?: number;
  dataToBeEdited?: {
    project_detail_description: string;
    quantity: number;
    unit_id: number;
  };
}) => {
  const [units, setUnits] = useState<SelectItem[]>([]);

  useEffect(() => {
    getUnitList();

    if (props.dataToBeEdited) {
      const { project_detail_description, quantity, unit_id } = props.dataToBeEdited;

      form.setValues({
        project_detail_description,
        quantity: quantity ?? 0,
        unit_id,
      });
    }
  }, []);

  const [fields, setFields] = useState<IFormGeneratorFieldProps[]>([
    {
      name: 'project_detail_description',
      label: 'Project Detail Desc',
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'quantity',
      label: 'Qty',
      type: IFormGeneratorFieldType.Number,
    },
    {
      name: 'unit_id',
      label: 'Unit',
      type: IFormGeneratorFieldType.Dropdown,
      datasource: [],
      placeholder: 'Please select a unit',
    },
  ]);

  const validationSchema = object().shape({
    project_detail_description: string().required(requiredField('Project Title')).nullable(),
    quantity: number().required(requiredField('Number')).nullable(),
    unit_id: string().required(requiredField('Unit')).nullable(),
  });

  const form = useForm<any>({
    initialValues: {
      project_detail_description: '',
      quantity: 0,
      unit_id: null,
    },
    validate: yupResolver(validationSchema),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUnitList = () => {
    UnitApi.getDataSource()
      .then((res) => {
        setUnits(res.data);
        let newFields = [...fields];

        let unitField = newFields.findIndex((field) => field.name === 'unit_id');

        if (unitField > -1) {
          newFields[unitField].datasource = res.data;
          setFields(newFields);
        }
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    let submittedUnit = units.find((x) => Number(x.value) === Number(values.unit_id));
    values.id = props.id;
    values.unit = submittedUnit;
    props.afterSubmit(values);
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <DynamicField field={field} form={form} key={index} />
        ))}
        <Group position='right' mt='md'>
          <Button type='submit' loading={isSubmitting}>
            Submit
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default ItemForm;
