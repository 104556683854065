import React, { forwardRef } from 'react';
import { ChevronRight } from 'tabler-icons-react';
import { UnstyledButton, Group, Avatar, Text } from '@mantine/core';

interface UserAvatarProps {
  avatar: string;
  name: string;
  email: string;
}

const UserAvatar = forwardRef<HTMLButtonElement, UserAvatarProps>(
  ({ avatar, name, email, ...others }: UserAvatarProps, ref) => (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.md,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      })}
      {...others}
    >
      <Group>
        <Avatar src={avatar} radius='xl' />

        <div style={{ flex: 1 }}>
          <Text size='sm' weight={500}>
            {name}
          </Text>

          <Text color='dimmed' size='xs'>
            {email}
          </Text>
        </div>

        <ChevronRight size={16} />
      </Group>
    </UnstyledButton>
  )
);

export default UserAvatar;
