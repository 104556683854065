import { SelectItem } from '@mantine/core';
import { AxiosResponse } from 'axios';
import _fetch from '../helpers/_fetch';

const url = '/module';

const ModuleApi = {
  getAll: (): Promise<AxiosResponse<any[]>> => {
    return _fetch<any[]>(`${url}/get-all`);
  },
  getDataSource: (): Promise<AxiosResponse<SelectItem[]>> => {
    return _fetch<SelectItem[]>(`${url}/datasource`, {
      method: 'POST',
    });
  },
  updateModuleAndRole: (data: any): Promise<AxiosResponse<boolean>> => {
    return _fetch<boolean>(`${url}/update-module-role`, {
      method: 'POST',
      body: data,
    });
  },
};

export default ModuleApi;
