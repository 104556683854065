import { Box } from '@mantine/core';
import { useEffect, useState } from 'react';
import FormGenerator from '../../../components/FormGenerator';
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';

const EditItemForm = (props: { afterSubmit: Function; data: any }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({
    id: null,
    quantity: 0,
    price: 0,
  });

  const fields: IFormGeneratorFieldProps[] = [
    {
      name: 'quantity',
      label: 'Quantity',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'price',
      label: 'Price',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
  ];

  const onSubmit = (values: any) => {
    if (props.afterSubmit) {
      props.afterSubmit(values);
    }
  };

  useEffect(() => {
    if (props.data) {
      setInitialValues(props.data);
    }
  }, []);

  return (
    <Box>
      <FormGenerator fields={fields} initialValues={initialValues} onSubmit={onSubmit} loading={isSubmitting} />
    </Box>
  );
};

export default EditItemForm;
