import { SelectItem } from '@mantine/core';
import { AxiosResponse } from 'axios';
import _fetch from '../helpers/_fetch';
import {
  ISalesQuotationDataTableRequest,
  ISalesQuotationDataTableResponse,
  IPostSalesQuotationRequest,
} from '../interfaces/requests/ISalesQuotation';

const url = '/quotation';

const QuotationApi = {
  getDataTable: (
    body: ISalesQuotationDataTableRequest = {
      page: 1,
      limit: 10,
    }
  ): Promise<AxiosResponse<ISalesQuotationDataTableResponse>> => {
    return _fetch<ISalesQuotationDataTableResponse>(`${url}/dt`, {
      method: 'POST',
      body,
    });
  },
  create: (body: any): Promise<AxiosResponse<IPostSalesQuotationRequest>> => {
    return _fetch<IPostSalesQuotationRequest>(`${url}`, {
      method: 'POST',
      body,
    });
  },
  update: (body: any): Promise<AxiosResponse<IPostSalesQuotationRequest>> => {
    return _fetch<IPostSalesQuotationRequest>(`${url}`, {
      method: 'PUT',
      body,
    });
  },
  remove: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`, {
      method: 'delete',
    });
  },
  getById: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`);
  },
  getDataSource: (): Promise<AxiosResponse<SelectItem[]>> => {
    return _fetch<SelectItem[]>(`${url}/datasource`, {
      method: 'POST',
    });
  },
  search: (body: any): Promise<AxiosResponse<any>> => {
    return _fetch<IPostSalesQuotationRequest>(`${url}/search`, {
      method: 'POST',
      body,
    });
  },
};

export default QuotationApi;
