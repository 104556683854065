import { Anchor, Breadcrumbs } from '@mantine/core';

interface IBreadCrumbsProps {
  crumbs: ICrumbs[];
}

interface ICrumbs {
  title: string;
  link?: string;
}

const OurBreadcrumbs = (props: IBreadCrumbsProps) => {
  return (
    <Breadcrumbs>
      {props.crumbs.map((item, index) => {
        return (
          <Anchor href={item.link} key={index}>
            {item.title}
          </Anchor>
        );
      })}
    </Breadcrumbs>
  );
};

export default OurBreadcrumbs;
