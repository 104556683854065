import { ActionIcon, Box, Button, Grid, Group, Modal, SelectItem, Space, Text, Tooltip } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { object, string, number, date } from 'yup';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { DeviceFloppy, Plus, Check, Trash, Pencil } from 'tabler-icons-react';
import CurrencyApi from '../../../apis/CurrencyApi';
import CustomerApi from '../../../apis/CustomerApi';
import MarketingApi from '../../../apis/MarketingApi';
import DynamicField from '../../../components/DynamicField';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import OurTable from '../../../components/OurTable';
import ErrorHandler from '../../../helpers/_errorHandler';
import TwoDecimalPlaces from '../../../helpers/_twoDecimalPlaces';
import { IsARequiredField as requiredField } from '../../../helpers/_isARequiredField';
import { IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import InnerLayout from '../../InnerLayout';
import ItemForm from './itemForm';
import moment from 'moment';
import QuotationApi from '../../../apis/QuotationApi';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IItem } from '../../../interfaces/requests/IItemRequest';
import { useModals } from '@mantine/modals';
import { v4 as uuidV4 } from 'uuid';

const QuotationForm = () => {
  const location = useLocation();

  const links = [
    {
      title: 'Dashboard',
      link: 'dashboard',
    },
    {
      title: 'Quotation',
      link: '/quotation',
    },
    {
      title: location.pathname.includes('/quotation/create') ? 'Create' : 'Update',
    },
  ];

  const columns = [
    {
      title: 'Project',
      accessor: 'project_desc',
    },
    {
      title: 'Qty',
      accessor: 'quantity',
    },
    {
      title: 'Unit',
      accessor: 'unit',
      customRender: (item: any) => {
        return item.unit?.label;
      },
    },
    {
      title: '@Price',
      accessor: 'price',
    },
    {
      title: 'Amount',
      accessor: 'amount',
      customRender: (item: any) => {
        return item.amount ? item.amount : item.quantity * item.price;
      },
    },
    {
      title: 'Discount',
      accessor: 'discount',
    },
    {
      title: 'Total Amount',
      accessor: 'total',
      customRender: (item: any) => {
        return item.total ? item.total : item.quantity * item.price - item.discount;
      },
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: IItem) => {
        return (
          <Group spacing={'xs'}>
            <Tooltip label='Edit' withArrow>
              <ActionIcon variant='light' color='blue[4]' onClick={() => onEditSalesQuotationLineClicked(item)}>
                <Pencil size={16} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label='Delete' withArrow>
              <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
                <Trash size={16} />
              </ActionIcon>
            </Tooltip>
          </Group>
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<SelectItem[]>([]);
  const [currencies, setCurrencies] = useState<SelectItem[]>([]);
  const [marketings, setMarketings] = useState<SelectItem[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);

  const modals = useModals();

  const [salesQuotationLines, setSalesQuotationLines] = useState<
    {
      id: string;
      project_desc: string;
      quantity: number;
      price: number;
      discount: number;
      unit_id: number;
      unit: object;
      amount?: number;
      total?: number;
    }[]
  >([]);

  const [showItemForm, setShowItemForm] = useState<boolean>(false);
  const [salesQuotationLineToBeEdited, setSalesQuotationLineToBeEdited] = useState<any>({});

  const navigate = useNavigate();

  const validationSchema = object().shape({
    customer_id: string().required(requiredField('Customer')).nullable(),
    down_payment: number().required(requiredField('DP')).nullable(),
    quotation_no: string().required(requiredField('Quotation No')).nullable(),
    currency_id: string().required(requiredField('Currency')).nullable(),
    date: date().required(requiredField('Date')).nullable(),
    validity: number().required(requiredField('Validity')).nullable(),
    customer_po_no: string().nullable(),
    inquiry_no: string().nullable(),
    payment: number().required(requiredField('Payment')).nullable(),
    marketing_id: string().required(requiredField('Marketing')).nullable(),
    delivery: number().required(requiredField('Delivery')).nullable(),
    revision: number().required(requiredField('Revision')).nullable(),
  });

  const form = useForm({
    initialValues: {
      customer_id: '',
      down_payment: 0,
      quotation_no: '',
      currency_id: '',
      marketing_id: '',
      customer_po_no: '',
      inquiry_no: '',
      date: new Date(),
      validity: 0,
      payment: 0,
      delivery: 0,
      revision: 0,
    },
    validate: yupResolver(validationSchema),
  });

  const getCustomer = () => {
    setIsLoading(true);
    CustomerApi.getDataSource()
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMarketing = () => {
    setIsLoading(true);
    MarketingApi.getDataSource()
      .then((res) => {
        setMarketings(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCurrency = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrencies(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleItemForm = () => {
    setShowItemForm(!showItemForm);
    if (showItemForm) {
      setSalesQuotationLineToBeEdited({});
    }
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  const params = useParams();

  useEffect(() => {
    getMarketing();
    getCurrency();
    getCustomer();

    if (params.salesQuotationId) {
      getSalesQuotationById();
    }
  }, []);

  const getSalesQuotationById = () => {
    setIsLoading(true);

    QuotationApi.getById(Number(params.salesQuotationId))
      .then((res) => {
        const {
          customer_id,
          down_payment,
          quotation_no,
          currency_id,
          date,
          validity,
          customer_po_no,
          inquiry_no,
          payment,
          marketing_id,
          delivery,
          sales_quotation_lines,
          revision,
        } = res.data;

        let newSalesQuotationLines = sales_quotation_lines.map((x: any) => {
          return {
            id: x.id.toString(),
            project_desc: x.project_desc,
            quantity: x.quantity,
            price: x.price,
            discount: x.discount,
            amount: TwoDecimalPlaces(x.amount),
            total: TwoDecimalPlaces(x.total),
            unit_id: x.unit_id,
            unit: {
              label: x.unit?.name,
              value: x.unit?.id,
            },
          };
        });

        setSalesQuotationLines(newSalesQuotationLines);

        form.setValues({
          customer_id,
          down_payment: Number(down_payment),
          quotation_no,
          currency_id: String(currency_id),
          date: new Date(date),
          validity,
          customer_po_no,
          inquiry_no,
          payment,
          marketing_id,
          delivery,
          revision,
        });
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formOnSubmit = (values: any) => {
    setIsLoading(true);

    const {
      date,
      quotation_no,
      customer_id,
      marketing_id,
      customer_po_no,
      inquiry_no,
      validity,
      payment,
      delivery,
      currency_id,
      down_payment,
      revision,
    } = values;

    let body = {
      id: params.salesQuotationId ? params.salesQuotationId : null,
      date: moment(date).format('YYYY-MM-DD'),
      quotation_no,
      customer_id,
      marketing_id,
      customer_po_no,
      inquiry_no,
      validity,
      payment,
      delivery,
      currency_id,
      down_payment,
      discount: 0,
      lines: salesQuotationLines.map((x) => {
        return {
          id: x.id,
          project_desc: x.project_desc,
          quantity: x.quantity,
          price: x.price,
          discount: x.discount,
          unit_id: x.unit_id,
        };
      }),
      revision,
    };

    let apiToCall: any;

    if (params.salesQuotationId) {
      apiToCall = QuotationApi.update(body);
    } else {
      apiToCall = QuotationApi.create(body);
    }

    apiToCall
      .then(() => {
        showNotification({
          message: 'Process Successfully!',
          title: `Sales Quotation ${params.salesQuotationId ? 'Updated' : 'Created'}!`,
          icon: <Check />,
          color: 'green',
        });

        navigate('/quotation');
      })
      .catch((err: any) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitSalesQuotationItem = (values: any) => {
    const { id, project_desc, quantity, price, discount, unit_id, unit } = values;

    let newSalesQuotationLines = [];

    if (id) {
      newSalesQuotationLines = [...salesQuotationLines];

      let index = newSalesQuotationLines.findIndex((x) => x.id === id);

      newSalesQuotationLines[index] = {
        id,
        project_desc,
        quantity,
        price,
        discount,
        unit_id,
        unit,
      };
    } else {
      newSalesQuotationLines = [
        ...salesQuotationLines,
        {
          id: uuidV4(),
          project_desc,
          quantity,
          price,
          discount,
          unit_id,
          unit,
        },
      ];
    }

    setSalesQuotationLines(newSalesQuotationLines);
    setSalesQuotationLineToBeEdited({});
    setShowItemForm(false);
  };

  const onDeletePrompt = (data: IItem) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        remove(data);
      },
    });
  };

  const remove = (item: IItem) => {
    let newSalesQuotationLines = [...salesQuotationLines].filter((x) => x.id.toString() != item.id?.toString());
    setSalesQuotationLines(newSalesQuotationLines);
  };

  const onEditSalesQuotationLineClicked = (item: any) => {
    setSalesQuotationLineToBeEdited(item);
    toggleItemForm();
  };

  return (
    <>
      <InnerLayout title='Sales Quotation'>
        <form>
          <OurBreadcrumbs crumbs={links} />
          <ListToolbar>
            <Group>
              <Button
                disabled={!form.isValid()}
                onClick={() => formOnSubmit(form.values)}
                size='sm'
                leftIcon={<DeviceFloppy size={16} />}
              >
                Submit
              </Button>
            </Group>
          </ListToolbar>

          <Grid>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'customer_id',
                  type: IFormGeneratorFieldType.Dropdown,
                  datasource: customers,
                  label: 'Customer',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'down_payment',
                  type: IFormGeneratorFieldType.Number,
                  label: 'DP',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'quotation_no',
                  type: IFormGeneratorFieldType.Text,
                  label: 'Quotation No.',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'currency_id',
                  type: IFormGeneratorFieldType.Dropdown,
                  datasource: currencies,
                  label: 'Currency',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'date',
                  type: IFormGeneratorFieldType.Date,
                  label: 'Date',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'validity',
                  type: IFormGeneratorFieldType.Number,
                  label: 'Validity',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'customer_po_no',
                  type: IFormGeneratorFieldType.Text,
                  label: 'Cust PO No',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'inquiry_no',
                  type: IFormGeneratorFieldType.Text,
                  label: 'Inquiry No.',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'payment',
                  type: IFormGeneratorFieldType.Number,
                  label: 'Payment',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'marketing_id',
                  type: IFormGeneratorFieldType.Dropdown,
                  datasource: marketings,
                  label: 'Marketing',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'delivery',
                  type: IFormGeneratorFieldType.Number,
                  label: 'Delivery',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'revision',
                  type: IFormGeneratorFieldType.Number,
                  label: 'Revision',
                  disabled: isLoading,
                }}
                form={form}
              />
            </Grid.Col>
          </Grid>

          <Space h={24} />
          <ListToolbar>
            <Group>
              <Button disabled={isLoading} size='sm' leftIcon={<Plus size={16} />} onClick={toggleItemForm}>
                Add Items
              </Button>
            </Group>
          </ListToolbar>
          <Box>
            <OurTable
              isLoading={isLoading}
              page={page}
              handleChangePage={handleChangePage}
              totalPage={totalPage}
              columns={columns}
              data={salesQuotationLines}
            />
          </Box>
          <Modal
            title={`${salesQuotationLineToBeEdited.id ? 'Update' : 'Add'} Sales Quotation Item`}
            opened={showItemForm}
            onClose={() => toggleItemForm()}
          >
            <ItemForm
              afterSubmit={(values: any) => {
                submitSalesQuotationItem(values);
              }}
              id={salesQuotationLineToBeEdited.id}
              dataToBeEdited={salesQuotationLineToBeEdited}
            />
          </Modal>
        </form>
      </InnerLayout>
    </>
  );
};

export default QuotationForm;
