import { Header, ScrollArea } from '@mantine/core';

interface InnerLayoutProps {
  children?: React.ReactNode;
  title: string;
}

const InnerLayout = ({ children, title }: InnerLayoutProps) => {
  return (
    <>
      <header
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '8px',
          height: '60px',
        }}
      >
        <span
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {title}
        </span>
      </header>
      <div
        style={{
          padding: '20px',
        }}
      >
        {children}
      </div>
    </>
  );
};

export default InnerLayout;
