import {
  ActionIcon,
  Box,
  Button,
  Grid,
  Group,
  Modal,
  SelectItem,
  Space,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { DeviceFloppy, Plus, Search, Check, Pencil, Trash } from 'tabler-icons-react';
import CustomerApi from '../../../apis/CustomerApi';
import QuotationApi from '../../../apis/QuotationApi';
import DynamicField from '../../../components/DynamicField';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import OurTable from '../../../components/OurTable';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import InnerLayout from '../../InnerLayout';
import ItemForm from './itemForm';
import SearchQuotationModal from './searchQuotationModal';
import { object, string, date } from 'yup';
import { IsARequiredField as requiredField } from '../../../helpers/_isARequiredField';
import moment from 'moment';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import CustomerOrderApi from '../../../apis/CustomerOrderApi';
import { IItem } from '../../../interfaces/requests/IItemRequest';
import { useModals } from '@mantine/modals';
import { v4 as uuidV4 } from 'uuid';
import { ISalesQuotation } from '../../../interfaces/requests/ISalesQuotation';
import { ICustomerOrderLineVM } from '../../../interfaces/view-models/ICustomerOrderLineVM';

const QuotationForm = () => {
  const location = useLocation();
  const modals = useModals();

  const links = [
    {
      title: 'Dashboard',
      link: 'dashboard',
    },
    {
      title: 'Customer Order',
      link: '/customer-order',
    },
    {
      title: location.pathname.includes('/customer-order/create') ? 'Create' : 'Update',
    },
  ];

  const columns = [
    {
      title: 'Project Detail Description',
      accessor: 'project_detail_description',
    },
    {
      title: 'Qty',
      accessor: 'quantity',
    },
    {
      title: 'Unit',
      accessor: 'unit',
      customRender: (item: any) => {
        return item.unit?.label ?? item.unit?.name;
      },
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: IItem) => {
        return (
          <Group spacing={'xs'}>
            <Tooltip label='Edit' withArrow>
              <ActionIcon variant='light' color='blue[4]' onClick={() => onEditCustomerOrderLineClicked(item)}>
                <Pencil size={16} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label='Delete' withArrow>
              <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
                <Trash size={16} />
              </ActionIcon>
            </Tooltip>
          </Group>
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<SelectItem[]>([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);

  const [showItemForm, setShowItemForm] = useState<boolean>(false);
  const [showSearchQuotationModal, setShowSearchQuotationModal] = useState<boolean>(false);
  const [quotation, setQuotation] = useState<ISalesQuotation | null>(null);

  const [customerOrderLines, setCustomerOrderLines] = useState<ICustomerOrderLineVM[]>([]);

  const [customerOrderLineToBeEdited, setCustomerOrderLineToBeEdited] = useState<any>({});

  const params = useParams();
  const navigate = useNavigate();

  const validationSchema = object().shape({
    customer_id: string().required(requiredField('Customer')).nullable(),
    quotation_id: string().nullable(),
    date: date().required(requiredField('Date')).nullable(),
    project_no: string().required(requiredField('Project No')).nullable(),
    project_title: string().required(requiredField('Project Title')).nullable(),
  });

  const form = useForm({
    initialValues: {
      customer_id: null,
      quotation_id: null,
      project_title: '',
      date: new Date(),
      project_no: '',
    },
    validate: yupResolver(validationSchema),
  });

  const getCustomer = () => {
    setIsLoading(true);
    CustomerApi.getDataSource()
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  const toggleItemForm = () => {
    setShowItemForm(!showItemForm);
    if (showItemForm) {
      setCustomerOrderLineToBeEdited({});
    }
  };

  const toggleSearchQuotationModal = () => {
    setShowSearchQuotationModal(!showSearchQuotationModal);
  };

  useEffect(() => {
    getCustomer();

    if (params.customerOrderId) {
      getCustomerOrderById();
    }
  }, []);

  useEffect(() => {
    mapCustomerOrderLines();
  }, [quotation]);

  const getCustomerOrderById = () => {
    setIsLoading(true);
    CustomerOrderApi.getById(Number(params.customerOrderId))
      .then((res) => {
        const { customer_id, quotation_id, date, project_title, project_no, lines } = res.data;

        let newCustomerOrderLines = lines.map((x: any) => {
          return {
            id: x.id.toString(),
            project_detail_description: x.project_name,
            quantity: x.quantity,
            unit_id: x.unit_id,
            unit: {
              label: x.unit?.name,
              value: x.unit?.id,
            },
          };
        });

        setCustomerOrderLines(newCustomerOrderLines);

        form.setValues({
          customer_id,
          quotation_id,
          date: new Date(date),
          project_title,
          project_no,
        });
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onQuotationSelected = (quotation: ISalesQuotation) => {
    // setQuotationIdSelected(quotationId);
    toggleSearchQuotationModal();
    // form.setFieldValue('quotation_id', quotationId);
    setQuotation(quotation);
  };

  const submitCustomerOrder = (values: any) => {
    console.log(values);
    const { customer_id, project_no, date, project_title } = values;

    let body = {
      id: params.customerOrderId ? params.customerOrderId : null,
      customer_id: Number(customer_id),
      project_no,
      date: moment(date).format('YYYY-MM-DD'),
      project_title,
      quotation_id: quotation?.id,
      lines: customerOrderLines.map((x) => {
        return {
          project_name: x.project_detail_description,
          quantity: Number(x.quantity),
          unit_id: Number(x.unit_id),
        };
      }),
    };

    let apiToCall: any;

    if (params.customerOrderId) {
      apiToCall = CustomerOrderApi.update(body);
    } else {
      apiToCall = CustomerOrderApi.create(body);
    }

    apiToCall.then(() => {
      showNotification({
        message: 'Process Successfully!',
        title: `Customer Order ${params.customerOrderId ? 'Updated' : 'Created'}!`,
        icon: <Check />,
        color: 'green',
      });

      navigate('/customer-order');
    });
  };

  const submitCustomerOrderItem = (values: any) => {
    const { id, project_detail_description, quantity, unit_id, unit } = values;

    let newCustomerOrderLines = [];

    if (id) {
      newCustomerOrderLines = [...customerOrderLines];

      let index = newCustomerOrderLines.findIndex((x) => x.id === id);

      newCustomerOrderLines[index] = {
        id,
        project_detail_description,
        quantity,
        unit_id,
        unit,
      };
    } else {
      newCustomerOrderLines = [
        ...customerOrderLines,
        {
          id: uuidV4(),
          project_detail_description,
          quantity,
          unit_id,
          unit,
        },
      ];
    }

    setCustomerOrderLines(newCustomerOrderLines);
    setCustomerOrderLineToBeEdited({});
    setShowItemForm(false);
  };

  const onDeletePrompt = (data: IItem) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        remove(data);
      },
    });
  };

  const remove = (item: IItem) => {
    let newCustomerOrderLines = [...customerOrderLines].filter((x) => x.id.toString() != item.id?.toString());
    setCustomerOrderLines(newCustomerOrderLines);
  };

  const onEditCustomerOrderLineClicked = (item: any) => {
    setCustomerOrderLineToBeEdited(item);
    toggleItemForm();
  };

  const mapCustomerOrderLines = () => {
    let lines: ICustomerOrderLineVM[] = [];
    let newLines = [...customerOrderLines];
    if (quotation) {
      if (quotation.sales_quotation_lines?.length) {
        quotation.sales_quotation_lines.map((x) => {
          let index = newLines.findIndex((y) => y.project_detail_description === x.project_desc);

          if (index === -1) {
            lines.push({
              id: uuidV4(),
              project_detail_description: x.project_desc,
              quantity: 1,
              unit_id: x.unit_id,
              unit: x.unit,
            });
          }
        });
      }
    }

    lines.forEach((x) => {
      let index = newLines.findIndex((y) => y.id === x.id);
      if (index === -1) {
        newLines.push(x);
      }
    });

    setCustomerOrderLines(newLines);
  };

  return (
    <>
      <InnerLayout title='Customer Order'>
        <OurBreadcrumbs crumbs={links} />
        <form onSubmit={form.onSubmit(submitCustomerOrder)}>
          <ListToolbar>
            <Group>
              <Button size='sm' type='submit' leftIcon={<DeviceFloppy size={16} />}>
                Submit
              </Button>
            </Group>
          </ListToolbar>

          <Grid>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'customer_id',
                  type: IFormGeneratorFieldType.Dropdown,
                  datasource: customers,
                  label: 'Customer',
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'project_no',
                  type: IFormGeneratorFieldType.Text,
                  label: 'Project No.',
                }}
                form={form}
              />
            </Grid.Col>

            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'date',
                  type: IFormGeneratorFieldType.Date,
                  label: 'Date',
                }}
                form={form}
              />
            </Grid.Col>

            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'project_title',
                  type: IFormGeneratorFieldType.Text,
                  label: 'Project Title',
                }}
                form={form}
              />
            </Grid.Col>
          </Grid>
        </form>
        <div className='mt-4 flex items-end gap-2'>
          <TextInput disabled label='Selected Quotation' value={quotation?.quotation_no} />
          <Button size='sm' leftIcon={<Search size={14} />} onClick={toggleSearchQuotationModal}>
            Search Quotation
          </Button>
        </div>

        <Space h={24} />
        <ListToolbar>
          <Group>
            <Button size='sm' leftIcon={<Plus size={16} />} onClick={toggleItemForm}>
              Add Customer Order Item
            </Button>
          </Group>
        </ListToolbar>
        <Box>
          <OurTable
            isLoading={isLoading}
            page={page}
            handleChangePage={handleChangePage}
            totalPage={totalPage}
            columns={columns}
            data={customerOrderLines}
          />
        </Box>
        <Modal
          title={`${customerOrderLineToBeEdited.id ? 'Update' : 'Add'} Customer Order Item`}
          opened={showItemForm}
          onClose={toggleItemForm}
          closeOnClickOutside={false}
        >
          <ItemForm
            afterSubmit={(values: any) => {
              submitCustomerOrderItem(values);
            }}
            id={customerOrderLineToBeEdited.id}
            dataToBeEdited={customerOrderLineToBeEdited}
          />
        </Modal>

        <Modal
          title='Search Quotation'
          size='xl'
          opened={showSearchQuotationModal}
          closeOnClickOutside={false}
          onClose={toggleSearchQuotationModal}
        >
          <SearchQuotationModal onQuotationSelected={onQuotationSelected} />
        </Modal>
      </InnerLayout>
    </>
  );
};

export default QuotationForm;
