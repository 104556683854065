import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';
import { Check } from 'tabler-icons-react';
import CurrencyApi from '../../../apis/CurrencyApi';
import FormGenerator from '../../../components/FormGenerator';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';

const CurrencyForm = (props: { afterSubmit: Function }) => {
  const fields: IFormGeneratorFieldProps[] = [
    {
      name: 'prefix',
      label: 'Prefix',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'sign',
      label: 'Sign',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'operator',
      label: 'Operator',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'description',
      label: 'Desc',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'rate',
      label: 'Rate',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'active',
      label: 'Is home currency?',
      type: IFormGeneratorFieldType.Checkbox,
      required: true,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    prefix: '',
    sign: '',
    description: '',
    rate: '',
    operator: '',
    active: false,
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    CurrencyApi.create(values)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Currency Created!',
          icon: <Check />,
        });

        if (props.afterSubmit) {
          props.afterSubmit();
        }
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Box>
      <FormGenerator fields={fields} initialValues={initialValues} onSubmit={onSubmit} loading={isSubmitting} />
    </Box>
  );
};

export default CurrencyForm;
