import { Route, Routes } from 'react-router-dom';
import SalesReturnList from './list';
import SalesReturnCreateForm from './create';
import SalesReturnEditForm from './edit';

const SalesReturnMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<SalesReturnList />}></Route>
        <Route path='/create' element={<SalesReturnCreateForm />}></Route>
        <Route path='/update/:id' element={<SalesReturnEditForm />}></Route>
      </Routes>
    </>
  );
};

export default SalesReturnMasterLayout;
