import { ActionIcon, Button, Group, SelectItem, Text, Tooltip } from '@mantine/core';
import { Pencil, Printer, Trash } from 'tabler-icons-react';
import { Plus } from 'tabler-icons-react';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import InnerLayout from '../../InnerLayout';
import OurTable, { IOurColumnFilterType, IOurTableColumnProps } from '../../../components/OurTable';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../helpers/_errorHandler';
import TwoDecimalPlaces from '../../../helpers/_twoDecimalPlaces';
import { showNotification } from '@mantine/notifications';
import QuotationApi from '../../../apis/QuotationApi';
import { ISupplier } from '../../../interfaces/requests/ISupplierRequest';
import { useModals } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CurrencyApi from '../../../apis/CurrencyApi';
import EnumerationApi from '../../../apis/EnumerationApi';
import CustomerApi from '../../../apis/CustomerApi';
import MarketingApi from '../../../apis/MarketingApi';
import { ISalesQuotation } from '../../../interfaces/requests/ISalesQuotation';

const links = [
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
];

const QuotationList = () => {
  const [data, setData] = useState<ISalesQuotation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>(10);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [datatableParams, setDatatableParams] = useState<any>({
    page: 1,
  });

  const [currencies, setCurrencies] = useState<SelectItem[]>([]);
  const [salesQuotationStatuses, setSalesQuotationStatuses] = useState<SelectItem[]>([]);
  const [customers, setCustomers] = useState<SelectItem[]>([]);
  const [marketings, setMarketings] = useState<SelectItem[]>([]);

  const navigate = useNavigate();

  const modals = useModals();

  const getList = () => {
    const { page, ...rest } = datatableParams;
    setIsLoading(true);
    QuotationApi.getDataTable({
      limit,
      page,
      ...rest,
    })
      .then((res) => {
        setTotalPage(res.data.last_page);
        setData(res.data.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  useEffect(() => {
    if (!data.length) {
      getList();
    }

    getCurrencies();
    getSalesQuotationStatuses();
    getCustomers();
    getMarketings();
  }, []);

  useEffect(() => {
    getList();
  }, [datatableParams]);

  const toCreate = () => {
    navigate('create');
  };

  const toEdit = (salesQuotationId: number) => {
    navigate(`/quotation/update/${salesQuotationId}`);
  };

  const print = (salesQuotationId: number) => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/api/quotation/print/${salesQuotationId}`, '_blank');
  };

  const remove = (item: ISupplier) => {
    setIsLoading(true);
    QuotationApi.remove(item.id)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Sales Quotation Removed!',
          icon: <Trash />,
        });
        getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDeletePrompt = (data: any) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(data),
    });
  };

  const handleChangePage = (value: any) => {
    setDatatableParams(value);
  };

  const columns: IOurTableColumnProps[] = [
    {
      title: 'Status',
      accessor: 'status_in_string',
      sortable: true,
      sort_key: 'status',
      filter: {
        name: 'status',
        type: IOurColumnFilterType.Dropdown,
        data: [],
      },
    },
    {
      title: 'Date',
      accessor: 'date',
      customRender: (item: any) => {
        return moment(item.date).format('DD MMMM YYYY');
      },
      sortable: true,
      filter: {
        name: 'date',
        type: IOurColumnFilterType.DateRange,
      },
    },
    {
      title: 'Quotation No',
      accessor: 'quotation_no',
      sortable: true,
      filter: {
        name: 'quotation_no',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Rev #',
      accessor: 'revision',
      sortable: true,
      filter: {
        name: 'revision',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Customer',
      accessor: 'customer',
      filter: {
        name: 'customer_id',
        type: IOurColumnFilterType.Dropdown,
        data: [],
      },
      customRender: (item: any) => {
        return item.customer ? item.customer.name : '-';
      },
    },
    {
      title: 'Marketing',
      accessor: 'marketing',
      filter: {
        name: 'marketing_id',
        type: IOurColumnFilterType.Dropdown,
        data: [],
      },
      customRender: (item: any) => {
        return item.marketing.name;
      },
    },
    {
      title: 'PO No.',
      accessor: 'customer_po_no',
      sortable: true,
      filter: {
        name: 'customer_po_no',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Inquiry No.',
      accessor: 'inquiry_no',
      sortable: true,
      filter: {
        name: 'inquiry_no',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Validity',
      accessor: 'validity',
      sortable: true,
      filter: {
        name: 'validity',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Payment',
      accessor: 'payment',
      sortable: true,
      filter: {
        name: 'payment',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Delivery',
      accessor: 'delivery',
      sortable: true,
      filter: {
        name: 'delivery',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Currency',
      accessor: 'currency',
      filter: {
        name: 'currency_id',
        type: IOurColumnFilterType.Dropdown,
        data: [],
      },
      customRender: (item: any) => {
        return item.currency.prefix;
      },
    },
    {
      title: 'DP',
      accessor: 'down_payment',
      customRender: (item: any) => {
        return TwoDecimalPlaces(item.down_payment);
      },
      sortable: true,
      filter: {
        name: 'down_payment',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Total',
      accessor: 'total',
      customRender: (item: any) => {
        return TwoDecimalPlaces(item.total);
      },
      sortable: true,
      filter: {
        name: 'total',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: ISupplier) => {
        return (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Tooltip label='Print'>
              <ActionIcon variant='light' color='cyan' onClick={() => print(item.id!)}>
                <Printer size={16} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label='Edit'>
              <ActionIcon variant='light' color='blue[4]' onClick={() => toEdit(item.id!)}>
                <Pencil size={16} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label='Delete'>
              <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
                <Trash size={16} />
              </ActionIcon>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const getCurrencies = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrencies(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSalesQuotationStatuses = () => {
    setIsLoading(true);
    EnumerationApi.getDataSource('sales_quotation_status')
      .then((res) => {
        var statuses = res.data.map((x) => {
          return {
            value: x.value,
            label: x.display_name,
          };
        });

        setSalesQuotationStatuses(statuses);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCustomers = () => {
    setIsLoading(true);
    CustomerApi.getDataSource()
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMarketings = () => {
    setIsLoading(true);
    MarketingApi.getDataSource()
      .then((res) => {
        setMarketings(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getColumns = () => {
    let cols = [...columns];
    var statusIndex = cols.findIndex((x) => x.accessor == 'status_in_string');
    var currencyIndex = cols.findIndex((x) => x.accessor == 'currency');
    var customerIndex = cols.findIndex((x) => x.accessor == 'customer');
    var marketingIndex = cols.findIndex((x) => x.accessor == 'marketing');

    if (cols[statusIndex] != undefined) {
      if (cols[statusIndex].filter != undefined) {
        cols[statusIndex].filter!.data = salesQuotationStatuses;
      }
    }

    if (cols[currencyIndex] != undefined) {
      if (cols[currencyIndex].filter != undefined) {
        cols[currencyIndex].filter!.data = currencies;
      }
    }

    if (cols[customerIndex] != undefined) {
      if (cols[customerIndex].filter != undefined) {
        cols[customerIndex].filter!.data = customers;
      }
    }

    if (cols[marketingIndex] != undefined) {
      if (cols[marketingIndex].filter != undefined) {
        cols[marketingIndex].filter!.data = marketings;
      }
    }

    return cols;
  };

  return (
    <InnerLayout title='Sales Quotation'>
      <OurBreadcrumbs crumbs={links} />
      <ListToolbar>
        <Group>
          <Button size='sm' leftIcon={<Plus size={16} />} onClick={toCreate}>
            Create Sales Quotation
          </Button>
        </Group>
      </ListToolbar>
      <OurTable
        isLoading={isLoading}
        page={datatableParams.page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={getColumns()}
        data={data}
      />
    </InnerLayout>
  );
};

export default QuotationList;
