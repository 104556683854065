import { Box, Button, Group, Modal, SelectItem, Space, Grid, Input } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { DeviceFloppy, Plus } from 'tabler-icons-react';
import CurrencyApi from '../../../apis/CurrencyApi';
import CustomerApi from '../../../apis/CustomerApi';

import DynamicField from '../../../components/DynamicField';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import OurTable from '../../../components/OurTable';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import InnerLayout from '../../InnerLayout';
import ItemForm from './itemForm';

const links = [
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
  {
    title: 'Sales Return',
    link: '/sales-return',
  },
  {
    title: 'Create',
  },
];

const columns = [
  {
    title: 'Detail Desc',
    accessor: 'detailDesc',
  },
  {
    title: 'Qty',
    accessor: 'quantity',
  },
  {
    title: 'Unit',
    accessor: 'unit',
  },
  {
    title: '@Price',
    accessor: 'price',
  },
  {
    title: 'Total Amount',
    accessor: 'total',
  },
];

const SalesReturnForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<SelectItem[]>([]);
  const [currencies, setCurrencies] = useState<SelectItem[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);

  const [showItemForm, setShowItemForm] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      customer_id: null,
      currency_id: null,
      quotation_no: '',
      inquiry_no: '',
      date: new Date(),
    },
  });

  const getCustomer = () => {
    setIsLoading(true);
    CustomerApi.getDataSource()
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCurrency = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrencies(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleItemForm = () => {
    setShowItemForm(!showItemForm);
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  useEffect(() => {
    getCurrency();
    getCustomer();
  }, []);

  return (
    <>
      <InnerLayout title='Sales Return'>
        <OurBreadcrumbs crumbs={links} />

        <ListToolbar>
          <Group>
            <Button size='sm' leftIcon={<DeviceFloppy size={16} />}>
              Submit
            </Button>
          </Group>
        </ListToolbar>

        <Grid>
          <Grid.Col span={3}>
            <DynamicField
              field={{
                name: 'customer_id',
                type: IFormGeneratorFieldType.Dropdown,
                datasource: customers,
                label: 'Customer',
              }}
              form={form}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <DynamicField
              field={{
                name: 'doc_no',
                type: IFormGeneratorFieldType.Text,
                label: 'Doc No.',
              }}
              form={form}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <DynamicField
              field={{
                name: 'date',
                type: IFormGeneratorFieldType.Date,
                label: 'Date',
              }}
              form={form}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <DynamicField
              field={{
                name: 'invoice_no',
                type: IFormGeneratorFieldType.Text,
                label: 'Invoice No.',
              }}
              form={form}
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={3}>
            <DynamicField
              field={{
                name: 'currency_id',
                type: IFormGeneratorFieldType.Dropdown,
                datasource: currencies,
                label: 'Currency',
              }}
              form={form}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <DynamicField
              field={{
                name: 'amount',
                type: IFormGeneratorFieldType.Number,
                label: 'Amount',
              }}
              form={form}
            />
          </Grid.Col>
        </Grid>

        <Space h={24} />
        <ListToolbar>
          <Group>
            <Button size='sm' leftIcon={<Plus size={16} />} onClick={toggleItemForm}>
              Add Items
            </Button>
          </Group>
        </ListToolbar>
        <Box>
          <OurTable
            isLoading={isLoading}
            page={page}
            handleChangePage={handleChangePage}
            totalPage={totalPage}
            columns={columns}
            data={[]}
          />
        </Box>

        <Space h={12} />

        <Grid justify='end'>
          <Grid.Col span={3}>
            <span style={{ float: 'right' }}>Total S$</span>
          </Grid.Col>
          <Grid.Col span={3}>
            <Input type='number' />
          </Grid.Col>
        </Grid>

        <Grid justify='end'>
          <Grid.Col span={3}>
            <span style={{ float: 'right' }}>HM Curr Total After</span>
          </Grid.Col>
          <Grid.Col span={3}>
            <Input type='number' />
          </Grid.Col>
        </Grid>

        <Modal title='Add Sales Return Item' opened={showItemForm} onClose={() => toggleItemForm()}>
          <ItemForm afterSubmit={() => {}} />
        </Modal>
      </InnerLayout>
    </>
  );
};

export default SalesReturnForm;
