import { SelectItem } from '@mantine/core';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import _fetch from '../helpers/_fetch';
import {
  ICurrency,
  ICurrencyDataTableRequest,
  ICurrencyDataTableResponse,
  IPostCurrencyRequest,
} from '../interfaces/requests/ICurrencyRequest';

const url = '/currency';

const CurrencyApi = {
  getById: (id?: number): Promise<AxiosResponse<ICurrency>> => {
    return _fetch<ICurrency>(`${url}/${id}`);
  },
  getDataTable: (
    body: ICurrencyDataTableRequest = {
      page: 1,
      limit: 10,
    },
    filter: any
  ): Promise<AxiosResponse<ICurrencyDataTableResponse>> => {
    let extraUrl = qs.stringify(filter);
    return _fetch<ICurrencyDataTableResponse>(
      `${url}/dt?page=${body.page}&limit=${body.limit}&${extraUrl ? extraUrl : ''}`,
      {
        method: 'POST',
      }
    );
  },
  getDataSource: (): Promise<AxiosResponse<SelectItem[]>> => {
    return _fetch<SelectItem[]>(`${url}/datasource`, {
      method: 'POST',
    });
  },
  create: (body: ICurrency): Promise<AxiosResponse<IPostCurrencyRequest>> => {
    return _fetch<IPostCurrencyRequest>(`${url}`, {
      method: 'POST',
      body: body,
    });
  },
  remove: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`, {
      method: 'delete',
    });
  },
  default: (): Promise<AxiosResponse<ICurrency>> => {
    return _fetch<ICurrency>(`${url}/default`);
  },
};

export default CurrencyApi;
