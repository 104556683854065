import { Route, Routes } from 'react-router-dom';
import QuotationList from './list';
import QuotationCreateForm from './create';
import QuotationEditForm from './edit';

const QuotationMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<QuotationList />}></Route>
        <Route path='/create' element={<QuotationCreateForm />}></Route>
        <Route path='/update/:salesQuotationId' element={<QuotationEditForm />}></Route>
      </Routes>
    </>
  );
};

export default QuotationMasterLayout;
