import { ActionIcon, Button, Group, Select, SelectItem, TextInput, Text, Modal, Space, Box } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Check, Pencil, Plus, Trash, DeviceFloppy } from 'tabler-icons-react';
import CurrencyApi from '../../../apis/CurrencyApi';
import PurchaseOrderApi from '../../../apis/PurchaseOrderApi';
import SupplierApi from '../../../apis/SupplierApi';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import OurTable from '../../../components/OurTable';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IItemPurchaseOrder, IPurchaseOrderRequest } from '../../../interfaces/requests/IPurchaseOrderRequest';
import InnerLayout from '../../InnerLayout';
import EditItemForm from './editItemForm';
import PurchaseOrderForm from './form';
import ListToolbar from '../../../components/ListToolbar';
import UnitApi from '../../../apis/UnitApi';

const links = [
  {
    title: 'Purchase Order',
    link: '/purchase-order',
  },
  {
    title: 'v1',
    link: 'dashboard',
  },
  {
    title: 'v2',
    link: 'dashboard',
  },
];

const CreateOrEditPurchaseOrderMasterLayout = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [currency, setCurrency] = useState<SelectItem[]>([]);
  const [supplier, setSupplier] = useState<SelectItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [editItemModalOpened, setEditItemModalOpened] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<any>({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [data, setData] = useState<IPurchaseOrderRequest>({
    code: '',
    date: new Date(),
    supplier_id: '',
    currency_id: '',
    status: 0,
    close_reason: '',
    lines: [],
  });
  const modals = useModals();
  const params = useParams();
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Name',
      accessor: 'name',
    },
    {
      title: 'Size',
      accessor: 'size',
    },
    {
      title: 'Unit',
      accessor: 'unit_name',
    },
    {
      title: 'Weight',
      accessor: 'weight',
    },
    {
      title: 'Length',
      accessor: 'length',
    },

    {
      title: 'Dimension',
      accessor: 'dimension',
    },
    {
      title: 'Quantity',
      accessor: 'quantity',
    },
    {
      title: 'Price',
      accessor: 'price',
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: any) => {
        return (
          <Group spacing={'xs'} style={{ zIndex: 99999 }}>
            <ActionIcon
              variant='light'
              color='red'
              id='delete-button'
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                // console.log((e.target as HTMLButtonElement)?.value)
                if ((e.target as HTMLButtonElement)?.id !== 'click-detail') {
                  onDeletePrompt(item);
                }
                e.stopPropagation();
              }}
            >
              <Trash size={16} />
            </ActionIcon>
            <ActionIcon variant='light' color='blue[3]' onClick={() => toEdit(item)}>
              <Pencil size={16} />
            </ActionIcon>
          </Group>
        );
      },
    },
  ];

  const toEdit = (item: any) => {
    let body = {
      id: item.id,
      quantity: item.quantity,
      price: item.price,
    };
    setCurrentData(body);
    setEditItemModalOpened(true);
  };

  const toggleModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  const remove = (item: any) => {
    let newData = [...data.lines].filter((x) => x.id !== item.id);
    setData((prevState) => ({
      ...prevState,
      lines: newData,
    }));
  };

  const onDeletePrompt = (item: any) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(item),
    });
  };

  const toCreate = () => {
    toggleModal();
  };

  const updateSelectValue = (value: string, type: string) => {
    if (type === 'currency') {
      setData((prevState) => ({
        ...prevState,
        currency_id: value,
      }));
    }

    if (type === 'supplier') {
      setData((prevState) => ({
        ...prevState,
        supplier_id: value,
      }));
    }
  };

  const updateDateValue = (value: Date) => {
    setData((prevState) => ({
      ...prevState,
      date: value,
    }));
  };

  const updateInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData((prevState) => ({
      ...prevState,
      code: event.target.value,
    }));
  };

  const afterSubmit = (value: any) => {
    // let isExist = data.lines.find((x) => x.id === value.id);
    // if (!isExist) {

    UnitApi.getById(value.unit_id).then((res) => {
      let newData: IItemPurchaseOrder = {
        id: value.id,
        dimension: value.dimensions,
        length: value.length,
        name: value.name,
        price: value.price,
        quantity: value.quantity,
        size: value.size,
        weight: value.weight,
        unit_id: value.unit_id,
        unit_name: res.data.name,
      };

      setData((prevState) => {
        return {
          ...prevState,
          lines: [...prevState.lines, newData],
        };
      });
      toggleModal();
    });
  };

  const editItemAfterSubmit = (value: any) => {
    let newData = [...data.lines];

    let manipulatedNewData = newData.map((x) => {
      if (x.id === value.id) {
        return {
          ...x,
          price: value.price,
          quantity: value.quantity,
        };
      }

      return x;
    });

    setData((prevState) => {
      return {
        ...prevState,
        lines: manipulatedNewData,
      };
    });

    toggleEdiItemModal();
  };

  const toggleEdiItemModal = () => {
    setEditItemModalOpened(!editItemModalOpened);
  };

  const getCurrencyList = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrency(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSupplierList = () => {
    setIsLoading(true);
    SupplierApi.getDataSource()
      .then((res) => {
        setSupplier(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getById = (id: number) => {
    setIsLoading(true);
    PurchaseOrderApi.getById(id)
      .then((res) => {
        let linesData = res.data.purchase_order_lines.map((x: any) => {
          return {
            id: x.item_id,
            name: x.item.name,
            size: x.item.size,
            weight: x.item.weight,
            length: x.item.length,
            dimension: x.item.dimensions,
            quantity: x.quantity,
            price: x.price,
          };
        });

        const { code, date, currency_id, status, supplier_id, close_reason } = res.data;
        let newData: IPurchaseOrderRequest = {
          id: Number(params.id),
          code,
          date: new Date(moment(date).format('YYYY-MM-DD')),
          currency_id: String(currency_id),
          status,
          supplier_id: String(supplier_id),
          close_reason,
          lines: linesData,
        };
        setData(newData);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const createPurchaseOrder = () => {
    setIsSubmitting(true);
    let fields = [...data.lines];
    let newData = fields.map((x) => {
      return {
        item_id: x.id,
        quantity: x.quantity,
        price: x.price,
        unit_id: x.unit_id,
      };
    });
    let body = {
      ...data,
      date: moment(data.date).format('YYYY-MM-DD'),
      lines: newData,
    };

    let actionToBeTakenAPI;
    if (params.id) {
      actionToBeTakenAPI = PurchaseOrderApi.update(body);
    } else {
      actionToBeTakenAPI = PurchaseOrderApi.create(body);
    }

    actionToBeTakenAPI
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: `Purchase Order ${params.id ? 'Updated' : 'Created'}`,
          icon: <Check />,
        });
        navigate('/purchase-order');
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  useEffect(() => {
    getCurrencyList();
    getSupplierList();
  }, []);

  useEffect(() => {
    const paramsId = params.id;
    if (paramsId) {
      getById(Number(paramsId));
    }
  }, []);

  return (
    <InnerLayout title='Purchase Order'>
      <OurBreadcrumbs crumbs={links} />
      <ListToolbar>
        <Group>
          <Button onClick={createPurchaseOrder} size='sm' leftIcon={<DeviceFloppy size={16} />}>
            Submit
          </Button>
        </Group>
      </ListToolbar>
      <Group grow>
        <Group align='column' grow spacing='xs'>
          <TextInput required label='Code' value={data.code} onChange={(event) => updateInputValue(event)} />
          <DatePicker label='Date' required value={data.date} onChange={(value) => value && updateDateValue(value)} />
        </Group>
        <Group align='column' grow spacing='xs'>
          <Select
            style={{ minWidth: '150px' }}
            data={currency}
            required
            searchable
            label='Currency'
            value={data.currency_id}
            onChange={(value) => value && updateSelectValue(value, 'currency')}
          />
          <Select
            style={{ minWidth: '150px' }}
            data={supplier}
            required
            label='Supplier'
            searchable
            value={data.supplier_id}
            onChange={(value) => value && updateSelectValue(value, 'supplier')}
          />
        </Group>
      </Group>
      <Space h={24} />
      <ListToolbar>
        <Group>
          <Button size='sm' leftIcon={<Plus size={16} />} onClick={toCreate}>
            Add Items
          </Button>
        </Group>
      </ListToolbar>
      <Box>
        <OurTable
          isLoading={isLoading}
          page={page}
          handleChangePage={handleChangePage}
          totalPage={totalPage}
          columns={columns}
          data={data.lines}
        />
      </Box>
      <Modal opened={createModalOpened} onClose={toggleModal} title={'Create Item'}>
        <PurchaseOrderForm afterSubmit={afterSubmit} />
      </Modal>
      <Modal opened={editItemModalOpened} onClose={toggleEdiItemModal} title={'Edit Item'}>
        <EditItemForm afterSubmit={editItemAfterSubmit} data={currentData} />
      </Modal>
    </InnerLayout>
  );
};

export default CreateOrEditPurchaseOrderMasterLayout;
