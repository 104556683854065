import { Route, Routes } from 'react-router-dom'
import AccountReceiveableReportList from './list'

const AccountReceiveableReportMasterLayout = () => {
 return (
  <>
   <Routes>
    <Route index element={<AccountReceiveableReportList />}></Route>
   </Routes>
  </>
 )
}

export default AccountReceiveableReportMasterLayout
