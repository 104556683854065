import { Button, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { IFormGeneratorProps } from '../interfaces/IFormGeneratorProps';
import DynamicField from './DynamicField';

const FormGenerator = (props: IFormGeneratorProps) => {
  const form = useForm({
    initialValues: props.initialValues,
  });

  useEffect(() => {
    if (props.initialValues.id) {
      form.setValues(props.initialValues);
    } else {
      form.reset();
    }
  }, [props.initialValues]);

  const formOnSubmit = (values: any) => {
    props.onSubmit(values);
  };

  return (
    <form onSubmit={form.onSubmit(formOnSubmit)}>
      {props.fields.map((field, index) => (
        <DynamicField field={field} form={form} key={index} />
      ))}
      <Group position='right' mt='md'>
        <Button type='submit'>Submit</Button>
      </Group>
    </form>
  );
};

export default FormGenerator;
