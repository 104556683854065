import { Route, Routes } from 'react-router-dom';
import SalesInvoiceList from './list';
import SalesInvoiceCreateForm from './create';
import SalesInvoiceEditForm from './edit';

const SalesInvoiceMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<SalesInvoiceList />}></Route>
        <Route path='/create' element={<SalesInvoiceCreateForm />}></Route>
        <Route path='/update/:salesInvoiceId' element={<SalesInvoiceEditForm />}></Route>
      </Routes>
    </>
  );
};

export default SalesInvoiceMasterLayout;
