import { Route, Routes } from 'react-router-dom';
import CustomerOrderList from './list';
import CustomerOrderCreateForm from './create';
import CustomerOrderEditForm from './edit';

const CustomerOrderMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<CustomerOrderList />}></Route>
        <Route path='/create' element={<CustomerOrderCreateForm />}></Route>
        <Route path='/update/:customerOrderId' element={<CustomerOrderEditForm />}></Route>
      </Routes>
    </>
  );
};

export default CustomerOrderMasterLayout;
