import { Box, NativeSelect, Grid, Input, Button, Space, Group, ActionIcon, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import { useState } from 'react';
import { Check, Search } from 'tabler-icons-react';
import CustomerOrderApi from '../../../apis/CustomerOrderApi';
import OurTable from '../../../components/OurTable';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IItem } from '../../../interfaces/requests/IItemRequest';

const ItemForm = (props: any) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [searchBySelected, setSearchBySelected] = useState('project_no');
  const [searchByValue, setSearchByValue] = useState('');

  const [searchProjectList, setSearchProjectList] = useState<any>([]);

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  const columns = [
    {
      title: 'Date',
      accessor: 'date',
      customRender: (item: any) => {
        return moment(item.date).format('YYYY-MM-YY');
      },
    },
    {
      title: 'Project No',
      accessor: 'project_no',
    },
    {
      title: 'Project Title',
      accessor: 'project_title',
    },
    {
      title: 'ID Cust',
      accessor: 'customer_id',
    },
    {
      title: 'Customer Name',
      accessor: 'customer_name',
      customRender: (item: any) => {
        return item.customer ? item.customer.name : '-';
      },
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: IItem) => {
        return (
          <Group spacing={'xs'}>
            <ActionIcon variant='light' color='blue[4]' onClick={() => props.onProjectSelected(item)}>
              <Check size={16} />
            </ActionIcon>
          </Group>
        );
      },
    },
  ];

  const onSearchClicked = () => {
    setIsSubmitting(true);

    let body = {
      [searchBySelected]: searchByValue,
    };

    CustomerOrderApi.search(body)
      .then((res) => {
        setSearchProjectList(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Box>
      <Grid>
        <Grid.Col span={3}>
          <NativeSelect
            mt='sm'
            label='Search By'
            value={searchBySelected}
            data={[
              {
                label: 'Project No',
                value: 'project_no',
              },
              {
                label: 'Project Title',
                value: 'project_title',
              },
              {
                label: 'ID Customer',
                value: 'customer_id',
              },
              {
                label: 'Customer Name',
                value: 'customer_name',
              },
            ]}
            onChange={(data) => setSearchBySelected(data.target.value)}
          />
        </Grid.Col>

        <Grid.Col span={7}>
          <TextInput
            type='text'
            mt='sm'
            label='Search Value'
            value={searchByValue}
            onChange={(event: any) => {
              setSearchByValue(event.target.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={2} className='flex items-end'>
          <Button size='sm' leftIcon={<Search size={16} />} onClick={onSearchClicked}>
            Search
          </Button>
        </Grid.Col>
      </Grid>

      <Space h={12} />

      <OurTable
        isLoading={isLoading}
        page={page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={columns}
        data={searchProjectList}
      />
    </Box>
  );
};

export default ItemForm;
