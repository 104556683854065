import { SelectItem } from '@mantine/core';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import _fetch from '../helpers/_fetch';
import {
  IMarketing,
  IMarketingDataTableRequest,
  IMarketingDataTableResponse,
  IPostMarketingRequest,
} from '../interfaces/requests/IMarketingRequest';

const url = '/marketing';

const MarketingApi = {
  getDataTable: (
    body: IMarketingDataTableRequest = {
      page: 1,
      limit: 10,
    },
    filter: any
  ): Promise<AxiosResponse<IMarketingDataTableResponse>> => {
    let extraUrl = qs.stringify(filter);
    return _fetch<IMarketingDataTableResponse>(
      `${url}/dt?page=${body.page}&limit=${body.limit}&${extraUrl ? extraUrl : ''}`,
      {
        method: 'POST',
      }
    );
  },
  create: (body: IMarketing): Promise<AxiosResponse<IPostMarketingRequest>> => {
    return _fetch<IPostMarketingRequest>(`${url}`, {
      method: 'POST',
      body: body,
    });
  },
  remove: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`, {
      method: 'delete',
    });
  },
  getById: (id?: number): Promise<AxiosResponse<IMarketing>> => {
    return _fetch<IMarketing>(`${url}/${id}`);
  },
  getDataSource: (): Promise<AxiosResponse<SelectItem[]>> => {
    return _fetch<SelectItem[]>(`${url}/datasource`, {
      method: 'POST',
    });
  },
};

export default MarketingApi;
