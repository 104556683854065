import { Route, Routes } from 'react-router-dom'
import SupplierList from './list'

const SupplierMasterLayout = () => {
    return (
        <>
            <Routes>
                <Route index element={<SupplierList />} />
            </Routes>
        </>
    )
}

export default SupplierMasterLayout