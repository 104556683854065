import React, { useEffect, useState } from 'react';
import { TextInput, PasswordInput, Anchor, Paper, Title, Text, Container, Group, Button } from '@mantine/core';
import config from '../../setups/config';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';
import { ILoginRequest } from '../../interfaces/requests/IAuthRequest';
import AuthApi from '../../apis/AuthApi';
import ErrorHandler from '../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';

const Login = () => {
  let navigate = useNavigate();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
  });

  const [accessToken, setAccessToken] = useLocalStorage<string>({
    key: 'access_token',
    defaultValue: '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onLogin = (values: ILoginRequest) => {
    setIsLoading(true);
    AuthApi.login(values)
      .then((response) => {
        localStorage.setItem('access_token', response.data.plainTextToken);
        navigate('/dashboard');
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (accessToken) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <Container size={420} my={40}>
      <form onSubmit={form.onSubmit((values) => onLogin(values))}>
        <Title align='center' sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
          Welcome back!
        </Title>
        {config.enableCreateAccount ? (
          <Text color='dimmed' size='sm' align='center' mt={5}>
            Do not have an account yet?{' '}
            <Anchor<'a'> href='#' size='sm' onClick={(event) => event.preventDefault()}>
              Create account
            </Anchor>
          </Text>
        ) : null}

        <Paper withBorder shadow='md' p={30} mt={30} radius='md'>
          <TextInput
            label='Email'
            placeholder='you@mantine.dev'
            required
            {...form.getInputProps('email')}
            disabled={isLoading}
          />
          <PasswordInput
            label='Password'
            placeholder='Your password'
            required
            mt='md'
            {...form.getInputProps('password')}
            disabled={isLoading}
          />
          <Group position='apart' mt='md'>
            {config.enableResetAccount ? (
              <Anchor<'a'> onClick={(event) => event.preventDefault()} href='#' size='sm'>
                Forgot password?
              </Anchor>
            ) : null}
          </Group>
          <Button fullWidth mt='xl' type='submit' loading={isLoading}>
            Sign in
          </Button>
        </Paper>
      </form>
    </Container>
  );
};

export default Login;
