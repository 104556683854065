import { Box } from '@mantine/core';
import { useEffect, useState } from 'react';
import FormGenerator from '../../../components/FormGenerator';
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';

const ItemForm = (props: { afterSubmit: Function; id?: number }) => {
  const fields: IFormGeneratorFieldProps[] = [
    {
      name: 'poNo',
      label: 'PO No',
      required: true,
      type: IFormGeneratorFieldType.Number,
    },
    {
      name: 'idItem',
      label: 'ID Item',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'itemDesc',
      label: 'Item Desc',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'quantity',
      label: 'Qty',
      required: true,
      type: IFormGeneratorFieldType.Number,
    },
    {
      name: 'unit',
      label: 'Unit',
      required: true,
      type: IFormGeneratorFieldType.Number,
    },
    {
      name: 'sQuantity',
      label: 'S. Qty',
      required: true,
      type: IFormGeneratorFieldType.Number,
    },
    {
      name: 'sUnit',
      label: 'S. Unit',
      required: true,
      type: IFormGeneratorFieldType.Number,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    detailDesc: null,
    quantity: 0,
    unit_id: null,
    price: 0,
    totalAmount: 0,
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
  };

  return (
    <Box>
      <FormGenerator fields={fields} initialValues={initialValues} onSubmit={onSubmit} loading={isSubmitting} />
    </Box>
  );
};

export default ItemForm;
