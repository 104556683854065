import { Route, Routes } from 'react-router-dom';
import UnitList from './list';

const UnitMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<UnitList />} />
      </Routes>
    </>
  );
};

export default UnitMasterLayout;
