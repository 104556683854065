import { useNavigate } from 'react-router-dom';
import InnerLayout from '../../InnerLayout';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import ListToolbar from '../../../components/ListToolbar';
import { ActionIcon, Button, Group, Modal, SelectItem, Textarea, Tooltip, Drawer } from '@mantine/core';
import { Check, Pencil, Plus, Printer, Truck, X } from 'tabler-icons-react';
import OurTable, { IOurColumnFilterType, IOurTableColumnProps } from '../../../components/OurTable';
import { useEffect, useState } from 'react';
import {
  IPurchaseOrder,
  IPurchaseOrderCancelPO,
  IPurchaseOrderLine,
} from '../../../interfaces/requests/IPurchaseOrderRequest';
import PurchaseOrderApi from '../../../apis/PurchaseOrderApi';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import EnumerationApi from '../../../apis/EnumerationApi';
import SupplierApi from '../../../apis/SupplierApi';
import CurrencyApi from '../../../apis/CurrencyApi';
import TwoDecimalPlaces from '../../../helpers/_twoDecimalPlaces';

const links = [
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
  {
    title: 'Purchase Order',
    link: 'purchase-order',
  },
];

const PurchaseOrderList = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<IPurchaseOrder[]>([]);
  const [reason, setReason] = useState<string>('');
  const [id, setId] = useState<number | undefined>(0);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [datatableParams, setDatatableParams] = useState<any>({
    page: 1,
  });
  const [purchaseOrderStatuses, setPurchaseOrderStatuses] = useState<SelectItem[]>([]);
  const [currencies, setCurrencies] = useState<SelectItem[]>([]);
  const [suppliers, setSuppliers] = useState<SelectItem[]>([]);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IPurchaseOrder | null>(null);

  const navigate = useNavigate();
  const handleCreateRoute = () => {
    navigate('/purchase-order/create');
  };

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  const toEdit = (item: IPurchaseOrder) => {
    navigate(`/purchase-order/update/${item.id}`);
  };

  const getList = () => {
    setIsLoading(true);
    const { page, ...rest } = datatableParams;
    PurchaseOrderApi.getDataTable(
      {
        limit,
        page,
      },
      rest
    )
      .then((res) => {
        let newData = res.data.data.map((x) => {
          return {
            ...x,
            date: moment(x.date).format('YYYY-MM-DD'),
          };
        });
        setTotalPage(res.data.last_page);
        setData(newData);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const itemOnClick = (item: IPurchaseOrder) => {
    setDrawerOpen(true);

    PurchaseOrderApi.getById(item.id)
      .then((res) => {
        setSelectedItem(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedItem(null);
  };

  const getPurchaseOrderStatus = () => {
    setIsLoading(true);
    EnumerationApi.getDataSource('purchase_order_status')
      .then((res) => {
        var statuses = res.data.map((x) => {
          return {
            value: x.value,
            label: x.display_name,
          };
        });

        setPurchaseOrderStatuses(statuses);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSuppliers = () => {
    setIsLoading(true);
    SupplierApi.getDataSource()
      .then((res) => {
        setSuppliers(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCurrencies = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrencies(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onPromptClosePO = (item: any) => {
    setId(item.id);
    setModalOpened(true);
  };

  const updateReasonValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event.target.value);
  };

  const handleChangePage = (value: any) => {
    setDatatableParams(value);
  };

  const handleClosePO = () => {
    setIsSubmitting(true);
    let currentId = id;
    let body: IPurchaseOrderCancelPO = {
      cancel_reason: reason,
      id: Number(currentId),
    };
    PurchaseOrderApi.cancelPO(body)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Purchase Order Updated!',
          icon: <Check />,
        });

        getList();
        setReason('');
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    toggleModal();
  };

  const handleCompletePO = (item: any) => {
    setIsSubmitting(true);
    PurchaseOrderApi.completePO({
      limit: 10,
      page: 1,
      id: item.id,
    })
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Purchase Order Created!',
          icon: <Check />,
        });

        getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const print = (purchaseOrderId: number) => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/api/purchase-order/print/${purchaseOrderId}`, '_blank');
  };

  const columns: IOurTableColumnProps[] = [
    {
      title: 'Status',
      accessor: 'status_in_string',
      withFilter: true,
      filter: {
        name: 'status',
        type: IOurColumnFilterType.Dropdown,
        data: [],
      },
    },
    {
      title: 'Date',
      accessor: 'date',
      withFilter: true,
      filter: {
        name: 'date',
        type: IOurColumnFilterType.DateRange,
      },
    },
    {
      title: 'PO No.',
      accessor: 'code',
      withFilter: true,
      filter: {
        name: 'code',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Supplier',
      accessor: 'supplier',
      withFilter: true,
      filter: {
        name: 'supplier_id',
        type: IOurColumnFilterType.Dropdown,
        data: [],
      },
      customRender: (item: IPurchaseOrder) => {
        return item.supplier ? item.supplier.name : '-';
      },
    },
    {
      title: 'Currency',
      accessor: 'currency',
      withFilter: true,
      filter: {
        name: 'currency_id',
        type: IOurColumnFilterType.Dropdown,
        data: [],
      },
      customRender: (item: IPurchaseOrder) => {
        return item.currency.prefix;
      },
    },
    {
      title: 'Rate',
      accessor: 'currency_snapshot_rate',
      customRender: (item: IPurchaseOrder) => {
        return TwoDecimalPlaces(item.currency_snapshot_rate);
      },
    },
    {
      title: 'Total',
      accessor: 'total',
      customRender: (item: IPurchaseOrder) => {
        return TwoDecimalPlaces(item.total);
      },
    },
    {
      title: 'HM Currency Total',
      accessor: 'currency_converted_total',
      customRender: (item: IPurchaseOrder) => {
        return TwoDecimalPlaces(item.currency_converted_total);
      },
    },
    {
      title: 'Close Reason',
      accessor: 'close_reason',
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: IPurchaseOrder) => {
        return (
          <Group spacing={'xs'}>
            <Tooltip label='Print' withArrow>
              <ActionIcon variant='light' color='cyan' onClick={() => print(item.id!)}>
                <Printer size={16} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label='Complete PO' withArrow>
              <ActionIcon
                disabled={item.status_in_string.toUpperCase() !== 'OPEN' ? true : false}
                variant='light'
                color='green'
                onClick={() => handleCompletePO(item)}
              >
                <Truck size={16} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label='Edit' withArrow>
              <ActionIcon
                disabled={item.status_in_string.toUpperCase() !== 'OPEN' ? true : false}
                variant='light'
                color='blue[4]'
                onClick={() => toEdit(item)}
              >
                <Pencil size={16} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label='Cancel PO' withArrow>
              <ActionIcon
                disabled={item.status_in_string.toUpperCase() !== 'OPEN' ? true : false}
                variant='light'
                color='red'
                onClick={() => onPromptClosePO(item)}
              >
                <X size={16} />
              </ActionIcon>
            </Tooltip>
          </Group>
        );
      },
    },
  ];

  const detailColumns: IOurTableColumnProps[] = [
    {
      title: 'Item ID',
      accessor: 'item',
      customRender: (item: IPurchaseOrderLine) => {
        return item.item.code;
      },
    },
    {
      title: 'Desc',
      accessor: 'item',
      customRender: (item: IPurchaseOrderLine) => {
        return item.item.name;
      },
    },
    {
      title: 'Qty',
      accessor: 'quantity',
    },
    {
      title: 'Unit',
      accessor: 'item',
      customRender: (item: IPurchaseOrderLine) => {
        return item.unit?.code;
      },
    },
    {
      title: '@Price',
      accessor: 'item',
      customRender: (item: IPurchaseOrderLine) => {
        return TwoDecimalPlaces(item.price);
      },
    },
    {
      title: 'Amount',
      accessor: 'item',
      customRender: (item: IPurchaseOrderLine) => {
        return TwoDecimalPlaces(item.total);
      },
    },
  ];

  useEffect(() => {
    if (!data.length) {
      getList();
    }

    getPurchaseOrderStatus();
    getSuppliers();
    getCurrencies();
  }, []);

  useEffect(() => {
    getList();
  }, [datatableParams]);

  const getColumns = () => {
    let cols = [...columns];
    var statusIndex = cols.findIndex((x) => x.accessor == 'status_in_string');
    var currencyIndex = cols.findIndex((x) => x.accessor == 'currency');
    var supplierIndex = cols.findIndex((x) => x.accessor == 'supplier');

    if (cols[statusIndex] != undefined) {
      if (cols[statusIndex].filter != undefined) {
        cols[statusIndex].filter!.data = purchaseOrderStatuses;
      }
    }

    if (cols[currencyIndex] != undefined) {
      if (cols[currencyIndex].filter != undefined) {
        cols[currencyIndex].filter!.data = currencies;
      }
    }

    if (cols[supplierIndex] != undefined) {
      if (cols[supplierIndex].filter != undefined) {
        cols[supplierIndex].filter!.data = suppliers;
      }
    }

    return cols;
  };

  return (
    <InnerLayout title='Purchase Order'>
      <OurBreadcrumbs crumbs={links} />
      <ListToolbar>
        <Group>
          <Button size='sm' leftIcon={<Plus size={16} />} onClick={handleCreateRoute}>
            Create Purchase Order
          </Button>
        </Group>
      </ListToolbar>
      <OurTable
        isLoading={isLoading}
        page={datatableParams.page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={getColumns()}
        data={data}
        clickEvent={itemOnClick}
      />
      <Modal opened={modalOpened} onClose={toggleModal} title={'Reason'}>
        <Textarea value={reason} onChange={(event) => updateReasonValue(event)} required />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='red' mt='sm' size='sm' onClick={handleClosePO}>
            Close PO
          </Button>
        </div>
      </Modal>
      <Drawer
        opened={drawerOpen}
        title='Purchase Order Detail'
        padding='md'
        size='md'
        onClose={closeDrawer}
        position='bottom'
      >
        <OurTable
          isLoading={isLoading}
          page={1}
          handleChangePage={() => {}}
          totalPage={1}
          columns={detailColumns}
          data={selectedItem?.purchase_order_lines}
        />
      </Drawer>
    </InnerLayout>
  );
};

export default PurchaseOrderList;
