import { ActionIcon, Button, Group, Text } from '@mantine/core';
import { Pencil, Trash } from 'tabler-icons-react';
import { Plus } from 'tabler-icons-react';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import InnerLayout from '../../InnerLayout';
import OurTable, { IOurColumnFilterType } from '../../../components/OurTable';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import { ISupplier } from '../../../interfaces/requests/ISupplierRequest';
import { useModals } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import CustomerOrderApi from '../../../apis/CustomerOrderApi';
import moment from 'moment';
import { ICustomerOrder } from '../../../interfaces/requests/ICustomerOrderRequest';

const links = [
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
  {
    title: 'Customer Order',
  },
];

const CustomerOrderList = () => {
  const [data, setData] = useState<ICustomerOrder[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>(0);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [datatableParams, setDatatableParams] = useState<any>({
    page: 1,
  });

  const navigate = useNavigate();

  const modals = useModals();

  const getList = () => {
    setIsLoading(true);
    const { page, ...rest } = datatableParams;
    CustomerOrderApi.getDataTable({
      limit,
      page,
      ...rest,
    })
      .then((res) => {
        setTotalPage(res.data.last_page);
        setData(res.data.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  useEffect(() => {
    if (!data.length) {
      getList();
    }
  }, []);

  const toCreate = () => {
    navigate('create');
  };

  useEffect(() => {
    getList();
  }, [datatableParams]);

  const toEdit = (customerOrderId: number) => {
    navigate(`/customer-order/update/${customerOrderId}`);
  };

  const remove = (item: ISupplier) => {
    setIsLoading(true);
    CustomerOrderApi.remove(item.id)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Supplier Removed!',
          icon: <Trash />,
        });
        getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (value: any) => {
    setDatatableParams(value);
  };

  const onDeletePrompt = (data: any) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(data),
    });
  };

  const columns = [
    {
      title: 'Status',
      accessor: 'status_in_string',
      sortable: true,
    },
    {
      title: 'Date',
      accessor: 'date',
      customRender: (item: any) => {
        return moment(item.date).format('DD MMM YYYY');
      },
      withFilter: true,
      filter: {
        name: 'date',
        type: IOurColumnFilterType.DateRange,
      },
      sortable: true,
    },
    {
      title: 'Project No',
      accessor: 'project_no',
      withFilter: true,
      filter: {
        name: 'project_no',
        type: IOurColumnFilterType.Text,
      },
      sortable: true,
    },
    {
      title: 'Project Title',
      accessor: 'project_title',
      withFilter: true,
      filter: {
        name: 'project_title',
        type: IOurColumnFilterType.Text,
      },
      sortable: true,
    },
    {
      title: 'ID Cust',
      accessor: 'customer_id',
      customRender: (item: any) => {
        return item.customer ? item.customer.code : '-';
      },
      withFilter: true,
      filter: {
        name: 'customer_code',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Customer Name',
      accessor: 'customerName',
      customRender: (item: any) => {
        return item.customer ? item.customer.name : '-';
      },
      withFilter: true,
      filter: {
        name: 'customer_name',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: ISupplier) => {
        return (
          <Group spacing={'xs'}>
            <ActionIcon variant='light' color='blue[4]' onClick={() => toEdit(item.id!)}>
              <Pencil size={16} />
            </ActionIcon>
            <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
              <Trash size={16} />
            </ActionIcon>
          </Group>
        );
      },
    },
  ];

  return (
    <InnerLayout title='Customer Order'>
      <OurBreadcrumbs crumbs={links} />
      <ListToolbar>
        <Group>
          <Button size='sm' leftIcon={<Plus size={16} />} onClick={toCreate}>
            Create
          </Button>
        </Group>
      </ListToolbar>
      <OurTable
        isLoading={isLoading}
        page={datatableParams.page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={columns}
        data={data}
      />
    </InnerLayout>
  );
};

export default CustomerOrderList;
