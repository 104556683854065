import { ActionIcon, Button, Group, Modal, Switch, Text } from '@mantine/core';
import { Pencil, Trash } from 'tabler-icons-react';
import { Plus } from 'tabler-icons-react';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import InnerLayout from '../../InnerLayout';
import OurTable, { IOurColumnFilterType } from '../../../components/OurTable';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import SupplierApi from '../../../apis/SupplierApi';
import { ISupplier } from '../../../interfaces/requests/ISupplierRequest';
import SupplierForm from './form';
import { useModals } from '@mantine/modals';

const links = [
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
  {
    title: 'Suppliers',
    link: 'supplier',
  },
];

const SupplierList = () => {
  const [data, setData] = useState<ISupplier[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>(0);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [datatableParams, setDatatableParams] = useState<any>({
    page: 1,
  });

  const modals = useModals();

  const getList = () => {
    setIsLoading(true);
    const { page, ...rest } = datatableParams;
    SupplierApi.getDataTable(
      {
        limit,
        page,
      },
      rest
    )
      .then((res) => {
        setTotalPage(res.data.last_page);
        setData(res.data.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  const afterSubmit = () => {
    toggleModal();
    getList();
  };

  useEffect(() => {
    if (!data.length) {
      getList();
    }
  }, []);

  useEffect(() => {
    getList();
  }, [datatableParams]);

  const toCreate = () => {
    setId(undefined);
    toggleModal();
  };

  const toEdit = (item: ISupplier) => {
    setId(item.id);
    toggleModal();
  };

  const remove = (item: ISupplier) => {
    setIsLoading(true);
    SupplierApi.remove(item.id)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Supplier Removed!',
          icon: <Trash />,
        });
        getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDeletePrompt = (data: any) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(data),
    });
  };

  const onUpdateActive = (data: ISupplier) => {
    setIsLoading(true);
    data.active = !data.active;
    SupplierApi.create(data)
      .then(() => {
        showNotification({
          message: 'Process Successfully!',
          title: 'Supplier Updated!',
          icon: <Trash />,
        });
        getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (value: any) => {
    setDatatableParams(value);
  };

  const columns = [
    {
      title: 'Active',
      accessor: 'active',
      customRender: (item: any) => {
        return <Switch color='green' checked={item.active} onChange={() => onUpdateActive(item)} />;
      },
    },
    {
      title: 'Name',
      accessor: 'name',
      filter: {
        name: 'name',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Contact Person',
      accessor: 'contact_person',
      filter: {
        name: 'contact_person',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Phone',
      accessor: 'phone',
      filter: {
        name: 'phone',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Address',
      accessor: 'address',
      filter: {
        name: 'address',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Email',
      accessor: 'email',
      filter: {
        name: 'email',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Code',
      accessor: 'code',
      filter: {
        name: 'code',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Fax',
      accessor: 'fax',
      filter: {
        name: 'fax',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: ISupplier) => {
        return (
          <Group spacing={'xs'}>
            <ActionIcon variant='light' color='blue[4]' onClick={() => toEdit(item)}>
              <Pencil size={16} />
            </ActionIcon>
            <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
              <Trash size={16} />
            </ActionIcon>
          </Group>
        );
      },
    },
  ];

  return (
    <InnerLayout title='Supplier'>
      <OurBreadcrumbs crumbs={links} />
      <ListToolbar>
        <Group>
          <Button size='sm' leftIcon={<Plus size={16} />} onClick={toCreate}>
            Create Supplier
          </Button>
        </Group>
      </ListToolbar>
      <OurTable
        isLoading={isLoading}
        page={datatableParams.page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={columns}
        data={data}
      />
      <Modal opened={createModalOpened} onClose={toggleModal} title={id ? 'Update Supplier' : 'Create Supplier'}>
        <SupplierForm afterSubmit={afterSubmit} id={id} />
      </Modal>
    </InnerLayout>
  );
};

export default SupplierList;
