import { Box, NativeSelect, Grid, Input, Button, Space, Group, ActionIcon } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { Check, Search } from 'tabler-icons-react';
import CustomerApi from '../../../apis/CustomerApi';
import OurTable from '../../../components/OurTable';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IItem } from '../../../interfaces/requests/IItemRequest';

const ItemForm = (props: any) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPage, setTotalPage] = useState(0);
    const [searchBySelected, setSearchBySelected] = useState('customer_code');
    const [searchByValue, setSearchByValue] = useState('');

    const [searchCustomerList, setSearchCustomerList] = useState<any>([]);

    const onSubmit = (values: any) => {
        setIsSubmitting(true);
    };

    const handleChangePage = (value: any) => {
        setPage(value);
    };

    const columns = [
        {
            title: 'Customer ID',
            accessor: 'id',
        },
        {
            title: 'Code',
            accessor: 'code',
        },
        {
            title: 'Customer Name',
            accessor: 'name',
        },
        {
            title: 'Contact Person',
            accessor: 'contact_person',
        },
        {
            title: 'Address',
            accessor: 'address',
        },
        {
            title: 'Action',
            accessor: '',
            customRender: (item: IItem) => {
                return (
                    <Group spacing={'xs'}>
                        <ActionIcon variant='light' color='blue[4]' onClick={() => props.onCustomerSelected(item)}>
                            <Check size={16} />
                        </ActionIcon>
                    </Group>
                );
            },
        },
    ];

    const onSearchClicked = () => {
        setIsSubmitting(true);

        let body = {
            [searchBySelected]: searchByValue,
        };

        CustomerApi.search(body)
            .then((res) => {
                setSearchCustomerList(res.data);
            })
            .catch((err) => {
                let error = ErrorHandler(err);
                showNotification({ message: error.message, title: 'Error!', color: 'red' });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <Box>
            <Grid>
                <Grid.Col span={3}>
                    <NativeSelect
                        mt='sm'
                        label='Search By'
                        data={[
                            {
                                label: 'ID Customer',
                                value: 'customer_code',
                            },
                            {
                                label: 'Customer Name',
                                value: 'customer_name',
                            },
                            {
                                label: 'Contact Person',
                                value: 'contact_person',
                            },
                            {
                                label: 'Address',
                                value: 'address',
                            },
                        ]}
                        onChange={(data) => setSearchBySelected(data.target.value)}
                    />
                </Grid.Col>

                <Grid.Col span={7}>
                    <Input
                        type='text'
                        mt='40px'
                        value={searchByValue}
                        onChange={(event: any) => {
                            setSearchByValue(event.target.value);
                        }}
                    />
                </Grid.Col>

                <Grid.Col span={2}>
                    <Button size='sm' mt='40px' leftIcon={<Search size={16} />} onClick={onSearchClicked}>
                        Search
                    </Button>
                </Grid.Col>
            </Grid>

            <Space h={12} />

            <OurTable
                isLoading={isLoading}
                page={page}
                handleChangePage={handleChangePage}
                totalPage={totalPage}
                columns={columns}
                data={searchCustomerList}
            />
        </Box>
    );
};

export default ItemForm;
