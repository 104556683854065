import { IListToolbarProps } from '../interfaces/SidebarInterface';

const ListToolbar = ({ children }: IListToolbarProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '12px',
      }}
    >
      {children}
    </div>
  );
};

export default ListToolbar;
