import { SelectItem } from '@mantine/core';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import _fetch from '../helpers/_fetch';
import {
  IPostUnitRequest,
  IUnit,
  IUnitDataTableRequest,
  IUnitDataTableResponse,
} from '../interfaces/requests/IUnitRequest';

const url = '/unit';

const UnitApi = {
  getDataTable: (
    body: IUnitDataTableRequest = {
      page: 1,
      limit: 10,
    },
    filter: any
  ): Promise<AxiosResponse<IUnitDataTableResponse>> => {
    let extraUrl = qs.stringify(filter);
    return _fetch<IUnitDataTableResponse>(
      `${url}/dt?page=${body.page}&limit=${body.limit}&${extraUrl ? extraUrl : ''}`,
      {
        method: 'POST',
      }
    );
  },
  create: (body: IUnit): Promise<AxiosResponse<IPostUnitRequest>> => {
    return _fetch<IPostUnitRequest>(`${url}`, {
      method: 'POST',
      body: body,
    });
  },
  remove: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`, {
      method: 'delete',
    });
  },
  getById: (id?: number): Promise<AxiosResponse<IUnit>> => {
    return _fetch<IUnit>(`${url}/${id}`);
  },
  getDataSource: (): Promise<AxiosResponse<SelectItem[]>> => {
    return _fetch<SelectItem[]>(`${url}/datasource`, { method: 'POST' });
  },
};

export default UnitApi;
