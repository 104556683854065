import { SelectItem } from '@mantine/core';
import { AxiosResponse } from 'axios';
import _fetch from '../helpers/_fetch';
import {
  ISalesInvoice,
  IPostSalesInvoiceRequest,
  ISalesInvoiceDataTableRequest,
  ISalesInvoiceDataTableResponse,
} from '../interfaces/requests/ISalesInvoiceRequest';

const url = '/sales-invoice';

const SalesInvoiceApi = {
  getDataTable: (
    body: ISalesInvoiceDataTableRequest = {
      page: 1,
      limit: 10,
    }
  ): Promise<AxiosResponse<ISalesInvoiceDataTableResponse>> => {
    return _fetch<ISalesInvoiceDataTableResponse>(`${url}/dt?page=${body.page}&limit=${body.limit}`, {
      method: 'POST',
    });
  },
  create: (body: any): Promise<AxiosResponse<IPostSalesInvoiceRequest>> => {
    return _fetch<IPostSalesInvoiceRequest>(`${url}`, {
      method: 'POST',
      body,
    });
  },
  update: (body: any): Promise<AxiosResponse<IPostSalesInvoiceRequest>> => {
    return _fetch<IPostSalesInvoiceRequest>(`${url}`, {
      method: 'PUT',
      body,
    });
  },
  remove: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`, {
      method: 'delete',
    });
  },
  getById: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`);
  },
  //   getDataSource: (): Promise<AxiosResponse<SelectItem[]>> => {
  //     return _fetch<SelectItem[]>(`${url}/datasource`, {
  //       method: 'POST',
  //     });
  //   },
  //   search: (body: any): Promise<AxiosResponse<any>> => {
  //     return _fetch<IPostSupplierRequest>(`${url}/search`, {
  //       method: 'POST',
  //       body,
  //     });
  //   },
};

export default SalesInvoiceApi;
