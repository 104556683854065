import { BuildingWarehouse, Database, Gauge, ShoppingCart, ShoppingCartPlus, Report } from 'tabler-icons-react'
import { ISidebarMenu } from '../interfaces/SidebarInterface'

const sidebarLinks: ISidebarMenu[] = [
 { label: 'Dashboard', icon: Gauge },
 {
  label: 'Store',
  icon: BuildingWarehouse,
  children: [
   {
    label: 'Master Item',
    link: '/item',
   },
   // {
   //     label: 'Stock In',
   //     link: '/stock-in',
   // },
   // {
   //     label: 'Stock Out',
   //     link: '/stock-out',
   // },
   // {
   //     label: 'Stock Out Return',
   //     link: '/stock-out-return',
   // },
  ],
 },
 {
  label: 'Purchase',
  icon: ShoppingCart,
  children: [
   { label: 'Master Supplier', link: '/supplier' },
   { label: 'Purchase Order', link: '/purchase-order' },
   //   { label: 'Purchase Invoice', link: '/purchase-invoice' },
   //   { label: 'Purchase Return', link: '/purchase-return' },
   //   { label: 'Purchase Report', link: '/purchase-report' },
  ],
 },
 {
  label: 'Sales Order',
  icon: ShoppingCartPlus,
  children: [
   { label: 'Master Customer', link: '/customer' },
   { label: 'Sales Quotation', link: '/quotation' },
   { label: 'Customer Order', link: '/customer-order' },
   { label: 'Sales Invoice', link: '/sales-invoice' },
   //   { label: 'Sales Return', link: '/sales-return' },
   { label: 'Sales Report', link: '/sales-report' },
  ],
 },
 {
  label: 'Masters',
  icon: Database,
  children: [
   { label: 'Currency', link: '/currency' },
   { label: 'Unit', link: '/unit' },
   { label: 'Bank', link: '/bank' },
   { label: 'Collector', link: '/collector' },
   { label: 'Marketing', link: '/marketing' },
   { label: 'Role', link: '/role' },
   { label: 'Users', link: '/users' },
  ],
 },
 //  {
 //   label: 'Report',
 //   icon: Report,
 //   children: [
 //    { label: 'Account Payable', link: '/account-payable-report' },
 //    { label: 'Account Receivable', link: '/account-receivable-report' },
 //    { label: 'Account Report', link: '/account-report' },
 //   ],
 //  },
 //   {
 //     label: 'AP & AR',
 //     icon: Report,
 //     children: [
 //       { label: 'Account Payable', link: '/account-payable' },
 //       { label: 'Account Receivable', link: '/account-receivable' },
 //       { label: 'Account Report', link: '/account-report' },
 //     ],
 //   },
]

export default sidebarLinks
