const ErrorHandler = (error: any): { message: string } => {
  let message = 'Oops! Something happen, please contact administrator for further information';

  if (error) {
    if (error.response) {
      if (error.response.message) {
        message = error.response.message;
      }

      if (error.response.data) {
        if (error.response.data.message) {
          message = error.response.data.message;
        }
      }
    }
  }

  return {
    message,
  };
};

export default ErrorHandler;
