import {
  ActionIcon,
  Button,
  Group,
  Text,
  Tooltip,
  Checkbox,
  Popover,
  Grid,
  NativeSelect,
  SelectItem,
  Space,
  Input,
} from '@mantine/core';
import { Pencil, Trash, History, Filter } from 'tabler-icons-react';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import InnerLayout from '../../InnerLayout';
import OurTable from '../../../components/OurTable';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import QuotationApi from '../../../apis/QuotationApi';
import { ISupplier } from '../../../interfaces/requests/ISupplierRequest';
import { useModals } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CurrencyApi from '../../../apis/CurrencyApi';
import { ISalesQuotation } from '../../../interfaces/requests/ISalesQuotation';

const links = [
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
];

const AccountPayableList = () => {
  const [data, setData] = useState<ISalesQuotation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [isViewInvoiceOverdue, setIsViewInvoiceOverdue] = useState(false);
  const [openFilterPopover, setOpenFilterPopover] = useState<boolean>(false);
  const [currencyList, setCurrencyList] = useState<SelectItem[]>([]);

  const [searchByDataSource] = useState<SelectItem[]>([
    {
      value: 'supplierName',
      label: 'Supplier Name',
    },
  ]);
  const [searchBy, selectedSearchBy] = useState<SelectItem>({
    value: 'docNo',
    label: 'Doc No',
  });

  const navigate = useNavigate();

  const modals = useModals();

  const getList = () => {
    setIsLoading(true);
    QuotationApi.getDataTable({
      limit: 10,
      page: 1,
    })
      .then((res) => {
        setTotalPage(res.data.last_page);
        setData(res.data.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  useEffect(() => {
    if (!data.length) {
      // getList();

      getCurrencyList();
    }
  }, []);

  const getCurrencyList = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrencyList(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onInputPaymentClicked = () => {
    navigate('create');
  };

  const toEdit = (accountPayableId: number) => {
    navigate(`/account-payable/update/${accountPayableId}`);
  };

  const remove = (item: ISupplier) => {
    setIsLoading(true);
    QuotationApi.remove(item.id)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Account Payable Removed!',
          icon: <Trash />,
        });
        // getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDeletePrompt = (data: any) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(data),
    });
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  const columns = [
    {
      title: 'ID Supp',
      accessor: 'supplier_id',
    },
    {
      title: 'Supplier Name',
      accessor: 'supplier_name',
    },
    {
      title: 'Curr',
      accessor: 'currency',
    },
    {
      title: 'Total Amount',
      accessor: 'total',
    },
    {
      title: 'Amount Paid',
      accessor: 'amount_paid',
    },
    {
      title: 'End Balance',
      accessor: 'end_balance',
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: ISupplier) => {
        return (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Tooltip label='View History'>
              <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
                <History size={16} />
              </ActionIcon>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const toggleViewInvoiceOverdue = (event: any) => {
    setIsViewInvoiceOverdue((prevState) => !prevState);
  };

  const toggleFilterPopover = () => {
    setOpenFilterPopover(!openFilterPopover);
  };

  if (isLoading) {
    return <div>Loading..</div>;
  }

  return (
    <InnerLayout title='Account Payable'>
      <OurBreadcrumbs crumbs={links} />

      <Space h={24} />

      <ListToolbar>
        <Group style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Popover
            width={700}
            position='right'
            withArrow
            shadow='md'
            opened={openFilterPopover}
            onClose={toggleFilterPopover}
          >
            <Popover.Target>
              <Button variant='outline' size='sm' onClick={toggleFilterPopover} leftIcon={<Filter size={16} />}>
                Filter
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <>
                <Grid>
                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Search By'
                      data={searchByDataSource}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={4} style={{ marginTop: '13px' }}>
                    <span
                      style={{
                        color: '#212529',
                        fontWeight: 500,
                        fontSize: '14px',
                        marginBottom: '4px',
                      }}
                    >
                      Keyword
                    </span>
                    <Input style={{ marginTop: '4px' }} name='keyword' />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Curr'
                      data={currencyList}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col mt='xs'>
                    <Button size='sm' style={{ float: 'right' }}>
                      Reset
                    </Button>
                  </Grid.Col>
                </Grid>
              </>
            </Popover.Dropdown>
          </Popover>

          <div style={{ display: 'flex' }}>
            <Checkbox label='View Invoice Overdue' checked={isViewInvoiceOverdue} onChange={toggleViewInvoiceOverdue} />

            <Space w={18} />

            <Button size='sm' style={{ float: 'right' }} onClick={onInputPaymentClicked}>
              Input Payment
            </Button>
          </div>
        </Group>
      </ListToolbar>
      <OurTable
        isLoading={isLoading}
        page={page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={columns}
        data={[]}
      />

      <Space h={12} />

      <Grid justify='end'>
        <Grid.Col span={9}>
          <Grid>
            <Grid.Col span={2}>
              <span style={{ float: 'right' }}>Total Amount</span>
            </Grid.Col>
            <Grid.Col span={2}>
              <Input type='number' />
            </Grid.Col>
            <Grid.Col span={2}>
              <span style={{ float: 'right' }}>Total Paid</span>
            </Grid.Col>
            <Grid.Col span={2}>
              <Input type='number' />
            </Grid.Col>
            <Grid.Col span={2}>
              <span style={{ float: 'right' }}>Total End Balance</span>
            </Grid.Col>
            <Grid.Col span={2}>
              <Input type='number' />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </InnerLayout>
  );
};

export default AccountPayableList;
