import {
  Box,
  Button,
  Group,
  Modal,
  SelectItem,
  Space,
  Grid,
  Input,
  Checkbox,
  Tooltip,
  ActionIcon,
  Text,
  NumberInput,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { Check, DeviceFloppy, Pencil, Plus, Search, Trash } from 'tabler-icons-react';
import CurrencyApi from '../../../apis/CurrencyApi';
import CustomerApi from '../../../apis/CustomerApi';
import EnumerationApi from '../../../apis/EnumerationApi';
import SalesInvoiceApi from '../../../apis/SalesInvoiceApi';
import DynamicField from '../../../components/DynamicField';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import OurTable from '../../../components/OurTable';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import InnerLayout from '../../InnerLayout';
import ItemForm from './itemForm';
import SearchProjectModal from './searchProjectModal';
import { object, string, number, date } from 'yup';
import { IsARequiredField as requiredField } from '../../../helpers/_isARequiredField';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useModals } from '@mantine/modals';
import { v4 as uuidV4 } from 'uuid';
import { IItem } from '../../../interfaces/requests/IItemRequest';
import TwoDecimalPlaces from '../../../helpers/_twoDecimalPlaces';

const SalesInvoiceForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<SelectItem[]>([]);
  const [currencies, setCurrencies] = useState<SelectItem[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [paymentData, setPaymentData] = useState<SelectItem[]>([]);
  const [useTax, setUseTax] = useState(false);

  const modals = useModals();

  const [salesInvoiceLines, setSalesInvoiceLines] = useState<
    {
      id: string;
      description?: string;
      project_name: string;
      quantity: number;
      price: number;
      discount: number;
      unit_id: number;
      unit: {
        value: number;
        label: string;
      };
    }[]
  >([]);

  const [showItemForm, setShowItemForm] = useState<boolean>(false);
  const [showSearchProjectModal, setShowSearchProjectModal] = useState<boolean>(false);
  const [salesInvoiceLineToBeEdited, setSalesInvoiceLineToBeEdited] = useState<any>({});
  const [salesOrderSelected, setSalesOrderSelected] = useState({
    id: null,
    project_no: '',
  });
  const [defaultCurrency, setDefaultCurrency] = useState<any>({ prefix: null, rate: null });

  const links = [
    {
      title: 'Dashboard',
      link: 'dashboard',
    },
    {
      title: 'Sales Invoice',
      link: '/sales-invoice',
    },
    {
      title: 'Create',
    },
  ];

  const columns = [
    {
      title: 'Project Detail Desc',
      accessor: 'project_name',
    },
    {
      title: 'Qty',
      accessor: 'quantity',
    },
    {
      title: 'Unit',
      accessor: 'unit',
      customRender: (item: any) => {
        return item.unit?.label;
      },
    },
    {
      title: 'Price',
      accessor: 'price',
      customRender: (item: any) => {
        return TwoDecimalPlaces(item.price);
      },
    },
    {
      title: 'Amount',
      accessor: 'amount',
      customRender: (item: any) => {
        return TwoDecimalPlaces(item.quantity * item.price);
      },
    },
    {
      title: 'Disc',
      accessor: 'discount',
      customRender: (item: any) => {
        return TwoDecimalPlaces(item.discount);
      },
    },
    {
      title: 'Total Amount',
      accessor: 'total',
      customRender: (item: any) => {
        return TwoDecimalPlaces(item.quantity * item.price - item.discount);
      },
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: IItem) => {
        return (
          <Group spacing={'xs'}>
            <Tooltip label='Edit' withArrow>
              <ActionIcon variant='light' color='blue[4]' onClick={() => onEditSalesInvoiceLineClicked(item)}>
                <Pencil size={16} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label='Delete' withArrow>
              <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
                <Trash size={16} />
              </ActionIcon>
            </Tooltip>
          </Group>
        );
      },
    },
  ];

  const remove = (item: IItem) => {
    let newSalesInvoiceLines = [...salesInvoiceLines].filter((x) => x.id.toString() != item.id?.toString());
    setSalesInvoiceLines(newSalesInvoiceLines);
  };

  const onDeletePrompt = (data: IItem) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        remove(data);
      },
    });
  };

  const validationSchema = object().shape({
    customer_id: string().required(requiredField('Customer')).nullable(),
    invoice_no: string().required(requiredField('Invoice No')).nullable(),
    date: date().required(requiredField('Date')).nullable(),
    // sales_order_id: string().required(requiredField('Project No')).nullable(),
    po_no: string().required(requiredField('PO No')).nullable(),
    do_no: string().required(requiredField('DO No')).nullable(),
    currency_id: string().required(requiredField('Currency')).nullable(),
    payment_id: string().required(requiredField('Payment')).nullable(),
    terms: number().required(requiredField('Terms')).nullable(),
  });

  const form = useForm({
    initialValues: {
      customer_id: null,
      invoice_no: null,
      date: new Date(),
      po_no: null,
      do_no: null,
      currency_id: null,
      payment_id: null,
      terms: null,
      discount: null,
      tax: 0,
    },
    validate: yupResolver(validationSchema),
  });

  const getCustomer = () => {
    setIsLoading(true);
    CustomerApi.getDataSource()
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCurrency = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrencies(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPaymentEnum = () => {
    setIsLoading(true);
    EnumerationApi.getDataSource('sales_invoice_payment')
      .then((res) => {
        let result = res.data.map((x) => {
          return {
            ...x,
            label: x.display_name,
          };
        });

        setPaymentData(result);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleItemForm = () => {
    setShowItemForm(!showItemForm);
    if (showItemForm) {
      setSalesInvoiceLineToBeEdited({});
    }
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  useEffect(() => {
    getCurrency();
    getCustomer();
    getPaymentEnum();
    getDefaultCurrency();

    if (params.salesInvoiceId) {
      getSalesInvoiceById();
    }
  }, []);

  const getDefaultCurrency = () => {
    setIsLoading(true);

    CurrencyApi.default()
      .then((res) => {
        setDefaultCurrency(res.data);
      })
      .catch((err: any) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSalesInvoiceById = () => {
    setIsLoading(true);

    SalesInvoiceApi.getById(Number(params.salesInvoiceId)).then((res) => {
      const {
        customer_id,
        invoice_no,
        date,
        po_no,
        do_no,
        currency,
        currency_id,
        payment,
        terms,
        discount,
        tax,
        lines,
        sales_order,
      } = res.data;

      let taxValue = Number(tax) * 100;

      setUseTax(taxValue !== 0);
      setSalesOrderSelected(sales_order);

      // disni harus return Default Currency Rate Snapshot
      // setDefaultCurrency({
      //   prefix: IDR,
      //   rate: 1
      // });

      form.setValues({
        customer_id,
        invoice_no,
        date: new Date(date),
        po_no,
        do_no,
        currency_id,
        payment_id: payment,
        terms,
        discount,
        tax: taxValue,
      });

      let newSalesInvoiceLines = lines.map((x: any) => {
        return {
          id: x.id,
          description: x.description,
          project_name: x.project_name,
          quantity: x.quantity,
          price: x.price,
          discount: x.discount,
          unit_id: x.unit_id,
          unit: {
            value: x.unit.id,
            label: x.unit.name,
          },
        };
      });

      setSalesInvoiceLines(newSalesInvoiceLines);
    });
  };

  const toggleSearchProjectModal = () => {
    setShowSearchProjectModal(!showSearchProjectModal);
  };

  const params = useParams();
  const navigate = useNavigate();

  const formOnSubmit = (values: any) => {
    console.log(values);
    setIsLoading(true);

    const { date, customer_id, po_no, do_no, invoice_no, payment_id, currency_id, discount, terms, tax } = values;

    let body = {
      customer_id,
      id: params.salesInvoiceId ? params.salesInvoiceId : null,
      date: moment(date).format('YYYY-MM-DD'),
      po_no,
      do_no,
      invoice_no,
      payment: Number(payment_id),
      currency_id,
      discount,
      sales_order_id: salesOrderSelected.id,
      terms,
      tax: useTax ? Number(tax) : 0,
      lines: [...salesInvoiceLines].map((x) => {
        return {
          description: '-',
          project_name: x.project_name,
          unit_id: x.unit?.value,
          quantity: x.quantity,
          price: x.price,
          discount: x.discount,
        };
      }),
    };

    let apiToCall: any;

    if (params.salesQuotationId) {
      apiToCall = SalesInvoiceApi.update(body);
    } else {
      apiToCall = SalesInvoiceApi.create(body);
    }

    apiToCall
      .then(() => {
        showNotification({
          message: 'Process Successfully!',
          title: `Sales Quotation ${params.salesQuotationId ? 'Updated' : 'Created'}!`,
          icon: <Check />,
          color: 'green',
        });

        navigate('/sales-invoice');
      })
      .catch((err: any) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitSalesInvoiceItem = (values: any) => {
    const { id, project_name, quantity, price, discount, unit_id, unit } = values;

    let newSalesInvoiceLines = [];

    if (id) {
      newSalesInvoiceLines = [...salesInvoiceLines];

      let index = newSalesInvoiceLines.findIndex((x) => x.id === id);

      newSalesInvoiceLines[index] = {
        id,
        project_name,
        quantity,
        price,
        discount,
        unit_id,
        unit,
      };
    } else {
      newSalesInvoiceLines = [
        ...salesInvoiceLines,
        {
          id: uuidV4(),
          project_name,
          quantity,
          price,
          discount,
          unit_id,
          unit,
        },
      ];
    }

    setSalesInvoiceLines(newSalesInvoiceLines);
    setSalesInvoiceLineToBeEdited({});
    setShowItemForm(false);
  };

  const onEditSalesInvoiceLineClicked = (item: any) => {
    setSalesInvoiceLineToBeEdited(item);
    toggleItemForm();
  };

  let total =
    salesInvoiceLines.length &&
    salesInvoiceLines.reduce((accumulator, current) => {
      let price = current.quantity * current.price - current.discount;

      return Number(accumulator + price);
    }, 0);

  let discount = form.values.discount ? form.values.discount : 0;

  const onProjectSelected = (project: any) => {
    let newLines = project.lines.map((x: any) => {
      return {
        id: x.id.toString,
        project_name: x.project_name,
        quantity: Number(x.quantity),
        price: 0,
        discount: 0,
        unit_id: x.unit_id,
        unit: {
          value: Number(x.unit.id),
          label: x.unit.name,
        },
      };
    });

    setSalesOrderSelected(project);
    setSalesInvoiceLines(newLines);
    toggleSearchProjectModal();
  };

  return (
    <>
      <InnerLayout title='Sales Invoice'>
        <OurBreadcrumbs crumbs={links} />
        <form onSubmit={form.onSubmit(formOnSubmit)}>
          <ListToolbar>
            <Group>
              <Button type='submit' size='sm' leftIcon={<DeviceFloppy size={16} />}>
                Submit
              </Button>
            </Group>
          </ListToolbar>

          <Grid>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'customer_id',
                  type: IFormGeneratorFieldType.Dropdown,
                  datasource: customers,
                  label: 'Customer',
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'invoice_no',
                  type: IFormGeneratorFieldType.Text,
                  label: 'Invoice No',
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'date',
                  type: IFormGeneratorFieldType.Date,
                  label: 'Date',
                }}
                form={form}
              />
            </Grid.Col>

            <Grid.Col span={3}>
              <Grid>
                <Grid.Col span={9}>
                  <TextInput label='Project No.' value={salesOrderSelected.project_no} name='project_no' mt='sm' />
                </Grid.Col>
                <Grid.Col span={2} className='flex items-end'>
                  <Button size='sm' leftIcon={<Search size={16} />} onClick={toggleSearchProjectModal} />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'po_no',
                  type: IFormGeneratorFieldType.Text,
                  label: 'PO No',
                }}
                form={form}
              />
            </Grid.Col>

            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'do_no',
                  type: IFormGeneratorFieldType.Text,
                  datasource: currencies,
                  label: 'DO No',
                }}
                form={form}
              />
            </Grid.Col>

            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'currency_id',
                  type: IFormGeneratorFieldType.Dropdown,
                  datasource: currencies,
                  label: 'Currency',
                }}
                form={form}
              />
            </Grid.Col>

            <Grid.Col span={3}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    mt='sm'
                    type='text'
                    name='default_currency_prefix'
                    value={defaultCurrency.prefix}
                    label='Def. Currency'
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    mt='sm'
                    label='Def. Currency Rate'
                    type='text'
                    name='default_currency_rate'
                    value={defaultCurrency.rate}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'payment_id',
                  type: IFormGeneratorFieldType.Dropdown,
                  datasource: paymentData,
                  label: 'Payment',
                }}
                form={form}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <DynamicField
                field={{
                  name: 'terms',
                  type: IFormGeneratorFieldType.Number,
                  label: 'Term (days)',
                }}
                form={form}
              />
            </Grid.Col>
          </Grid>

          <Space h={24} />
          <ListToolbar>
            <Group>
              <Button size='sm' leftIcon={<Plus size={16} />} onClick={toggleItemForm}>
                Add Items
              </Button>
            </Group>
          </ListToolbar>
          <Box>
            <OurTable
              isLoading={isLoading}
              page={page}
              handleChangePage={handleChangePage}
              totalPage={totalPage}
              columns={columns}
              data={salesInvoiceLines}
            />
          </Box>

          <Space h={12} />

          <Grid justify='end'>
            <Grid.Col span={3}>
              <Grid>
                <Grid.Col span={6}>
                  <span style={{ float: 'right' }}>Total S$</span>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Input disabled type='number' value={TwoDecimalPlaces(total)} />
                </Grid.Col>
              </Grid>
            </Grid.Col>

            <Grid.Col span={3}>
              <Grid>
                <Grid.Col span={6}>
                  <span style={{ float: 'right' }}>Disc S$</span>
                </Grid.Col>
                <Grid.Col span={6}>
                  <NumberInput min={0} {...form.getInputProps('discount')} />
                </Grid.Col>
              </Grid>
            </Grid.Col>

            <Grid.Col span={3}>
              <Grid>
                <Grid.Col span={6}>
                  <span style={{ float: 'right' }}>Grand Total S$</span>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Input disabled type='number' value={TwoDecimalPlaces(total - discount)} />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>

          <Grid justify='end'>
            <Grid.Col span={3}></Grid.Col>
            <Grid.Col span={3}>
              <Grid>
                <Grid.Col span={6}></Grid.Col>
                <Grid.Col span={6}>
                  <div style={{ display: 'flex', float: 'right', gap: '10px' }}>
                    <Checkbox
                      checked={useTax}
                      onChange={(event) => {
                        setUseTax(event.target.checked);
                      }}
                    />
                    Status Tax
                  </div>
                </Grid.Col>
              </Grid>
            </Grid.Col>

            <Grid.Col span={3}>
              <Grid>
                <Grid.Col span={6}>
                  <Grid>
                    <Grid.Col span={10}>
                      <Input
                        type='number'
                        disabled={!useTax}
                        min='0'
                        {...form.getInputProps('tax')}
                        style={{ textAlign: 'right' }}
                      />
                    </Grid.Col>
                    <Grid.Col span={2}>%</Grid.Col>
                  </Grid>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Input type='number' min='0' step='0.5' />
                </Grid.Col>
              </Grid>
            </Grid.Col>

            <Grid.Col span={3}>
              <Grid>
                <Grid.Col span={6}>
                  <span style={{ float: 'right' }}>HM Curr Total S$</span>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Input
                    disabled
                    type='number'
                    style={{ textAlign: 'right' }}
                    value={TwoDecimalPlaces((total - discount) / defaultCurrency.rate)}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>

          <Modal
            title={`${salesInvoiceLineToBeEdited.id ? 'Update' : 'Add'} Sales Invoice Item`}
            opened={showItemForm}
            onClose={() => toggleItemForm()}
          >
            <ItemForm
              afterSubmit={(values: any) => {
                submitSalesInvoiceItem(values);
              }}
              id={salesInvoiceLineToBeEdited.id}
              dataToBeEdited={salesInvoiceLineToBeEdited}
            />
          </Modal>

          <Modal size='xl' title='Search Project' opened={showSearchProjectModal} onClose={toggleSearchProjectModal}>
            <SearchProjectModal onProjectSelected={onProjectSelected} />
          </Modal>
        </form>
      </InnerLayout>
    </>
  );
};

export default SalesInvoiceForm;
