import { SelectItem } from '@mantine/core';
import { AxiosResponse } from 'axios';
import _fetch from '../helpers/_fetch';

const url = '/enum';

const EnumerationApi = {
    getDataSource: (enumName: string): Promise<AxiosResponse<any[]>> => {
        return _fetch<SelectItem[]>(`${url}/${enumName}`, {
            method: 'GET',
        });
    },
};

export default EnumerationApi;
