import { ActionIcon, Button, Group, Modal, SelectItem, Switch, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { Pencil, Plus, Trash } from 'tabler-icons-react';
import ItemApi from '../../apis/ItemApi';
import UnitApi from '../../apis/UnitApi';
import ListToolbar from '../../components/ListToolbar';
import OurBreadcrumbs from '../../components/OurBreadcrumbs';
import OurTable, { IOurColumnFilterType, IOurTableColumnProps } from '../../components/OurTable';
import ErrorHandler from '../../helpers/_errorHandler';
import { IItem } from '../../interfaces/requests/IItemRequest';
import InnerLayout from '../InnerLayout';
import ItemForm from './form';

const links = [
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
  {
    title: 'Master Item',
    link: 'item',
  },
];

const ItemList = () => {
  const [items, setItems] = useState<IItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>(0);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [datatableParams, setDatatableParams] = useState<any>({
    page: 1,
  });

  const [units, setUnits] = useState<SelectItem[]>([]);

  const modals = useModals();

  const columns: IOurTableColumnProps[] = [
    {
      title: 'Active?',
      accessor: 'active',
      customRender: (item: any) => {
        return <Switch color='green' checked={item.active} />;
      },
    },
    {
      title: 'Item ID',
      accessor: 'code',
      withFilter: true,
      filter: {
        name: 'code',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Name',
      accessor: 'name',
      withFilter: true,
      filter: {
        name: 'name',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Size',
      accessor: 'size',
      withFilter: true,
      filter: {
        name: 'size',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Unit',
      accessor: 'unit',
      customRender: (item: IItem) => {
        return item.unit?.name;
      },
      withFilter: true,
      filter: {
        name: 'unit_id',
        type: IOurColumnFilterType.Dropdown,
        data: [],
      },
    },
    {
      title: 'Weight',
      accessor: 'weight',
      withFilter: true,
      filter: {
        name: 'weight',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Length',
      accessor: 'length',
      withFilter: true,
      filter: {
        name: 'length',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Dimensions',
      accessor: 'dimensions',
      withFilter: true,
      filter: {
        name: 'dimensions',
        type: IOurColumnFilterType.Text,
      },
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: IItem) => {
        return (
          <Group spacing={'xs'}>
            <ActionIcon variant='light' color='blue[4]' onClick={() => toEdit(item)}>
              <Pencil size={16} />
            </ActionIcon>
            <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
              <Trash size={16} />
            </ActionIcon>
          </Group>
        );
      },
    },
  ];

  const getList = () => {
    setIsLoading(true);
    const { page, ...rest } = datatableParams;
    ItemApi.getDataTable(
      {
        limit,
        page,
      },
      rest
    )
      .then((res) => {
        setTotalPage(res.data.last_page);
        setItems(res.data.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getList();
    getUnitDatasource();
  }, []);

  useEffect(() => {
    getList();
  }, [datatableParams]);

  const toggleModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  const toEdit = (item: IItem) => {
    setId(item.id);
    toggleModal();
  };

  const toCreate = () => {
    setId(undefined);
    toggleModal();
  };

  const afterSubmit = () => {
    toggleModal();
    getList();
  };

  const handleChangePage = (value: any) => {
    setDatatableParams(value);
  };

  const onDeletePrompt = (data: IItem) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(data),
    });
  };

  const remove = (item: IItem) => {
    setIsLoading(true);
    ItemApi.remove(item.id)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Collector Removed!',
          icon: <Trash />,
        });
        getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUnitDatasource = () => {
    setIsLoading(true);
    UnitApi.getDataSource()
      .then((res) => {
        setUnits(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getColumns = () => {
    let cols = [...columns];
    var unitIndex = cols.findIndex((x) => x.accessor == 'unit');

    if (cols[unitIndex] != undefined) {
      if (cols[unitIndex].filter != undefined) {
        cols[unitIndex].filter!.data = units;
      }
    }

    return cols;
  };

  return (
    <InnerLayout title='Master Item'>
      <OurBreadcrumbs crumbs={links} />

      <ListToolbar>
        <Group>
          <Button size='sm' leftIcon={<Plus size={16} />} onClick={toCreate}>
            Create Item
          </Button>
        </Group>
      </ListToolbar>
      <OurTable
        isLoading={isLoading}
        page={datatableParams.page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={getColumns()}
        data={items}
      />
      <Modal opened={createModalOpened} onClose={toggleModal} title={'Create Item'}>
        <ItemForm afterSubmit={afterSubmit} id={id} />
      </Modal>
    </InnerLayout>
  );
};

export default ItemList;
