import {
  Box,
  Center,
  Group,
  Pagination,
  Skeleton,
  Space,
  Table,
  Text,
  useMantineTheme,
  LoadingOverlay,
  TextInput,
  Select,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useEffect, useState } from 'react';
import ListToolbar from './ListToolbar';
import { SortAscending, SortDescending } from 'tabler-icons-react';

interface IOurTableProps {
  columns: IOurTableColumnProps[];
  data?: any[];
  clickEvent?: Function;
  totalPage?: Number;
  page?: Number;
  handleChangePage?: Function;
  isLoading?: Boolean;
}

export interface IOurTableColumnProps {
  title: string;
  accessor: string;
  customRender?: (item: any) => React.ReactNode;
  rowOnClick?: (item: any) => void;
  withFilter?: boolean;
  filter?: IOurTableColumnFilterProps;
  sortable?: boolean;
  sort_key?: string;
}

export interface IOurTableColumnFilterProps {
  type: IOurColumnFilterType;
  name: string;
  placeholder?: string;
  data?: any[];
}

export enum IOurColumnFilterType {
  Dropdown,
  Text,
  DateRange,
}

const OurTable = (props: IOurTableProps) => {
  const theme = useMantineTheme();
  const totalPage = props.totalPage ? props.totalPage : 0;
  const page = props.page ? props.page : 1;
  const dummyArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [filtered, setFiltered] = useState<any>({
    page: 1,
  });

  const handleClick = (item: any) => {
    if (props.clickEvent) {
      props.clickEvent(item);
    }
  };

  const handleChangePage = (value: any) => {
    handleFilterOnChange('page', value);
  };

  const handleFilterOnChange = (name: string, value: string | null) => {
    let newFiltered = { ...filtered };
    newFiltered[name] = value;

    setFiltered(newFiltered);
  };

  const handleSort = (item: IOurTableColumnProps) => {
    let newFiltered = { ...filtered };
    let sortKey = item.sort_key ? item.sort_key : item.accessor;

    if (newFiltered.sort) {
      if (newFiltered.sort.column === sortKey) {
        newFiltered.sort.direction = newFiltered.sort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        newFiltered.sort.column = sortKey;
        newFiltered.sort.direction = 'asc';
      }
    } else {
      newFiltered.sort = {
        column: sortKey,
        direction: 'asc',
      };
    }

    setFiltered(newFiltered);
  };

  const handleDataRangePickerOnChange = (value: any) => {
    let start = value[0];
    let end = value[1];

    if (!end && start) {
      return;
    }

    let newValue = {
      start_date: start,
      end_date: end,
    };

    let newFiltered = { ...filtered };

    if (start && end) {
      newFiltered['start_date'] = newValue.start_date;
      newFiltered['end_date'] = newValue.end_date;
      newFiltered.page = 1;
    } else {
      newFiltered['start_date'] = null;
      newFiltered['end_date'] = null;
    }

    setFiltered(newFiltered);
  };

  useEffect(() => {
    if (props.handleChangePage) {
      props.handleChangePage(filtered);
    }
  }, [filtered]);

  const handleFilterRendering = (item: IOurTableColumnFilterProps | undefined) => {
    if (item == null || item == undefined) {
      return '';
    }

    switch (item.type) {
      case IOurColumnFilterType.Dropdown:
        return (
          <Select
            placeholder={item.placeholder ? item.placeholder : 'Select one ...'}
            data={item.data ?? []}
            onChange={(e) => handleFilterOnChange(item.name, e)}
            clearable
            searchable
          />
        );
      case IOurColumnFilterType.Text:
        return (
          <TextInput
            placeholder={item.placeholder ? item.placeholder : 'Search ...'}
            onChange={(e) => handleFilterOnChange(item.name, e.target.value)}
          />
        );
      case IOurColumnFilterType.DateRange:
        return (
          <DateRangePicker
            dropdownType='modal'
            placeholder={item.placeholder ? item.placeholder : `Please select a date range`}
            clearable
            onChange={handleDataRangePickerOnChange}
          />
        );
    }
  };

  const handleSortIcon = (item: IOurTableColumnProps) => {
    let sortKey = item.sort_key ? item.sort_key : item.accessor;

    if (filtered.sort && filtered.sort.column === sortKey && filtered.sort.direction === 'asc') {
      return <SortAscending size={12} className='text-sky-500' />;
    }

    if (filtered.sort && filtered.sort.column === sortKey && filtered.sort.direction === 'desc') {
      return <SortDescending size={12} className='text-sky-500' />;
    }

    return <SortAscending size={12} className='text-zinc-500' />;
  };

  return (
    <>
      <div className='overflow-x-scroll w-full'>
        <Table
          highlightOnHover
          style={{
            border: `1px solid ${theme.colors.gray[3]}`,
          }}
          withBorder
          withColumnBorders
        >
          <LoadingOverlay visible={props.isLoading ? true : false} />
          <thead>
            <tr>
              {props.columns.map((item, key) => (
                <th key={`table-header-${key}`} className='whitespace-nowrap'>
                  <div
                    className='flex items-center gap-2 cursor-pointer'
                    onClick={() => (item.sortable ? handleSort(item) : null)}
                  >
                    {item.title} {item.sortable ? handleSortIcon(item) : null}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ position: 'relative' }}>
            {props.columns.some((x) => x.filter != null) ? (
              <tr>
                {props.columns.map((item) => (
                  <td>{handleFilterRendering(item.filter)}</td>
                ))}
              </tr>
            ) : null}
            {props.isLoading && props.data?.length
              ? dummyArray.map(() => (
                  <tr>
                    <td colSpan={props.columns.length}>
                      <Skeleton height={24} width='100%' radius='xl' />
                    </td>
                  </tr>
                ))
              : props.data?.map((item, key) => {
                  return (
                    <tr
                      style={{ position: 'relative' }}
                      key={`table-row-${key}`}
                      onClick={(e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                        if ((e.target as HTMLTableRowElement)?.id === `click-detail`) {
                          handleClick(item);
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }}
                      className='whitespace-nowrap'
                    >
                      {props.columns.map((col, key) => {
                        return (
                          <td key={`table-cell-${key}`} id={`click-detail`}>
                            {col.customRender ? col.customRender(item) : item[col.accessor] ? item[col.accessor] : '-'}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}

            {!props.data?.length ? (
              <tr>
                <td colSpan={props.columns.length}>
                  {' '}
                  <Center>
                    <Box
                      style={{
                        height: '400px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <Text
                          component='span'
                          align='center'
                          variant='gradient'
                          gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
                          size='md'
                          weight={500}
                        >
                          No Data
                        </Text>
                      </div>
                    </Box>
                  </Center>
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </div>
      <Space h={12} />
      <ListToolbar>
        <Group>
          <Pagination
            boundaries={1}
            page={Number(page)}
            onChange={handleChangePage}
            total={Number(totalPage)}
            size={'sm'}
          />
        </Group>
      </ListToolbar>
    </>
  );
};

export default OurTable;
