import { Route, Routes } from 'react-router-dom';
import MarketingList from './list';

const MarketingMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<MarketingList />} />
      </Routes>
    </>
  );
};

export default MarketingMasterLayout;
