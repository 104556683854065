import { useEffect, useState } from 'react';
import { Box } from '@mantine/core';
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import FormGenerator from '../../../components/FormGenerator';
import ItemApi from '../../../apis/ItemApi';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import UnitApi from '../../../apis/UnitApi';

const PurchaseOrderForm = (props: { afterSubmit: Function }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<IFormGeneratorFieldProps[]>([
    {
      name: 'id',
      label: 'Item',
      required: true,
      type: IFormGeneratorFieldType.Dropdown,
      datasource: [],
      placeholder: 'Please select a item',
    },
    {
      name: 'unit_id',
      label: 'Unit',
      required: true,
      type: IFormGeneratorFieldType.Dropdown,
      datasource: [],
      placeholder: 'Please select a unit',
    },
    {
      name: 'quantity',
      label: 'Quantity',
      required: true,
      type: IFormGeneratorFieldType.Number,
      datasource: [],
    },
    {
      name: 'price',
      label: 'Price',
      required: true,
      type: IFormGeneratorFieldType.Number,
    },
  ]);

  const [initialValues, setInitialValues] = useState({
    id: null,
    quantity: 0,
    price: 0,
    unit_id: null,
  });

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    ItemApi.getById(values.id)
      .then((res) => {
        let newData = {
          ...res.data,
          quantity: values.quantity,
          price: values.price,
          unit_id: values.unit_id,
        };

        if (props.afterSubmit) {
          props.afterSubmit(newData);
        }
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const getItemList = () => {
    setIsLoading(true);
    ItemApi.getDataSource()
      .then((res) => {
        let newFields = [...fields];
        let itemField = newFields.findIndex((field) => field.name === 'id');
        if (itemField > -1) {
          console.log(res.data);
          newFields[itemField].datasource = res.data;
          setFields(newFields);
        }
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
        setIsSubmitting(false);
      });
  };

  const getUnitList = () => {
    UnitApi.getDataSource()
      .then((res) => {
        let newFields = [...fields];

        let unitField = newFields.findIndex((field) => field.name === 'unit_id');

        if (unitField > -1) {
          newFields[unitField].datasource = res.data;
          setFields(newFields);
        }
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getItemList();
    getUnitList();
  }, []);

  return (
    <Box>
      <FormGenerator fields={fields} initialValues={initialValues} onSubmit={onSubmit} loading={isSubmitting} />
    </Box>
  );
};

export default PurchaseOrderForm;
