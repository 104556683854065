import { Box } from "@mantine/core";
import { useEffect, useState } from "react"
import FormGenerator from "../../../components/FormGenerator";
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from "../../../interfaces/IFormGeneratorProps";

const PurchaseOrderForm = (props: { afterSubmit: Function; id?: number }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState({
        id: null,
        code: null,
        currency: null,
        date: new Date(),
        supplier: null
    });

    const fields: IFormGeneratorFieldProps[] = [
        {
            name: 'code',
            label: 'Code',
            required: true,
            type: IFormGeneratorFieldType.Text,
        },
        {
            name: 'currency',
            label: 'Currency',
            required: true,
            type: IFormGeneratorFieldType.Text,
        },
        {
            name: 'date',
            label: 'Date',
            required: true,
            type: IFormGeneratorFieldType.Date,
        },
        {
            name: 'supplier_id',
            label: 'Supplier',
            required: true,
            type: IFormGeneratorFieldType.Text,
        }
    ]

    const onSubmit = (values: any) => {
        //Update API
        if (props.afterSubmit) {
            props.afterSubmit(values);
        }
    }

    const getById = () => {
        //setinitialvalues
    }

    useEffect(() => {
        if (props.id) {
            getById();
        }
    }, [])

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isSubmitting) {
        return <div>Submitting...</div>
    }

    return (
        <Box>
            <FormGenerator fields={fields} initialValues={initialValues} onSubmit={onSubmit} loading={isSubmitting} />
        </Box>
    )
}

export default PurchaseOrderForm