import { Route, Routes } from 'react-router-dom';
import CurrencyList from './list';

const CurrencyMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<CurrencyList />} />
      </Routes>
    </>
  );
};

export default CurrencyMasterLayout;
