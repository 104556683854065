import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { Check } from 'tabler-icons-react';
import CustomerApi from '../../../apis/CustomerApi';
import FormGenerator from '../../../components/FormGenerator';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import { ICustomer } from '../../../interfaces/requests/ICustomerRequest';

const CustomerForm = (props: { afterSubmit: Function; id?: number }) => {
  const fields: IFormGeneratorFieldProps[] = [
    {
      name: 'code',
      label: 'Code',
      required: true,
      disabled: props.id ? true : false,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'name',
      label: 'Name',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'contact_person',
      label: 'Contact Person',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'phone_1',
      label: 'Phone',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },

    {
      name: 'phone_2',
      label: 'Phone 2',
      required: false,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'address',
      label: 'Address',
      required: false,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'email',
      label: 'Email',
      required: false,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'fax',
      label: 'Fax',
      required: false,
      type: IFormGeneratorFieldType.Text,
    },
  ];

  const [initialValues, setInitialValues] = useState<ICustomer>({
    id: undefined,
    name: '',
    address: '',
    code: '',
    contact_person: '',
    email: '',
    fax: '',
    phone_1: '',
    phone_2: '',
    active: false,
    purchasing_contact_person: '',
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    CustomerApi.create(values)
      .then(() => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Customer Created!',
          icon: <Check />,
        });

        if (props.afterSubmit) {
          props.afterSubmit();
        }
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const getById = (id: number) => {
    CustomerApi.getById(id)
      .then((res) => {
        setInitialValues(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (props.id !== undefined && props.id !== null && props.id) {
      getById(props.id);
    }
  }, [props.id]);

  return (
    <Box>
      <FormGenerator fields={fields} initialValues={initialValues} onSubmit={onSubmit} loading={isSubmitting} />
    </Box>
  );
};

export default CustomerForm;
