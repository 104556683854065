import { Route, Routes } from 'react-router-dom';
import CollectorList from './list';

const CollectorMasterLayout = () => {
  return (
    <>
      <Routes>
        <Route index element={<CollectorList />} />
      </Routes>
    </>
  );
};

export default CollectorMasterLayout;
