import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { Check } from 'tabler-icons-react';
import BankApi from '../../../apis/BankApi';
import FormGenerator from '../../../components/FormGenerator';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IFormGeneratorFieldProps, IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import { IBank } from '../../../interfaces/requests/IBankRequest';

const BankForm = (props: { afterSubmit: Function; id?: number }) => {
  const fields: IFormGeneratorFieldProps[] = [
    {
      name: 'code',
      label: 'Code',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'name',
      label: 'Name',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'active',
      label: 'Active',
      type: IFormGeneratorFieldType.Checkbox,
      required: true,
    },
  ];

  const [initialValues, setInitialValues] = useState<IBank>({
    id: undefined,
    name: '',
    code: '',
    active: false,
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    BankApi.create(values)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Currency Created!',
          icon: <Check />,
        });

        if (props.afterSubmit) {
          props.afterSubmit();
        }
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const getById = (id: number) => {
    BankApi.getById(id)
      .then((res) => {
        setInitialValues(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    console.log(props.id);
    if (props.id !== undefined && props.id !== null && props.id) {
      getById(props.id);
    }
  }, [props.id]);

  return (
    <Box>
      <FormGenerator fields={fields} initialValues={initialValues} onSubmit={onSubmit} loading={isSubmitting} />
    </Box>
  );
};

export default BankForm;
