import { Checkbox, NativeSelect, NumberInput, PasswordInput, Select, TextInput, Textarea } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { IDynamicFieldProps, IFormGeneratorFieldType } from '../interfaces/IFormGeneratorProps';

const DynamicField = (props: IDynamicFieldProps) => {
  let obj: any = null;

  if (props.field.type === IFormGeneratorFieldType.Checkbox) {
    obj = props.form.getInputProps(props.field.name, { type: 'checkbox' });
  } else {
    obj = props.form.getInputProps(props.field.name);
  }

  if (props.onChange) {
    obj.onChange = props.onChange;
  }
  // const { onChange, ...restObj } = obj;

  if (props.field.datasource) {
    let firstOption = props.field.datasource.find((x) => x.value === '');

    if (!firstOption) {
      firstOption = {
        value: '',
        label: props.field.placeholder ?? 'Please choose one options',
      };

      props.field.datasource.unshift(firstOption);
    }
  }

  const renderForm = () => {
    switch (props.field.type) {
      case IFormGeneratorFieldType.Text:
        return (
          <TextInput
            mt='sm'
            required={props.field.required}
            label={props.field.label}
            placeholder={props.field.placeholder}
            disabled={props.field.disabled}
            {...obj}
          />
        );
      case IFormGeneratorFieldType.TextArea:
        return (
          <Textarea
            mt='sm'
            required={props.field.required}
            label={props.field.label}
            placeholder={props.field.placeholder}
            disabled={props.field.disabled}
            autosize
            minRows={3}
            {...obj}
          />
        );
      case IFormGeneratorFieldType.Checkbox:
        return <Checkbox mt='sm' label={props.field.label} disabled={props.field.disabled} {...obj} />;
      case IFormGeneratorFieldType.Dropdown:
        return (
          <Select
            mt='sm'
            required={props.field.required}
            label={props.field.label}
            placeholder={props.field.placeholder}
            data={props.field.datasource || []}
            disabled={props.field.disabled}
            searchable
            {...obj}
          />
        );
      case IFormGeneratorFieldType.Password:
        return (
          <PasswordInput
            mt='sm'
            required={props.field.required}
            label={props.field.label}
            placeholder={props.field.placeholder}
            disabled={props.field.disabled}
            {...obj}
          />
        );
      case IFormGeneratorFieldType.Date:
        return (
          <DatePicker
            mt='sm'
            required={props.field.required}
            label={props.field.label}
            placeholder={props.field.placeholder}
            disabled={props.field.disabled}
            {...obj}
          />
        );
      case IFormGeneratorFieldType.Number:
        return (
          <NumberInput
            mt='sm'
            required={props.field.required}
            label={props.field.label}
            placeholder={props.field.placeholder}
            disabled={props.field.disabled}
            onChange={props.onChange ? props.onChange : obj.onChange}
            {...obj}
          />
        );
      default:
        return (
          <TextInput
            required={props.field.required}
            label={props.field.label}
            placeholder={props.field.placeholder}
            disabled={props.field.disabled}
            {...obj}
          />
        );
    }
  };

  return renderForm();
};

export default DynamicField;
