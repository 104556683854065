import { SelectItem } from '@mantine/core';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import _fetch from '../helpers/_fetch';
import {
  ICustomer,
  IPostCustomerRequest,
  ICustomerDataTableRequest,
  ICustomerDataTableResponse,
} from '../interfaces/requests/ICustomerRequest';

const url = '/customer';

const CustomerApi = {
  getDataTable: (
    body: ICustomerDataTableRequest = {
      page: 1,
      limit: 10,
    },
    filter?: any
  ): Promise<AxiosResponse<ICustomerDataTableResponse>> => {
    let extraUrl = qs.stringify(filter);

    return _fetch<ICustomerDataTableResponse>(
      `${url}/dt?page=${body.page}&limit=${body.limit}&${extraUrl ? extraUrl : ''}`,
      {
        method: 'POST',
      }
    );
  },
  create: (body: ICustomer): Promise<AxiosResponse<IPostCustomerRequest>> => {
    return _fetch<IPostCustomerRequest>(`${url}`, {
      method: 'POST',
      body: body,
    });
  },
  remove: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`, {
      method: 'delete',
    });
  },
  getById: (id?: number): Promise<AxiosResponse<ICustomer>> => {
    return _fetch<any>(`${url}/${id}`);
  },
  getDataSource: (): Promise<AxiosResponse<SelectItem[]>> => {
    return _fetch<SelectItem[]>(`${url}/datasource`, {
      method: 'POST',
    });
  },

  search: (body: any): Promise<AxiosResponse<IPostCustomerRequest>> => {
    return _fetch<IPostCustomerRequest>(`${url}/search`, {
      method: 'POST',
      body,
    });
  },

  toggleActive: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/toggle-active/${id}`, {
      method: 'PUT',
    });
  },
};

export default CustomerApi;
