import React, { useEffect, useState } from 'react'
import { Navbar, Group, Code, ScrollArea, createStyles, AppShell, Menu } from '@mantine/core'
import { LinksGroup } from '../components/LinksGroup'
import { Logo } from '../components/Logo'
import UserAvatar from '../components/User'
import sidebarLinks from '../setups/sidebar'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Dashboard from './Dashboard'
import ItemMasterLayout from './Item'
import CurrencyMasterLayout from './Master/Currency'
import { useLocalStorage } from '@mantine/hooks'
import BankMasterLayout from './Master/Bank'
import UnitMasterLayout from './Master/Unit'
import CollectorMasterLayout from './Master/Collector'
import AuthApi from '../apis/AuthApi'
import ErrorHandler from '../helpers/_errorHandler'
import { showNotification } from '@mantine/notifications'
import MarketingMasterLayout from './Master/Marketing'
import SupplierMasterLayout from './Master/Supplier'
import UserMasterLayout from './Master/Users'
import { Logout } from 'tabler-icons-react'
import RoleMasterLayout from './Master/Role'
import PurchaseOrderMasterLayout from './Purchase/PurchaseOrder'
import PurchaseInvoiceMasterLayout from './Purchase/PurchaseInvoice'
import CustomerMasterLayout from './Master/Customer'
import QuotationMasterLayout from './Sales/Quotation'
import CustomerOrderMasterLayout from './Sales/CustomerOrder'
import SalesReturnMasterLayout from './Sales/SalesReturn'
import SalesReportMasterLayout from './Sales/SalesReport'
import SalesInvoiceMasterLayout from './Sales/SalesInvoice'
import StockInMasterLayout from './Store/StockIn'
import AccountPayableMasterLayout from './Account/AccountPayable'
import AccountReceivableMasterLayout from './Account/AccountReceivable'
import AccountPayableReportMasterLayout from './Report/AccountPayableReport'
import AccountReceiveAbleReportMasterLayout from './Report/AccountReceiveableReport'

const useStyles = createStyles((theme) => ({
 navbar: {
  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
  paddingBottom: 0,
 },

 header: {
  padding: theme.spacing.md,
  paddingTop: 0,
  marginLeft: -theme.spacing.md,
  marginRight: -theme.spacing.md,
  color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
 },

 links: {
  marginLeft: -theme.spacing.md,
  marginRight: -theme.spacing.md,
 },

 linksInner: {
  paddingTop: theme.spacing.xl,
  paddingBottom: theme.spacing.xl,
 },

 footer: {
  marginLeft: -theme.spacing.md,
  marginRight: -theme.spacing.md,
  borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
 },
}))

const OurNavbar = () => {
 const { classes } = useStyles()
 const links = sidebarLinks.map((item) => <LinksGroup {...item} key={item.label} />)

 const onLogout = () => {
  localStorage.clear()
  window.location.href = '/login'
 }

 return (
  <Navbar height={'100vh'} width={{ sm: 260 }} p='md' className={classes.navbar}>
   <Navbar.Section className={classes.header}>
    <Group position='apart'>
     <Logo colorScheme='light' />
     <Code sx={{ fontWeight: 700 }}>v3.1.2</Code>
    </Group>
   </Navbar.Section>

   <Navbar.Section grow className={classes.links} component={ScrollArea}>
    <div className={classes.linksInner}>{links}</div>
   </Navbar.Section>

   <Navbar.Section className={classes.footer}>
    <Group position='center'>
     <Menu withArrow position='top'>
      <Menu.Target>
       <UserAvatar
        avatar='https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80'
        name='Nobita huang'
        email='anullpointer@yahoo.com'></UserAvatar>
      </Menu.Target>
      <Menu.Item color='red' icon={<Logout size={14} />} onClick={onLogout}>
       Logout my account
      </Menu.Item>
     </Menu>
    </Group>
   </Navbar.Section>
  </Navbar>
 )
}

const MainLayout = () => {
 const navigate = useNavigate()
 const [accessToken, setAccessToken] = useLocalStorage<string>({
  key: 'access_token',
  defaultValue: '',
 })

 const [isLoading, setIsLoading] = useState<boolean>(false)

 useEffect(() => {
  let accessToken = localStorage.getItem('access_token')

  if (!accessToken) {
   navigate('/login')
  }

  getProfile()
 }, [])

 const getProfile = () => {
  setIsLoading(true)
  AuthApi.profile()
   .then((res) => {})
   .catch((err) => {
    let error = ErrorHandler(err)
    showNotification({ message: error.message, title: 'Error!', color: 'red' })
   })
   .finally(() => {
    setIsLoading(false)
   })
 }

 return (
  <AppShell navbar={<OurNavbar />} padding={0}>
   <Routes>
    <Route path='/dashboard' element={<Dashboard />} />
    <Route path='/item/*' element={<ItemMasterLayout />} />
    <Route path='/currency/*' element={<CurrencyMasterLayout />} />
    <Route path='/bank/*' element={<BankMasterLayout />} />
    <Route path='/unit/*' element={<UnitMasterLayout />} />
    <Route path='/collector/*' element={<CollectorMasterLayout />} />
    <Route path='/marketing/*' element={<MarketingMasterLayout />} />
    <Route path='/supplier/*' element={<SupplierMasterLayout />} />
    <Route path='/role/*' element={<RoleMasterLayout />} />
    <Route path='/users/*' element={<UserMasterLayout />} />
    <Route path='/purchase-order/*' element={<PurchaseOrderMasterLayout />} />
    <Route path='/purchase-invoice/*' element={<PurchaseInvoiceMasterLayout />} />
    <Route path='/customer/*' element={<CustomerMasterLayout />} />
    <Route path='/quotation/*' element={<QuotationMasterLayout />} />
    <Route path='/customer-order/*' element={<CustomerOrderMasterLayout />} />
    <Route path='/sales-return/*' element={<SalesReturnMasterLayout />} />
    <Route path='/sales-report/*' element={<SalesReportMasterLayout />} />
    <Route path='/sales-invoice/*' element={<SalesInvoiceMasterLayout />} />
    <Route path='/stock-in/*' element={<StockInMasterLayout />} />
    <Route path='/account-payable/*' element={<AccountPayableMasterLayout />} />
    <Route path='/account-receivable/*' element={<AccountReceivableMasterLayout />} />
    <Route path='/account-receivable/*' element={<AccountReceivableMasterLayout />} />
    <Route path='/account-payable-report/*' element={<AccountPayableReportMasterLayout />} />
    <Route path='/account-receivable-report/*' element={<AccountReceiveAbleReportMasterLayout />} />
   </Routes>
  </AppShell>
 )
}

export default MainLayout
