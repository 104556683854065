import { Group, Select, SelectItem, TextInput, Space, ActionIcon } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { Search } from 'tabler-icons-react';

interface IFilterProps {
  fields: IFilterField[];
}

export interface IFilterField {
  title: string;
  type: IFilterFieldType;
  data?: SelectItem[];
  placeholder?: string;
}

export enum IFilterFieldType {
  Dropdown,
  Text,
  DateRange,
}

const OurFilter = (props: IFilterProps) => {
  return (
    <>
      <Group>
        {props.fields.map((item) => {
          switch (item.type) {
            case IFilterFieldType.Dropdown:
              return (
                <Select
                  label={item.title}
                  placeholder={item.placeholder ? item.placeholder : `Select one ${item.title}`}
                  data={item.data ?? []}
                  searchable
                />
              );
            case IFilterFieldType.Text:
              return (
                <TextInput
                  placeholder={item.placeholder ? item.placeholder : `Search with ${item.title}`}
                  label={item.title}
                />
              );
            case IFilterFieldType.DateRange:
              return (
                <DateRangePicker
                  label={item.title}
                  placeholder={item.placeholder ? item.placeholder : `Please select a date range`}
                />
              );
          }
        })}
        <ActionIcon
          variant='filled'
          size={'lg'}
          style={{
            marginTop: '24px',
          }}
          color='blue'
        >
          <Search size={16} />
        </ActionIcon>
      </Group>
      <Space h='xs' />
    </>
  );
};

export default OurFilter;
