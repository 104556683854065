import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { Check } from 'tabler-icons-react';
import UserApi from '../../../apis/UserApi';
import FormGenerator from '../../../components/FormGenerator';
import ErrorHandler from '../../../helpers/_errorHandler';
import { IFormGeneratorFieldType, IFormGeneratorFieldProps } from '../../../interfaces/IFormGeneratorProps';
import { IPostUserRequest } from '../../../interfaces/requests/IUserRequest';
import { SelectItem } from '@mantine/core';
import RoleApi from '../../../apis/RoleApi';

const UserForm = (props: { afterSubmit: Function; id?: number }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<IFormGeneratorFieldProps[]>([
    {
      name: 'name',
      label: 'Name',
      required: true,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'email',
      label: 'Email',
      required: true,
      disabled: props.id ? true : false,
      type: IFormGeneratorFieldType.Text,
    },
    {
      name: 'role_id',
      label: 'Role',
      required: true,
      datasource: [],
      type: IFormGeneratorFieldType.Dropdown,
    },
    {
      name: 'password',
      label: 'Password',
      required: true,
      type: IFormGeneratorFieldType.Password,
    },
  ]);
  const [roles, setRoles] = useState<SelectItem[]>([]);

  const getRoles = () => {
    setIsLoading(true);
    RoleApi.getRoleDropdown()
      .then((res) => {
        let newFields = [...fields];
        let roleField = newFields.findIndex((field) => field.name === 'role_id');
        if (roleField > -1) {
          newFields[roleField].datasource = res.data;
          setFields(newFields);
        }
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsLoading(false);
      });
  };

  const [initialValues, setInitialValues] = useState<IPostUserRequest>({
    id: undefined,
    email: '',
    name: '',
    role: '',
    role_id: 0,
    password: '',
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = (values: IPostUserRequest) => {
    setIsSubmitting(true);
    UserApi.create(values)
      .then(() => {
        showNotification({
          message: 'Process Successfully!',
          title: 'User Created!',
          icon: <Check />,
        });

        if (props.afterSubmit) {
          props.afterSubmit();
        }
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const getById = (id: number) => {
    setIsLoading(true);
    UserApi.getById(id)
      .then((res) => {
        setInitialValues(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (props.id !== undefined && props.id !== null && props.id) {
      getById(props.id);
    }
    getRoles();
  }, [props.id]);

  useEffect(() => {
    if (roles.length > 0) {
      setIsLoading(true);
      setInitialValues((prevState) => {
        return {
          ...prevState,
          role_id: Number(roles[0].value),
        };
      });
      setIsLoading(false);
    }
  }, [roles]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <FormGenerator fields={fields} initialValues={initialValues} onSubmit={onSubmit} loading={isSubmitting} />
    </Box>
  );
};

export default UserForm;
