import { SelectItem } from '@mantine/core';
import { AxiosResponse } from 'axios';
import _fetch from '../helpers/_fetch';
import {
  ICustomerOrder,
  IPostCustomerOrderRequest,
  ICustomerOrderDataTableRequest,
  ICustomerOrderDataTableResponse,
} from '../interfaces/requests/ICustomerOrderRequest';

const url = '/sales-order';

const CustomerOrderApi = {
  getDataTable: (
    body: ICustomerOrderDataTableRequest = {
      page: 1,
      limit: 10,
    }
  ): Promise<AxiosResponse<ICustomerOrderDataTableResponse>> => {
    return _fetch<ICustomerOrderDataTableResponse>(`${url}/dt?page=${body.page}&limit=${body.limit}`, {
      method: 'POST',
      body,
    });
  },
  create: (body: any): Promise<AxiosResponse<IPostCustomerOrderRequest>> => {
    return _fetch<IPostCustomerOrderRequest>(`${url}`, {
      method: 'POST',
      body: body,
    });
  },
  update: (body: any): Promise<AxiosResponse<IPostCustomerOrderRequest>> => {
    return _fetch<IPostCustomerOrderRequest>(`${url}`, {
      method: 'POST',
      body,
    });
  },
  remove: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`, {
      method: 'delete',
    });
  },
  getById: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`);
  },
  getDataSource: (): Promise<AxiosResponse<SelectItem[]>> => {
    return _fetch<SelectItem[]>(`${url}/datasource`, {
      method: 'POST',
    });
  },
  search: (body: any): Promise<AxiosResponse<IPostCustomerOrderRequest>> => {
    return _fetch<IPostCustomerOrderRequest>(`${url}/search`, {
      method: 'POST',
      body,
    });
  },
};

export default CustomerOrderApi;
