import {
  ActionIcon,
  Button,
  Group,
  Text,
  Tooltip,
  Checkbox,
  Popover,
  Grid,
  NativeSelect,
  SelectItem,
  Space,
  Input,
} from '@mantine/core';
import { Ban, Trash, History, Filter } from 'tabler-icons-react';
import ListToolbar from '../../../components/ListToolbar';
import OurBreadcrumbs from '../../../components/OurBreadcrumbs';
import InnerLayout from '../../InnerLayout';
import OurTable from '../../../components/OurTable';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../helpers/_errorHandler';
import { showNotification } from '@mantine/notifications';
import QuotationApi from '../../../apis/QuotationApi';
import { ISupplier } from '../../../interfaces/requests/ISupplierRequest';
import { useModals } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CurrencyApi from '../../../apis/CurrencyApi';
import DynamicField from '../../../components/DynamicField';
import { IFormGeneratorFieldType } from '../../../interfaces/IFormGeneratorProps';
import { useForm } from '@mantine/form';
import { ISalesQuotation } from '../../../interfaces/requests/ISalesQuotation';

const links = [
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
  {
    title: 'Account Receivable',
    link: '/account-receivable',
  },
  {
    title: 'History',
  },
];

const AccountReceivableHistory = () => {
  const [data, setData] = useState<ISalesQuotation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [isViewInvoiceOverdue, setIsViewInvoiceOverdue] = useState(false);
  const [openFilterPopover, setOpenFilterPopover] = useState<boolean>(false);
  const [currencyList, setCurrencyList] = useState<SelectItem[]>([]);

  const [searchByDataSource] = useState<SelectItem[]>([
    {
      value: 'paid_no',
      label: 'Paid No',
    },
    {
      value: 'document_no',
      label: 'Document No',
    },
    {
      value: 'id_customer',
      label: 'ID Customer',
    },
    {
      value: 'customer_name',
      label: 'Customer Name',
    },
    {
      value: 'collector',
      label: 'Collector',
    },
    {
      value: 'description',
      label: 'Description',
    },
    {
      value: 'invoice_no',
      label: 'Invoice No',
    },
    {
      value: 'return_no',
      label: 'Return No',
    },
  ]);
  const [searchBy, selectedSearchBy] = useState<SelectItem>({
    value: 'docNo',
    label: 'Doc No',
  });

  const navigate = useNavigate();

  const modals = useModals();

  const getList = () => {
    setIsLoading(true);
    QuotationApi.getDataTable({
      limit: 10,
      page: 1,
    })
      .then((res) => {
        setTotalPage(res.data.last_page);
        setData(res.data.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  useEffect(() => {
    if (!data.length) {
      // getList();

      getCurrencyList();
    }
  }, []);

  const getCurrencyList = () => {
    setIsLoading(true);
    CurrencyApi.getDataSource()
      .then((res) => {
        setCurrencyList(res.data);
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onInputPaymentClicked = () => {
    navigate('create');
  };

  const toEdit = (accountReceivableId: number) => {
    navigate(`/account-receivable/update/${accountReceivableId}`);
  };

  const remove = (item: ISupplier) => {
    setIsLoading(true);
    QuotationApi.remove(item.id)
      .then((res) => {
        showNotification({
          message: 'Proccess Successfully!',
          title: 'Account Receivable Removed!',
          icon: <Trash />,
        });
        // getList();
      })
      .catch((err) => {
        let error = ErrorHandler(err);
        showNotification({ message: error.message, title: 'Error!', color: 'red' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDeletePrompt = (data: any) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size='sm'>
          This action is so important that you are required to confirm it. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => remove(data),
    });
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  const columns = [
    {
      title: 'Actv',
      accessor: 'active',
      customRender: (item: any) => {
        return <Checkbox />;
      },
    },
    {
      title: 'Paid No',
      accessor: 'paid_no',
    },
    {
      title: 'Paid Date',
      accessor: 'paid_date',
    },
    {
      title: 'ID Cust',
      accessor: 'id_customer',
    },
    {
      title: 'Customer Name',
      accessor: 'customer_name',
    },
    {
      title: 'Doc No',
      accessor: 'document_number',
    },
    {
      title: 'Description',
      accessor: 'description',
    },
    {
      title: 'Collector',
      accessor: 'currency.name',
    },
    {
      title: 'Curr',
      accessor: 'curr',
    },
    {
      title: 'Cash',
      accessor: 'cash',
    },
    {
      title: 'Transfer',
      accessor: 'transfer',
    },
    {
      title: 'Action',
      accessor: '',
      customRender: (item: ISupplier) => {
        return (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Tooltip label='Cancel Payment'>
              <ActionIcon variant='light' color='red' onClick={() => onDeletePrompt(item)}>
                <Ban size={16} />
              </ActionIcon>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const toggleViewInvoiceOverdue = (event: any) => {
    setIsViewInvoiceOverdue((prevState) => !prevState);
  };

  const toggleFilterPopover = () => {
    setOpenFilterPopover(!openFilterPopover);
  };

  const form = useForm({
    initialValues: {
      fromDate: new Date(),
      toDate: new Date(),
    },
  });

  if (isLoading) {
    return <div>Loading..</div>;
  }

  return (
    <InnerLayout title='Account Receivable History'>
      <OurBreadcrumbs crumbs={links} />

      <Space h={24} />

      <ListToolbar>
        <Group style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Popover
            width={700}
            position='right'
            withArrow
            shadow='md'
            opened={openFilterPopover}
            onClose={toggleFilterPopover}
          >
            <Popover.Target>
              <Button variant='outline' size='sm' onClick={toggleFilterPopover} leftIcon={<Filter size={16} />}>
                Filter
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <>
                <Grid>
                  <Grid.Col span={4}>
                    <DynamicField
                      field={{
                        name: 'fromDate',
                        type: IFormGeneratorFieldType.Date,
                        label: 'From Date',
                      }}
                      form={form}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <DynamicField
                      field={{
                        name: 'toDate',
                        type: IFormGeneratorFieldType.Date,
                        label: 'To Date',
                      }}
                      form={form}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Status'
                      data={[
                        {
                          value: 'all',
                          label: 'ALL',
                        },
                        {
                          value: 'active',
                          label: 'ACTIVE',
                        },
                        {
                          value: 'cancel',
                          label: 'CANCEL',
                        },
                      ]}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Search By'
                      data={searchByDataSource}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={4} style={{ marginTop: '13px' }}>
                    <span
                      style={{
                        color: '#212529',
                        fontWeight: 500,
                        fontSize: '14px',
                        marginBottom: '4px',
                      }}
                    >
                      Keyword
                    </span>
                    <Input style={{ marginTop: '4px' }} name='keyword' />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <NativeSelect
                      mt='sm'
                      label='Curr'
                      data={currencyList}
                      onChange={(data) => console.log(data.target.value)}
                    />
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col mt='xs'>
                    <Button size='sm' style={{ float: 'right' }}>
                      Reset
                    </Button>
                  </Grid.Col>
                </Grid>
              </>
            </Popover.Dropdown>
          </Popover>
        </Group>
      </ListToolbar>
      <OurTable
        isLoading={isLoading}
        page={page}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        columns={columns}
        data={[]}
      />

      <Space h={12} />

      <Grid justify='end'>
        <Grid.Col span={1}>
          <span style={{ float: 'right' }}>Tot Cash</span>
        </Grid.Col>
        <Grid.Col span={1}>
          <Input type='number' />
        </Grid.Col>
        <Grid.Col span={1}>
          <span style={{ float: 'right' }}>Tot Transfer</span>
        </Grid.Col>
        <Grid.Col span={1}>
          <Input type='number' />
        </Grid.Col>
        <Grid.Col span={1}>
          <span style={{ float: 'right' }}>Tot Giro</span>
        </Grid.Col>
        <Grid.Col span={1}>
          <Input type='number' />
        </Grid.Col>

        <Grid.Col span={1}>
          <span style={{ float: 'right' }}> Tot Disc</span>
        </Grid.Col>
        <Grid.Col span={1}>
          <Input type='number' />
        </Grid.Col>
        <Grid.Col span={1}>
          <span style={{ float: 'right' }}>Total</span>
        </Grid.Col>
        <Grid.Col span={1}>
          <Input type='number' />
        </Grid.Col>
      </Grid>
    </InnerLayout>
  );
};

export default AccountReceivableHistory;
