import { AxiosResponse } from 'axios';
import qs from 'qs';
import _fetch from '../helpers/_fetch';
import {
  IPostCollectorRequest,
  ICollector,
  ICollectorDataTableRequest,
  ICollectorDataTableResponse,
} from '../interfaces/requests/ICollectorRequest';

const url = '/collector';

const CollectorApi = {
  getDataTable: (
    body: ICollectorDataTableRequest = {
      page: 1,
      limit: 10,
    },
    filter: any
  ): Promise<AxiosResponse<ICollectorDataTableResponse>> => {
    let extraUrl = qs.stringify(filter);
    return _fetch<ICollectorDataTableResponse>(
      `${url}/dt?page=${body.page}&limit=${body.limit}&${extraUrl ? extraUrl : ''}`,
      {
        method: 'POST',
      }
    );
  },
  create: (body: ICollector): Promise<AxiosResponse<IPostCollectorRequest>> => {
    return _fetch<IPostCollectorRequest>(`${url}`, {
      method: 'POST',
      body: body,
    });
  },
  remove: (id?: number): Promise<AxiosResponse<any>> => {
    return _fetch<any>(`${url}/${id}`, {
      method: 'delete',
    });
  },
  getById: (id?: number): Promise<AxiosResponse<ICollector>> => {
    return _fetch<ICollector>(`${url}/${id}`);
  },
};

export default CollectorApi;
